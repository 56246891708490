var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.company)?_c('div',{staticClass:"company-footer",attrs:{"id":"product-page"}},[_c('desktop-nav',{attrs:{"company":_vm.company}}),_c('div',{staticClass:"app-content blobnav-overlay"},[_c('div',{staticClass:"desktop-shop-header"},[_c('section',{staticClass:"shop-details desktop-qc",staticStyle:{"background-position":"center center"},style:({
          'background-image':
            _vm.company.atmosphericImage !== null
              ? 'url(' + _vm.imageHost + _vm.company.atmosphericImage + ')'
              : '',
        })},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"sixteen"},[_c('div',{staticClass:"merchants-container"},[_c('div',{staticClass:"merchants"},[_c('div',{staticClass:"merchant"},[_c('figure',{staticClass:"merchant-logo"},[_c('img',{staticClass:"qc-logo",attrs:{"id":'img-' + _vm.company.companyId,"src":_vm.imageHost + _vm.company.logo},on:{"click":function () {
                          _vm.setViewMethod('category-overview', true);
                        },"error":function($event){return _vm.imageError('img-' + _vm.company.companyId)}}})])]),_c('div',{staticClass:"star-container"},[(_vm.company.favorite !== true)?_c('span',{on:{"click":function($event){return _vm.toggleFavorite(_vm.company, $event)}}},[_c('i',{staticClass:"star far fa-star",attrs:{"aria-hidden":"true"}})]):_vm._e(),(_vm.company.favorite === true)?_c('span',{on:{"click":function($event){return _vm.toggleFavorite(_vm.company, $event)}}},[_c('i',{staticClass:"star fa fa-star",attrs:{"aria-hidden":"true"}})]):_vm._e()])])])]),_c('div',{staticClass:"clear"})])])]),_c('section',{staticClass:"products-container"},[_c('div',{staticClass:"container"},[(_vm.contentPage)?_c('div',{staticClass:"sixteen"},[_c('div',{},[_c('div',{staticClass:"text carousel-right"},[(_vm.contentPage.media && _vm.contentPage.media.length > 0)?_c('VueSlickCarousel',{ref:"c1",attrs:{"asNavFor":_vm.$refs.c2,"focusOnSelect":true,"arrows":true,"adaptiveHeight":true}},_vm._l((_vm.contentPage.media),function(medium){return _c('div',{key:medium.id,class:{
                    'video-embed': medium.type.includes('video'),
                  }},[(medium.type.includes('video'))?_c('video-embed',{attrs:{"src":medium.link}}):_c('img',{attrs:{"src":_vm.imageHost + medium.url,"alt":medium.name}})],1)}),0):_vm._e(),_c('div',{staticClass:"carousel-content"},[_c('h1',{staticClass:"large"},[_vm._v(_vm._s(_vm.contentPage.title))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.contentPage.text)}})])],1),_c('div',{staticClass:"clear"})])]):_vm._e()])])]),_c('div',{staticClass:"desktop-qc-block desktop-footer-container"},[_c('company-footer',{attrs:{"company":_vm.company}}),_c('section',{staticClass:"desktop-footer desktop-qc-block"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"sixteen"},[_c('div',{staticClass:"text-right"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.setDisplayMode(6)}}},[_vm._v(_vm._s(_vm.$t('GeneralConditions')))])]),_c('li',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.setDisplayMode(5)}}},[_vm._v(_vm._s(_vm.$t('PrivacyPolicy')))])]),_c('li',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.setDisplayMode(4)}}},[_vm._v(_vm._s(_vm.$t('CookiePolicy')))])]),_vm._m(0)])])])])])],1)],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_vm._v(" by "),_c('a',{attrs:{"href":"https://www.quickcollect.be/een-handige-online-besteldienst-voor-handelaars/","target":"_blank"}},[_vm._v("Quickcollect")])])}]

export { render, staticRenderFns }