




























































import Vue from "vue";
import ClickCollectApi from "../libs/ClickCollectApi";
import { ProductRepo } from "../libs/ProductRepository";
import { Store } from "../libs/Store";
import DesktopNav from "../components/shared/DesktopNav.vue";
import PageHeader from "../components/shared/PageHeader.vue";
import { CompanyRepo, ICompany } from "@/libs/CompanyRepository";
export default Vue.extend({
  name: "MyAccount" as string,
  components: {
    DesktopNav,
    PageHeader,
  },
  data() {
    return {
      authenticated: false,
      showMobileMenu: true,
      company: undefined as ICompany | undefined,
    };
  },
  methods: {
    setDisplayMode(mode: any) {
      console.log("set display mode");
      this.$root.$emit("change-display-mode", mode);
    },
    showSettingsMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
  },
  mounted: async function () {
    console.log(Store);
    if (localStorage.getItem("user") != null) {
      this.authenticated = true;
    }
    this.company = await CompanyRepo.getCompanyByRoute(this.$route);
    console.log(this.company);
  },
  computed: {
    basePath: function () {
      var segments = this.$route.path.split("/");
      console.log(segments);
      return segments.length > 2 ? "/" + segments[1] : "";
    },
  },
});
