import { i18n } from '@/main';
import VueSweetalert2 from 'vue-sweetalert2';
import { ICompany } from './CompanyRepository';
declare var $: any;

export class Utils {

    public static openingHourList(company: ICompany) {
        var output = "";

       /*  company.openinghours.sort((h1, h2) => {
            if(h1.dayOfTheWeek == h2.dayOfTheWeek)
                return 0
            else if(h1.dayOfTheWeek == 0)
                return 1
            else if(h2.dayOfTheWeek == 0)
                return -1
            else
                return h1.dayOfTheWeek > h2.dayOfTheWeek ? 1 : -1
        }); */
        for (var i = 0; i < company.openinghours.length; i++) {
            if (company.openinghours[i].closed)
                continue;
            // @ts-ignore
            output += "<li><strong>" + this.convertDayOfWeekToString(company.openinghours[i].dayOfTheWeek) + "</strong>";
           /*  company.openinghours[i].openinghourRanges.sort((r1 : any,r2 : any) => {
                if(r1.from == r2.from)
                    return 0
                return r1.from > r2.from ? 1 : -1
            }) */
            // @ts-ignore
            for (var ii = 0; ii < company.openinghours[i].openinghourRanges.length; ii++) {
                // @ts-ignore
                output += company.openinghours[i].openinghourRanges[ii].from + " - " + company.openinghours[i].openinghourRanges[ii].until;
                // @ts-ignore
                if (ii != company.openinghours[i].openinghourRanges.length - 1) {
                    output += " <span class='hours-sep'></span> "
                }
            }

            output += "</li>"
        }
        return output ? "<ul>" + output + "</ul>" : "";

    }

    public static takeAwayHourList(company: ICompany) {
        var output = "";
        /* company.takeawayhours.sort((h1, h2) => {
            if(h1.dayOfTheWeek == h2.dayOfTheWeek)
                return 0
            else if(h1.dayOfTheWeek == 0)
                return 1
            else if(h2.dayOfTheWeek == 0)
                return -1
            else
                return h1.dayOfTheWeek > h2.dayOfTheWeek ? 1 : -1
        }); */
        console.log("takewayhours", company.takeawayhours)
        // @ts-ignore
        for (var i = 0; i < company.takeawayhours.length; i++) {
            if (company.takeawayhours[i].closed)
                continue;
            // @ts-ignore
            output += "<li><strong>" + this.convertDayOfWeekToString(company.takeawayhours[i].dayOfTheWeek) + "</strong>";
            /* company.takeawayhours[i].takeAwayHourRanges.sort((r1 : any,r2 : any) => {
                if(r1.from == r2.from)
                    return 0
                return r1.from > r2.from ? 1 : -1
            }) */
            // @ts-ignore
            for (var ii = 0; ii < company.takeawayhours[i].takeAwayHourRanges.length; ii++) {
                // @ts-ignore
                output += company.takeawayhours[i].takeAwayHourRanges[ii].from + " - " + company.takeawayhours[i].takeAwayHourRanges[ii].until;
                // @ts-ignore
                if (ii != company.takeawayhours[i].takeAwayHourRanges.length - 1) {
                    output += " <span class='hours-sep'></span> "
                }
            }

            output += "</li>"
        }
        return output ? "<ul>" + output + "</ul>" : "";

    }

    public static deliveryHourList(company: ICompany) {
        var output = "";
        /* company.deliveryhours.sort((h1, h2) => {
            if(h1.dayOfTheWeek == h2.dayOfTheWeek)
                return 0
            else if(h1.dayOfTheWeek == 0)
                return 1
            else if(h2.dayOfTheWeek == 0)
                return -1
            else
                return h1.dayOfTheWeek > h2.dayOfTheWeek ? 1 : -1
        }); */
        // @ts-ignore
        for (var i = 0; i < company.deliveryhours.length; i++) {
            if (company.deliveryhours[i].closed)
                continue;
            // @ts-ignore
            output += "<li><strong>" + this.convertDayOfWeekToString(company.deliveryhours[i].dayOfTheWeek) + "</strong>";
            /* company.deliveryhours[i].deliveryhourRanges.sort((r1 : any,r2 : any) => {
                if(r1.from == r2.from)
                    return 0
                return r1.from > r2.from ? 1 : -1
            }) */
            // @ts-ignore
            for (var ii = 0; ii < company.deliveryhours[i].deliveryhourRanges.length; ii++) {
                // @ts-ignore
                output += company.deliveryhours[i].deliveryhourRanges[ii].from + " - " + company.deliveryhours[i].deliveryhourRanges[ii].until;
                // @ts-ignore
                if (ii != company.deliveryhours[i].deliveryhourRanges.length - 1) {
                    output += " <span class='hours-sep'></span> "
                }
            }

            output += "</li>"
        }
        return output ? "<ul>" + output + "</ul>" : "";

    }
    public static showOpeningshours(company: ICompany, event: any, swal: any) {

        event.preventDefault();
        console.log(company);
        console.log('openingshours');
        var alertString = "";
        var title = company.enableDelivery && company.enableTakeAway ? i18n.t("TakeAwayAndDeliveryHours") : (company.enableDelivery ? i18n.t("DeliveryHours") : i18n.t("TakeAwayHours"));

        if (company.closed) {
            alertString += "<div class='status closed'>";
            alertString += "<span></span> " + i18n.t("StoreClosed");
            alertString += "</div><br />";
        }
        else {
            alertString += "<div class='status open'>";
            alertString += "<span></span> " + i18n.t("StoreOpen");
            alertString += "</div><br />";
        }
        if (company.openinghours.some(h => !h.closed)) {
            alertString += "<h5>" + i18n.t("OpeningHours") + "</h5>";
        }
        // @ts-ignore
        for (var i = 0; i < company.openinghours.length; i++) {
            if (company.openinghours[i].closed)
                continue;
            // @ts-ignore
            alertString += "<strong>" + this.convertDayOfWeekToString(company.openinghours[i].dayOfTheWeek) + "</strong>";
            // @ts-ignore
            if (company.openinghours[i].closed) {
                alertString += i18n.t("Closed");
            }
            else {
                // @ts-ignore
                for (var ii = 0; ii < company.openinghours[i].openinghourRanges.length; ii++) {
                    // @ts-ignore
                    alertString += company.openinghours[i].openinghourRanges[ii].from + " - " + company.openinghours[i].openinghourRanges[ii].until;
                    // @ts-ignore
                    if (ii != company.openinghours[i].openinghourRanges.length - 1) {
                        alertString += " <span class='hours-sep'></span> "
                    }
                }
            }
            
            alertString += "<br />"
        }
        if (company.enableTakeAway) {
            alertString += "<h5>" + i18n.t("TakeAwayHours") + "</h5>";
        }
        // @ts-ignore
        for (var i = 0; i < company.takeawayhours.length; i++) {
            if (company.takeawayhours[i].closed)
                continue;
            // @ts-ignore
            alertString += "<strong>" + this.convertDayOfWeekToString(company.takeawayhours[i].dayOfTheWeek) + "</strong>";
            // @ts-ignore
            if (company.takeawayhours[i].closed) {
                alertString += i18n.t("Closed");
            }
            else {
                // @ts-ignore
                for (var ii = 0; ii < company.takeawayhours[i].takeAwayHourRanges.length; ii++) {
                    // @ts-ignore
                    alertString += company.takeawayhours[i].takeAwayHourRanges[ii].from + " - " + company.takeawayhours[i].takeAwayHourRanges[ii].until;
                    // @ts-ignore
                    if (ii != company.takeawayhours[i].takeAwayHourRanges.length - 1) {
                        alertString += " <span class='hours-sep'></span> "
                    }
                }
            }
            
            alertString += "<br />"
        }
        alertString += "<small>" + (company.takeAwayHoursText ?? "") + "</small>"

        if (company.enableDelivery) {
            alertString += "<br /><h5>" + i18n.t("DeliveryHours") +"</h5>";

            // @ts-ignore
            for (var i = 0; i < company.deliveryhours.length; i++) {
                if (company.deliveryhours[i].closed)
                    continue;
                // @ts-ignore
                alertString += "<strong>" + this.convertDayOfWeekToString(company.deliveryhours[i].dayOfTheWeek) + "</strong>";
                // @ts-ignore
                if (company.deliveryhours[i].closed) {
                    alertString += i18n.t("Closed");
                }
                else {
                    // @ts-ignore
                    for (var ii = 0; ii < company.deliveryhours[i].deliveryhourRanges.length; ii++) {
                        // @ts-ignore
                        alertString += company.deliveryhours[i].deliveryhourRanges[ii].from + " - " + company.deliveryhours[i].deliveryhourRanges[ii].until;
                        // @ts-ignore
                        if (ii != company.deliveryhours[i].deliveryhourRanges.length - 1) {
                            alertString += "<strong></strong>"
                        }
                    }
                }
                alertString += "<br />"
            }

            alertString += "<small>" + (company.deliveryHoursText ?? "") + "</small>"
        }


        swal({
            title: title,
            html: alertString,
            showConfirmButton: false,
            showCloseButton: true,
            customClass: {
                container: 'openingsuren-container'
            },
        }).then((value: any) => {
            console.log(value);
            // if(value.dismiss && value.dismiss === Swal.DismissReason.close)
            //   self.$router.push({name: "basket"});
        });
    }

    private static convertDayOfWeekToString(input: number) {
        if (input == 1) {
            return i18n.t("Monday") + ": ";
        }
        else if (input == 2) {
            return i18n.t("Tuesday") + ": ";
        }
        else if (input == 3) {
            return i18n.t("Wednesday") + ": ";
        }
        else if (input == 4) {
            return i18n.t("Thursday") + ": ";
        }
        else if (input == 5) {
            return i18n.t("Friday") + ": ";
        }
        else if (input == 6) {
            return i18n.t("Saturday") + ": ";
        }
        else if (input == 0) {
            return i18n.t("Sunday") + ": ";
        }
    }

    public static isIos() {
        const userAgent = window.navigator.userAgent.toLowerCase();
        if (/iphone|ipad|ipod/.test(userAgent)) {
            return true;
        } else {
            return (
                navigator.maxTouchPoints &&
                navigator.maxTouchPoints > 2 &&
                /MacIntel/.test(navigator.platform)
            );
        }
    }

    public static processGoogleMapsData(res: any): any {

        var itemRoute = '';
        var itemLocality = '';
        var itemCountry = '';
        var itemPc = '';
        var itemSnumber = '';

        // iterate through address_component array
        if (res.address_components !== undefined) {
            $.each(res.address_components, function (i: number, address_component: any) {
                console.log('address_component:' + i);

                if (address_component.types[0] == "route") {
                    console.log(i + ": route:" + address_component.long_name);
                    itemRoute = address_component.long_name;
                }

                if (address_component.types[0] == "locality") {
                    console.log("town:" + address_component.long_name);
                    itemLocality = address_component.long_name;
                }

                if (address_component.types[0] == "country") {
                    console.log("country:" + address_component.long_name);
                    itemCountry = address_component.long_name;
                }

                if (address_component.types[0] == "postal_code_prefix") {
                    console.log("pc:" + address_component.long_name);
                    itemPc = address_component.long_name;
                }

                if (address_component.types[0] == "street_number") {
                    console.log("street_number:" + address_component.long_name);
                    itemSnumber = address_component.long_name;
                }
                //return false; // break the loop   
            });
        }
        else {
            $.each(res.addressComponents, function (i: number, address_component: any) {
                console.log('address_component:' + i);

                if (address_component.stringTypes[0] == "route") {
                    itemRoute = address_component.longName;
                }

                if (address_component.stringTypes[0] == "locality") {
                    itemLocality = address_component.longName;
                }

                if (address_component.stringTypes[0] == "country") {
                    itemCountry = address_component.longName;
                }

                if (address_component.stringTypes[0] == "postal_code") {
                    itemPc = address_component.longName;
                }

                if (address_component.stringTypes[0] == "street_number") {
                    itemSnumber = address_component.longName;
                }
                //return false; // break the loop   
            });
        }

        var saveObject = {
            // @ts-ignore
            latitude: res.geometry.location.lat,
            // @ts-ignore
            longitude: res.geometry.location.lng,
            locality: itemLocality,
            postal_code: itemPc,
            route: itemRoute,
        }
        return saveObject;
    }
}
export const UtilsRepo = new Utils();