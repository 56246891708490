


































import Vue from "vue";
import PinchZoom from 'vue-pinch-zoom';

export default Vue.extend( {
  components:{
    PinchZoom
  },
  props: {
    closeOnBackgroundClick: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      expanded: false,
      closeButtonRef: null,
    };
  },

  methods: {
    closeImage(event :any) {
      event.stopPropagation();
      this.expanded = false;
      
    },
    expand(event : any){
      if(!this.disabled){
        event.stopPropagation()
        this.expanded = true
      }        
    }
  },
});
