











































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CompanyRepo, ICompany } from "../libs/CompanyRepository";
import { configs } from "../libs/config"
import VueGoogleAutocomplete from '../../node_modules/vue-google-autocomplete/src/VueGoogleAutocomplete.vue'
import { CustomerRepo } from '../libs/CustomerRepository';


// @ts-ignore
import Autocomplete from "@trevoreyre/autocomplete-vue";

// @ts-ignore
import imagesLoaded from "vue-images-loaded";
import { Store } from "../libs/Store";
import { Utils } from "../libs/Utils";
declare var $ :any;

export default Vue.extend({
  name: "Companies" as string,
  components: {
    VueGoogleAutocomplete
  },
  directives: {
    imagesLoaded
  },
  data() {
    return {
      enableGeo : true,
      noResults: true,
      noAddress : false,
      store: Store.state,
      address : { "latitude": 50.8499966, "longitude": 4.3499986, "locality": "Brussel", "postal_code": "", "route": "" },
      autoAddress : '',
      companies: [] as ICompany[],
      paging: {
        page: 1,
        itemsPerPage: 9,
        pages: [] as number[],
        total: 0 as number
      },
      searchTerm : '',
      viewMethod: 'list',
      loading: false,
      imageHost : configs.ImageHost
    };
  },
  methods: {  
    setDisplayMode(mode : any) {
       this.$root.$emit('change-display-mode', mode);
    },
    switchLanguage(lang: string) {
      this.store.culture = lang + "-BE";
      this.$i18n.locale = lang;
      localStorage.setItem("culture", lang + "-BE");
      
      // update the component
      //this.componentKey += 1;

      // ProductRepo.abortAllTransactions();
      // ProductRepo.loadAllProducts(true);
      // fix non vue hamburger menu
      setTimeout(function() {
        // @ts-ignore
        //window.settingsFlyout();
        // @ts-ignore
        window.filterWindow();
      }, 200);
    },
    getAddressData: function (addressData : any, placeResultData: any, id : any) {
        this.address = addressData;
    },
    setAutoAddress: function (addressData : any, placeResultData: any, id : any) {
        this.autoAddress = addressData.newVal;
    },
   searchLocation : async function(){
              console.log(this.autoAddress);
      var self = this;
      if(!this.address){

        console.log(this.autoAddress);
        var response = await CustomerRepo.getAddress(this.autoAddress);
        var res = response.address;
        console.log(res);
        // @ts-ignore
        if(res.status == "OK"){
          var saveObject = Utils.processGoogleMapsData(res.results[0]);
          console.log('saveObject');
          console.log(res.results[0]);
          console.log('redirect');
            // @ts-ignore
          localStorage.setItem('address',JSON.stringify(saveObject));
          self.$router.push({
            name : 'companies'
          })
        }
        else{
          self.noAddress = true;

        }      
      }
      else{
          console.log('address not empty');
          console.log(this.address);
        // @ts-ignore
        localStorage.setItem('address',JSON.stringify(this.address));
        this.$router.push({
          name : 'companies'
        })
      }
     
    }  
  }, 
  async mounted() {
    // @ts-ignore
    this.$session.remove('companyRedirect');

    var self = this;
    var locationCheck = false;
    var firstTime = true;
    // @ts-ignore
    // Get current locationupdate
    console.log(navigator.geolocation);
  
    var preferencesSet =  this.$cookies.get("_cookiePreferencesSet")
    if(preferencesSet){
       if (navigator.geolocation) {
         if (Utils.isIos() == false) {
           setTimeout(() => {
            if (localStorage.getItem("location") === null) {
              console.log('no-location')
              this.$root.$emit('change-display-mode', 1);
            } else {
              this.$root.$emit('change-display-mode', 0);
            }
           }, 500);          
        }
        navigator.geolocation.getCurrentPosition(location => {
          console.log(location);
          localStorage.setItem('location',"1");

          // @ts-ignore       
          var geocoder = new google.maps.Geocoder;
          // @ts-ignore        
          var myLatlng = new google.maps.LatLng(parseFloat(location.coords.latitude),parseFloat(location.coords.longitude));
          geocoder.geocode({'location': myLatlng}, function(results: any, status: any) {
              if (status === 'OK') {
                if (results[0]) {
                  if(firstTime == false){
                    //redirect to companies
                    console.log(results);
                    var saveObject = Utils.processGoogleMapsData(results[0]);
                    console.log('redirect');
                    console.log('save object');
                    console.log(saveObject);
                    // @ts-ignore
                    saveObject.latitude = location.coords.latitude;
                    saveObject.longitude = location.coords.longitude;
                    localStorage.setItem('address',JSON.stringify(saveObject));
                    self.$router.push({
                      name : 'companies'
                    })
                  }
                  else{
                    console.log(self.$refs);
                    console.log(self.$refs.addressAutoComplete);
                    // @ts-ignore   
                    var response = self.$refs.addressAutoComplete.update(results[0].formatted_address);
                  }
                } else {
                  //window.alert('No results found');
                }
              } else {
                //window.alert('Geocoder failed due to: ' + status);
              }
          });
        });
      }   
    }
   
  },
  watch: {
    currentPage: function() {
      //deze 'watch' traceert page changes en kan de data anders ophalen
    }
  },
  computed: {
    maxPage: function maxPage(): number {
      //computed om max page te bepalen, wordt gebruikt in paging html
      if (this.paging.pages.length == 0) return 0;
      return this.paging.pages.reduce((a, b) => (a > b ? a : b));
    },
    currentPage: function currentPage(): number {
      //deze computed value wordt gebruikt om page changes te traceren, zie watch
      return this.paging.page;
    }
  }
});
