import ClickCollectApi from './ClickCollectApi'
import BraetDatabase, { IArticle, Basket, ArticleQuantity, IBasket, Db } from './QuickCollectDatabase'
import { Store } from './Store';
import { ProductRepo } from './ProductRepository';

class CustomerRepository {

    db: BraetDatabase;
    culture: () => string;

    constructor(db: BraetDatabase) {
        this.db = db;
        this.culture = () => (localStorage.getItem("culture") ? localStorage.getItem("culture") : "nl-BE") as string;
    }

    public async getAddress(address : string): Promise<any | null> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("GoogleMaps/GetAddress?address=" + address);

                if (res.status == 200) {
                    return res.data;
                } else {
                    return null;
                }
            } catch {
                return null;
            }
        }

        return null;
    }

    public async getOpenOrders(companyId? : string): Promise<any[]> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("Order/GetOrders?companyId=" + companyId);
                console.log('order response');
                console.log(res);
                if (res.status == 200) {
                    return res.data.orders as any[];
                } else {
                    throw "error";
                }
            } catch {
                throw "error";
            }
        }

        return [];
    }

    public async editOrder(orderItems : IArticle[], orderId : string): Promise<boolean> {
        if (navigator.onLine) {
            try {
                var data = {
                    Products : orderItems,
                    OrderId : orderId
                };
                var res = await ClickCollectApi.postData("Order/EditOrder", data);
                console.log('order response');
                console.log(res);
                if (res.status == 200) {
                    return res.data.success as boolean;
                } else {
                    throw "error";
                }
            } catch {
                throw "error";
            }
        }

        return false;
    }

   
    public async getOrderById(id : string): Promise<any> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("Order/GetOrderById?id=" + id);
                console.log('order response');
                console.log(res);
                if (res.status == 200) {
                    return res.data.order as any;
                } else {
                    throw "error";
                }
            } catch {
                throw "error";
            }
        }

        return [];
    }

    public async getFacturationAdres(): Promise<IUserFacturationInformation | undefined> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("User/GetFacturationAdres");
                console.log('order response');
                console.log(res);
                if (res.status == 200) {
                    return res.data.adres as IUserFacturationInformation;
                } else {
                    return undefined;
                }
            } catch {
                return undefined;
            }
        }

        return undefined;
    }

    public async getDeliveryAdres(): Promise<IUserFacturationInformation | undefined> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("User/GetDeliveryAdres");
                console.log('order response');
                console.log(res);
                if (res.status == 200) {
                    return res.data.adres as IUserFacturationInformation;
                } else {
                    return undefined;
                }
            } catch {
                return undefined;
            }
        }

        return undefined;
    }

    /**
     * Returns the user id of the current user or of the user he is loggedin as, if applicable
     */
    public getCurrentUserId(): string {
        console.log(Store.state.loggedInUser);
        return Store.state.loggedInUser.loggedInAs?.id ?? Store.state.loggedInUser.id;
    }

    public async clearPersonalData(skipBaskets = false): Promise<void> {
        try {
            Store.state.nbProductsInBasket = 0;
            // abort the active product transactions if any
            //ProductRepo.abortAllTransactions();

            // maintain only the unsynced baskets for a next session
            if (skipBaskets){
                this.db.baskets.filter(b => b.synced).delete();
            }                
            else
                await this.db.baskets.clear();

            //Store.state.nbProductsInBasket = await ProductRepo.getTotalProductsInBasket();
        } catch (e) {
            window.location.reload();
            console.log(e);
        }
    }
}

export interface ISubscription {
    isTrial: boolean;
    active: boolean;
    endDate: Date,
    startDate: Date,
    daysRemaining: number,
    shopActivated: boolean;
    initialActivated: boolean;
}

export interface IUserFacturationInformation {
    userFacturationInformationId : string;
    userId : string;
    firstName : string;
    lastName : string;
    vatNumber : string;
    registrationNumber : string;
    companyName : string;
    adres : string;
    email : string;
    number : string;
    bus : string | undefined;
    postalCode : string;
    city : string;
}

export class UserFacturationInformation implements IUserFacturationInformation {
    userFacturationInformationId = "";
    userId = "";
    firstName = "";
    lastName = "";
    email = "";
    vatNumber = "";
    companyName = "";
    adres = "";
    number = "";
    bus = "";
    postalCode = "";
    city = "";
    registrationNumber = "";
  }

export interface ICustomerResult {
    pageCount: number;
    nbResults: number;
    customers: ICustomer[];
}

export interface ISettings {
    ledColor: string | null;
    notificationSound: number | null;
    notificationSoundTime: number | null;
}

export interface ICustomerLetterResult {
    nbResults: number;
    customers: { [key: string]: ICustomer[]; };
}

export interface IUserResult {
    isLoggedIn: boolean;
    firstName: string;
    lastName: string;
    hasValidSubscription: boolean;
    loginUrl: string;
    customerId: string;
    companyName: string;
}

export interface ICustomer {
    id: string;
    klantNr: number;
    firstname: string;
    name: string | null;
}

export const CustomerRepo = new CustomerRepository(Db);