

















































































































































import Vue from "vue";
import ClickCollectApi from "../libs/ClickCollectApi";
import { ProductRepo } from "../libs/ProductRepository";
import { Store } from "../libs/Store";
import DesktopNav from "../components/shared/DesktopNav.vue";
import PageHeader from "../components/shared/PageHeader.vue";
// @ts-ignore
import Password from "vue-password-strength-meter";

export default Vue.extend({
  name: "Login" as string,
  components: {
    DesktopNav,
    PageHeader,
    Password,
  },
  data() {
    return {
      phoneConfig: {
        mode: "international",
        defaultCountry: "BE",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: "",
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: "username",
        name: "New-Phone",
        maxLen: 25,
        wrapperClasses: "",
        inputClasses: "form-control",
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      passwordScore: 0,
      phoneNumber: "" as string,
      token: "" as string,
      password: "" as string,
      confirmPassword: "" as string,
      errors: [] as string[],
      formError: [] as string[],
      store: Store.state,
      loading: false,
      authenticated: false,
    };
  },
  methods: {
    async requestNewPassword() {
      this.loading = true;
      this.errors = [];
      if (this.phoneNumber == "") {
        this.errors.push("phoneNumber");
      }

      if (this.confirmPassword == "") {
        this.errors.push("password-confirm");
      }

      if (
        this.password !== "" &&
        this.confirmPassword !== "" &&
        this.password !== this.confirmPassword
      ) {
        this.errors.push("password-not-equal");
      }

      if (this.password !== "" && this.passwordScore < 2) {
        this.errors.push("weak-password");
      }

      if (this.errors.length > 0) {
        this.loading = false;
        return;
      }

      try {
        var res = await ClickCollectApi.postData("User/SavePassword", {
          phoneNumber: this.phoneNumber,
          token: this.token,
          password: this.password,
        });

        var user = {
          userId: res.data.user.userId,
          phoneNumber: res.data.user.phoneNumber,
          firstName: res.data.user.firstName,
          lastName: res.data.user.lastName,
          email: res.data.user.email,
          loggedInAs: {},
        };

        this.store.loggedInUser = user;

        localStorage.setItem("user", JSON.stringify(user));
        this.$router.push("/");
      } catch (error) {
        console.log(error);
        var errRes = error.response;
        if (errRes && errRes.status !== 200) {
          if ("" in errRes.data) {
            this.formError = errRes.data[""];
          }
        } else {
          this.formError = [this.$t("noConnection") as string];
        }
        this.loading = false;
      }
    },
    updateScore(score: any) {
      this.passwordScore = score;
    },
  },
  mounted: function () {
    if (localStorage.getItem("user") != null) {
      this.authenticated = true;
    }
  },
});
