



import Vue from "vue";
import ClickCollectApi from "../libs/ClickCollectApi";
import { ProductRepo } from "../libs/ProductRepository";
import { Store } from "../libs/Store";

export default Vue.extend({
  name: "Logout" as string,
  mounted: function(){
    console.log('logout mounted');
    localStorage.removeItem("user");
    Store.state.loggedInUser = {};
    try {
        var res = ClickCollectApi.postData("User/Logout", {
         
        });
    } catch (error) {
      console.log(error);
      
    }

    // @ts-ignore
    var companyDomain = this.$session.get('companyDomain')

    // @ts-ignore
    var companySlug = this.$session.get('companyRedirect')

    if(companyDomain !== undefined){
      this.$router.push("/");
    }
    else if(companySlug !== undefined){         
      // @ts-ignore
      this.$router.push({
        path : companySlug,
      })
    }
    else{
      this.$router.push({ name: "home" });
    }
    
  }
});
