





























































































































































































































































































































































































































































































































import { CompanyRepo, ICompany } from "@/libs/CompanyRepository";
import Vue from "vue";
import { configs } from "../../libs/config";
import { Utils } from "../../libs/Utils";

export default Vue.extend({
  props: {
    company: Object,
  },
  data() {
    return {
      imageHost: configs.ImageHost,
      companyRedirect: false,
      activeTab: "takeAway",
      branches: [] as ICompany[],
      selectedBranch: null as ICompany | null,
    };
  },
  methods: {
    openingHourList: function (company: ICompany | undefined) {
      console.log(Utils.openingHourList(this.company));
      company = company ?? this.company;
      if (company !== undefined) {
        return Utils.openingHourList(company);
      }
      return "";
    },
    takeAwayHourList: function (company: ICompany | undefined) {
      company = company ?? this.company;
      if (company !== undefined) {
        return Utils.takeAwayHourList(company);
      }
      return "";
    },
    deliveryHourList: function (company: ICompany | undefined) {
      company = company ?? this.company;
      if (company !== undefined) {
        return Utils.deliveryHourList(company);
      }
      return "";
    },
    lineBreakToHtml(text: string) {
      if (!text) return "";
      return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
    },
    selectBranch(branch: ICompany) {
      this.selectedBranch = branch;
    },
  },
  async mounted() {
    // @ts-ignore
    var isRedirect = this.$session.get("companyRedirect");

    // @ts-ignore
    var isDomain = this.$session.get("companyDomain");

    if (isRedirect !== undefined || isDomain !== undefined) {
      this.companyRedirect = true;
    }

    this.branches = await CompanyRepo.getBranches(this.company.companyId);
  },
});
