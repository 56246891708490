


























































































































































































































































































import Vue from "vue";
import ClickCollectApi from "../libs/ClickCollectApi";
import { ProductRepo } from "../libs/ProductRepository";
import { Store } from "../libs/Store";
import { CompanyRepo, ICompany } from "../libs/CompanyRepository";
import { configs } from "../libs/config";
import {
  CustomerRepo,
  IUserFacturationInformation,
  UserFacturationInformation,
} from "../libs/CustomerRepository";
import PageHeader from "../components/shared/PageHeader.vue";
// @ts-ignore
import Password from "vue-password-strength-meter";
import DesktopNav from "../components/shared/DesktopNav.vue";
export default Vue.extend({
  name: "Facturation" as string,
  components: {
    Password,
    DesktopNav,
    PageHeader,
  },
  data() {
    return {
      company: undefined as ICompany | undefined,
      companyId: this.$route.params["id"],
      facturationAdres: {} as IUserFacturationInformation,
      imageHost: configs.ImageHost,
      errors: [] as string[],
      formError: [] as string[],
      store: Store.state,
      loading: false,
      authenticated: false,
    };
  },
  methods: {
    validEmail: function (email: any) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    setDisplayMode(mode: any) {
      this.$root.$emit("change-display-mode", mode);
    },
    validateVatNumber: async function () {
      if (!this.facturationAdres.vatNumber) return;

      this.$swal.fire({
        title: "BTW-nummer validatie",
        onBeforeOpen: () => {
          this.$swal.showLoading();
        },
      });
      console.log(this.facturationAdres.vatNumber);
      var response = await CompanyRepo.validateVat(
        this.facturationAdres.vatNumber
      );
      if (response == null) {
        this.$swal.fire(
          "Opgelet",
          "Controleer of het opgegeven BTW-nummer zeker correct is",
          "warning"
        );
      } else {
        if (response.Success) {
          this.facturationAdres.vatNumber = response.VAT;
          this.facturationAdres.companyName = this.facturationAdres.companyName
            ? this.facturationAdres.companyName
            : response.Name;
          this.facturationAdres.adres = this.facturationAdres.adres
            ? this.facturationAdres.adres
            : response.Street;
          this.facturationAdres.number = this.facturationAdres.number
            ? this.facturationAdres.number
            : response.Number;
          this.facturationAdres.city = this.facturationAdres.city
            ? this.facturationAdres.city
            : response.City;
          this.facturationAdres.postalCode = this.facturationAdres.postalCode
            ? this.facturationAdres.postalCode
            : response.PostalCode;

          this.$swal.close();
          this.$swal.fire(
            "Success",
            "Uw btw-nummer werd gevalideerd",
            "success"
          );
        } else {
          this.$swal.fire(
            "Opgelet",
            "Controleer of het opgegeven BTW-nummer zeker correct is",
            "warning"
          );
        }
      }
    },
    async save() {
      this.loading = true;
      this.errors = [];
      console.log("save");
      console.log(this.facturationAdres);
      if (this.facturationAdres.firstName == "") {
        this.errors.push("firstName");
      }

      if (this.facturationAdres.lastName == "") {
        this.errors.push("lastName");
      }

      if (this.facturationAdres.companyName == "") {
        this.errors.push("companyName");
      }

      if (this.facturationAdres.registrationNumber == "") {
        this.errors.push("registrationNumber");
      }

      if (this.facturationAdres.adres == "") {
        this.errors.push("adres");
      }

      if (this.facturationAdres.email == "") {
        this.errors.push("email");
      }

      if (this.facturationAdres.number == "") {
        this.errors.push("number");
      }

      if (this.facturationAdres.postalCode == "") {
        this.errors.push("postalCode");
      }

      if (this.facturationAdres.city == "") {
        this.errors.push("city");
      }

      if (this.errors.length > 0) {
        this.loading = false;
        return;
      }

      try {
        var res = await ClickCollectApi.postData(
          "User/SaveFacturationAdres",
          this.facturationAdres
        );

        this.errors = [];
        this.formError = [];
        this.loading = false;

        this.$router.push({
          name: "placeorder",
          params: {
            id: this.companyId,
          },
        });
      } catch (error) {
        console.log(error);
        var errRes = error.response;
        if (errRes && errRes.status !== 200) {
          if ("" in errRes.data) {
            this.formError = errRes.data[""];
          }
        } else {
          this.formError = [this.$t("noConnection") as string];
        }
        this.loading = false;
      }
    },

    previousStep() {
      var previousUrl =
        this.$route.query.previousUrl == null
          ? "/"
          : this.$route.query.previousUrl?.toString();
      this.$router.push(previousUrl);
    },

    imageError(imageId: string) {
      var el = document.getElementById(imageId);

      el?.setAttribute("src", "/images/product-placeholder.png");
    },
  },
  async mounted() {
    console.log(this.$route);
    console.log("pre process order");
    this.company = await CompanyRepo.getCompanyById(this.$route.params["id"]);
    var response = await CustomerRepo.getFacturationAdres();
    console.log("response");
    if (response === undefined || response === null) {
      this.facturationAdres = new UserFacturationInformation();
    } else {
      this.facturationAdres = response;
    }

    if (localStorage.getItem("user") != null) {
      this.authenticated = true;
    }
  },
});
