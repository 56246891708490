



















































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CompanyRepo, ICompany } from "../libs/CompanyRepository";
import { ProductRepo, IOrder } from "../libs/ProductRepository";
import Swal from "sweetalert2";
import { configs } from "../libs/config";
import CompanyFooter from "../components/shared/CompanyFooter.vue";

// @ts-ignore
import Autocomplete from "@trevoreyre/autocomplete-vue";

// @ts-ignore
import imagesLoaded from "vue-images-loaded";
import { IArticle } from "../libs/QuickCollectDatabase";
import { CustomerRepo } from "../libs/CustomerRepository";
import DesktopNav from "../components/shared/DesktopNav.vue";
import PageHeader from "../components/shared/PageHeader.vue";
import { UtilsRepo, Utils } from "../libs/Utils";

export default Vue.extend({
  name: "ConfirmOrderChanged" as string,
  components: {
    DesktopNav,
    PageHeader,
    CompanyFooter,
  },
  directives: {
    imagesLoaded,
  },
  data() {
    return {
      company: undefined as ICompany | undefined,
      order: undefined as IOrder | undefined,
      authenticated: false,
    };
  },
  methods: {
    openingHourList: function () {
      if (this.company !== undefined) {
        return Utils.openingHourList(this.company);
      }
      return "";
    },
    deliveryHourList: function () {
      if (this.company !== undefined) {
        return Utils.deliveryHourList(this.company);
      }
      return "";
    },
    imageError(imageId: string) {
      var el = document.getElementById(imageId);

      el?.setAttribute("src", "/images/product-placeholder.png");
    },
    setDisplayMode(mode: any) {
      this.$root.$emit("change-display-mode", mode);
    },
    redirectStart() {
      console.log("redirect start");
      // @ts-ignore
      console.log(this.$session.get("companyRedirect"));
      // @ts-ignore
      if (this.$session.get("companyDomain") !== undefined) {
        this.$router.push("/");
      }
      // @ts-ignore
      else if (this.$session.get("companyRedirect") !== undefined) {
        // @ts-ignore
        var companySlug = this.$session.get("companyRedirect");
        if (
          this.company?.companySlug !== null &&
          this.company?.companySlug !== undefined
        ) {
          this.$router.push("/" + this.company?.companySlug);
        } else {
          this.$router.push({ name: "companies" });
        }
      } else {
        this.$router.push({ name: "companies" });
      }
    },
    padNumber(number: any) {
      number = number.toString();

      while (number.length < 4) {
        number = "0" + number;
      }

      return number;
    },
  },
  async created() {
    this.company = await CompanyRepo.getCompanyById(this.$route.params["id"]);
    this.order = await CustomerRepo.getOrderById(this.$route.params["orderid"]);

    console.log(this.order);
    console.log(this.company);
  },
  async mounted() {
    this.company = await CompanyRepo.getCompanyById(this.$route.params["id"]);
    this.order = await CustomerRepo.getOrderById(this.$route.params["orderid"]);
    console.log(this.company);

    if (localStorage.getItem("user") != null) {
      this.authenticated = true;
    }
  },
});
