






























































































































































































































































































































































































































































































































































































































































































import { ProductRepo } from "@/libs/ProductRepository";
import Vue from "vue";
import { configs } from "../../libs/config";
import { IArticle } from "@/libs/QuickCollectDatabase";
// @ts-ignore
import VideoEmbed from "./VideoEmbed.vue";

// @ts-ignore
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default Vue.extend({
  props: {
    value: Object,
    companyId: String,
    company: Object,
    showOverlay: {
      type: Boolean,
      default: true,
    },
    extended: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueSlickCarousel,
    VideoEmbed,
  },
  data() {
    return {
      config: {
        // @ts-ignore
        middleware: this.middleware,
        events: ["dblclick", "click"],
      },
      imageHost: configs.ImageHost,
      linkedProductsByCategory: [] as any[],
      companyRedirect: false,
      product: this.value,
      isOpen: false,
      selectedPopupCategory: null as any | null,
      showDescription: false,
      componentKey: 0,
      selectedVariant1: "",
      selectedVariant2: "",
      selectedVariant3: "",
      variantError: false,
      branches: [] as any[],
    };
  },
  methods: {
    increaseAmount(product: any) {
      console.log(product !== undefined);
      if (product !== undefined) {
        product.depleted = false;
        product.amount += ProductRepo.GetQuantityStep(product);

        if (product.stock) {
          product.amount = Math.min(product.stock, product.amount);
          if (product.amount == product.stock) product.depleted = true;
        }

        //ProductRepo.addToBasket(this.product);
      }

      // @ts-ignore

      console.log(product.amount);
      console.log(product);
    },
    reduceAmount(product: any, zeroable?: boolean) {
      if (product !== undefined) {
        product.depleted = false;
        var decreaseAmount = ProductRepo.GetQuantityStep(product);
        console.log(decreaseAmount);
        console.log(product.amount);

        if (decreaseAmount >= product.amount) {
          product.amount = zeroable ? 0 : ProductRepo.GetQuantityStep(product);
        } else {
          product.amount -= decreaseAmount;
        }

        //ProductRepo.addToBasket(this.product);
        if (product.amount == product.stock) product.depleted = true;
      }
      console.log(product);
    },
    closeOrderOverlay: function () {
      console.log("close");
      this.$emit("close");
      console.log(
        this.$route.path.substring(0, this.$route.path.indexOf("/product"))
      );
      console.log(window.location.pathname);
      // strip of the product detail link
      if (window.location.pathname.indexOf("/product") > -1)
        window.history.back();
    },
    removeFromOverlay: function () {
      this.$emit("remove-from-basket");
    },
    submitLinkedProducts() {
      this.product.childProducts = [];

      this.product.childProducts = this.linkedProductsByCategory.flatMap((lp) =>
        lp.products.filter((p: any) => p.amount > 0)
      );

      console.log(this.product.childProducts);
      this.isOpen = false;
    },
    async submitOrderProduct() {
      // check if the variants are selected
      if (!this.isValidVariantSelection()) {
        this.variantError = true;
        this.$swal.fire("", this.$t("PleaseSelectVariant").toString(), "error");
        return;
      }

      this.variantError = false;

      //check if at least 1 amount is added
      console.log("add to basket");
      if (this.product !== undefined) {
        var self = this;
        this.product.companyId = this.companyId;

        if (!this.product.editMode) await ProductRepo.addToBasket(this.product);
        else await ProductRepo.updateInBasket(this.product);

        //this.store.nbProductsInBasket += this.quantity;
        this.$swal({
          title: "",
          icon: "success",
          text: this.$t("addedToBasket") as string,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          position: "top",
          showConfirmButton: false,
          customClass: {
            container: "confirm-order-swal",
          },
          animation: false,
        }).then((value) => {
          console.log(value);
          // if(value.dismiss && value.dismiss === Swal.DismissReason.close)
          //   self.$router.push({name: "basket"});
        });
        if (this.showOverlay) this.closeOrderOverlay();
        //this.currentCategory = "";
        //this.setViewMethod("category-overview", true);
        this.$emit("product-ordered", this.product);
      }
    },
    async changeProduct(otherProduct: IArticle) {
      console.log(otherProduct);
      console.log(this.product.childProducts);

      var productConverted: IArticle = {
        originalPrice: otherProduct.originalPrice,
        categoryName: otherProduct.categoryName,
        productName: otherProduct.productName,
        unitType: otherProduct.unitType,
        pricePerUnit: otherProduct.pricePerUnit,
        productId: otherProduct.productId,
        companyId: otherProduct.companyId,
        amount: otherProduct.amount,
        originalAmount: otherProduct.amount,
        image: otherProduct.image,
        availableDays: otherProduct.availableDays,
        customUnitType: otherProduct.customUnitType,
        customUnitValue: otherProduct.customUnitValue,
        customUnitType2: otherProduct.customUnitType2,
        customUnitValue2: otherProduct.customUnitValue2,
        customUnitType3: otherProduct.customUnitType3,
        customUnitValue3: otherProduct.customUnitValue3,
        deleted: false,
        otherProducts: this.product?.otherProducts ?? [],
        stock: otherProduct.stock,
        childProducts: this.product.childProducts ?? [],
        productDescription: otherProduct.productDescription,
        orderItemRemarkText: otherProduct.orderItemRemarkText,
        enableOrderItemRemarks: otherProduct.enableOrderItemRemarks,
        media: otherProduct.media,
        deposit: otherProduct.deposit,
        remark: this.product?.remark,
      };

      var previousAmount = this.product?.amount;
      var previousUnitType = this.product?.unitType;

      this.product = productConverted;
      this.$emit("input", this.product);

      console.log("previousAmount");
      console.log(previousAmount);

      if (
        previousAmount !== undefined &&
        previousUnitType == this.product.unitType
      ) {
        this.product.amount = previousAmount;
        if (this.product.stock)
          this.product.amount = Math.min(
            this.product.stock,
            this.product.amount
          );
      } else {
        this.product.amount = ProductRepo.GetQuantityStep(this.product);
      }

      this.linkedProductsByCategory = (
        await ProductRepo.getLinkedProductsByCategory(this.product.productId)
      ).filter((p) => p.category);

      this.initLinkedProductsQuantities();
    },
    openLinkedProductsPopup(category: any) {
      console.log(category);
      this.selectedPopupCategory = category;
      this.isOpen = true;
    },
    calculatePrice: function (product: IArticle): number | undefined {
      console.log(product);
      if (product.pricePerUnit === undefined || product.pricePerUnit === null) {
        return 0;
      } else {
        if (!ProductRepo.IsSingularUnitType(product.unitType)) {
          var price = product.amount * (product.pricePerUnit / 1000);
          return Math.round(price * 100) / 100;
        } else if (product.unitType == "WEIGHTPIECE") {
          return 0;
        } else {
          return product.amount * product.pricePerUnit;
        }
      }

      return 0;
    },
    getOrderedLinkedproductsByCategory: function (): any[] {
      var orderedProducts = [];

      for (var cat of this.linkedProductsByCategory) {
        var childProducts = cat.products.filter(
          (p: any) =>
            this.product.childProducts &&
            this.product.childProducts.some(
              (sp: any) => sp.productId == p.productId && sp.amount > 0
            )
        );

        if (childProducts.length > 0) {
          orderedProducts.push({
            category: cat.category,
            products: childProducts,
          });
        }
      }

      return orderedProducts;
    },
    initLinkedProductsQuantities() {
      for (var cat of this.linkedProductsByCategory) {
        for (var catProduct of cat.products) {
          catProduct.amount =
            this.product.childProducts.find(
              (p: any) => p.productId == catProduct.productId
            )?.amount ?? 0;
        }
      }
    },
    lineBreakToHtml(text: string) {
      if (!text) return "";
      return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
    },
    isVariantSelectable1(variant: string): boolean {
      return this.product.otherProducts.some(
        (p: IArticle) =>
          (!this.selectedVariant2 ||
            p.customUnitValue2 === this.selectedVariant2) &&
          (!this.selectedVariant3 ||
            p.customUnitValue3 === this.selectedVariant3) &&
          p.customUnitValue === variant
      );
    },
    isVariantSelectable2(variant: string): boolean {
      return this.product.otherProducts.some(
        (p: IArticle) =>
          (!this.selectedVariant1 ||
            p.customUnitValue === this.selectedVariant1) &&
          (!this.selectedVariant3 ||
            p.customUnitValue3 == this.selectedVariant3) &&
          p.customUnitValue2 === variant
      );
    },
    isVariantSelectable3(variant: string): boolean {
      return this.product.otherProducts.some(
        (p: IArticle) =>
          (!this.selectedVariant1 ||
            p.customUnitValue === this.selectedVariant1) &&
          (!this.selectedVariant2 ||
            p.customUnitValue2 == this.selectedVariant2) &&
          p.customUnitValue3 === variant
      );
    },
    changeVariant1(variant: string) {
      console.log(variant);
      // toggle value
      this.selectedVariant1 = this.selectedVariant1 != variant ? variant : "";
      // reset deeper selected variants
      this.selectedVariant2 = "";
      this.selectedVariant3 = "";

      // there is no second and third variant => change product
      if (!this.product.customUnitType3 && !this.product.customUnitType3) {
        const product = this.product.otherProducts.find(
          (p: IArticle) => p.customUnitValue == variant
        );
        this.changeProduct(product);
      }
    },
    changeVariant2(variant: string) {
      // toggle value
      this.selectedVariant2 = this.selectedVariant2 != variant ? variant : "";
      // reset deeper selected variants
      this.selectedVariant3 = "";

      // there is no third variant => change product
      if (!this.product.customUnitType3) {
        const product = this.product.otherProducts.find(
          (p: IArticle) =>
            p.customUnitValue2 == variant &&
            p.customUnitValue == this.selectedVariant1
        );
        this.changeProduct(product);
      }
    },
    changeVariant3(variant: string) {
      // toggle value
      this.selectedVariant3 = this.selectedVariant3 != variant ? variant : "";
      const product = this.product.otherProducts.find(
        (p: IArticle) =>
          p.customUnitValue3 == variant &&
          p.customUnitValue2 == this.selectedVariant2 &&
          p.customUnitValue == this.selectedVariant1
      );
      this.changeProduct(product);
    },
    isValidVariantSelection() {
      // if in edit mode there is always a variant selected
      if (this.product.editMode) return true;

      return (
        (!this.product.customUnitValue || this.selectedVariant1) &&
        (!this.product.customUnitType2 || this.selectedVariant2) &&
        (!this.product.customUnitType3 || this.selectedVariant3)
      );
    },
  },
  async mounted() {
    this.product = this.value;

    console.log(this.product);

    this.linkedProductsByCategory = (
      await ProductRepo.getLinkedProductsByCategory(this.product.productId)
    ).filter((p) => p.category);

    console.log("linkedProductsMounted", this.linkedProductsByCategory);

    this.initLinkedProductsQuantities();
    if (this.extended && !this.$route.path.includes("product")) {
      // strip of category link if present
      var newPath =
        this.$route.path.indexOf("/cat") > -1
          ? this.$route.path
              .substring(0, this.$route.path.indexOf("/cat"))
              .replace(/\/?$/, "/")
          : this.$route.path;

      history.pushState(
        {},
        "",
        newPath.replace(/\/?$/, "/") +
          "product/" +
          this.product.productId +
          "/" +
          this.product.slug
      );
    }
    if (this.level1Variants.length == 1)
      this.selectedVariant1 = this.product.customUnitValue;

    this.branches = await ProductRepo.getBranches(this.product.productId);
  },
  computed: {
    popupProducts: function (): any[] {
      var cat = this.linkedProductsByCategory.find(
        (c) => c.category?.id == this.selectedPopupCategory?.id
      );
      console.log(cat);
      if (cat) return cat.products;
      else return [];
    },
    enableOrderItemRemark: function (): boolean {
      console.log("remark", this.product);
      console.log("remark", this.company);
      if (!this.product || !this.company) return false;

      if (
        this.product.enableOrderItemRemarks !== null &&
        this.product.enableOrderItemRemarks !== undefined
      )
        return this.product.enableOrderItemRemarks;
      else return this.company.enableOrderItemRemarks;
    },
    level1Variants: function (): string[] {
      if (!this.product.otherProducts) return [];
      // return all unique level 1 variant values
      return this.product.otherProducts
        .map((p: IArticle) => p.customUnitValue)
        .filter(
          (u: any, index: any, self: string[]) => self.indexOf(u) === index && u
        );
    },
    level2Variants: function (): string[] {
      if (!this.product.otherProducts) return [];
      // return all unique level 1 variant values
      return this.product.otherProducts
        .map((p: IArticle) => p.customUnitValue2)
        .filter(
          (u: any, index: any, self: string[]) => self.indexOf(u) === index
        );
    },
    level3Variants: function (): string[] {
      if (!this.product.otherProducts) return [];
      // return all unique level 1 variant values
      return this.product.otherProducts
        .map((p: IArticle) => p.customUnitValue3)
        .filter(
          (u: any, index: any, self: string[]) => self.indexOf(u) === index
        );
    },
  },
  watch: {
    isOpen: function () {
      // reset quantities in overlay
      this.initLinkedProductsQuantities();
    },
    value: async function () {
      this.product = this.value;
      this.linkedProductsByCategory = (
        await ProductRepo.getLinkedProductsByCategory(this.product.productId)
      ).filter((c) => c.category);

      this.initLinkedProductsQuantities();

      if (this.level1Variants.length == 1)
        this.selectedVariant1 = this.product.customUnitValue;

      this.branches = await ProductRepo.getBranches(this.product.productId);
    },
  },
});
