import { render, staticRenderFns } from "./OrderOverview.vue?vue&type=template&id=44a4cff1&scoped=true&"
import script from "./OrderOverview.vue?vue&type=script&lang=ts&"
export * from "./OrderOverview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44a4cff1",
  null
  
)

export default component.exports