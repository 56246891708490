import axios, { AxiosResponse } from 'axios'
import ClickCollectApi from './ClickCollectApi'
import BraetDatabase, { IArticle, Basket, ArticleQuantity, IBasket, Db } from './QuickCollectDatabase'
import { Vm } from '@/main';
import { Store } from './Store';
import { $cookies } from 'vue/types/umd';
import { Route } from 'vue-router';

class CompanyRepository {

    db: BraetDatabase;
    culture: () => string;
    abortTransactions: boolean;

    constructor(db: BraetDatabase) {
        this.db = db;
        this.culture = () => (localStorage.getItem("culture") ? localStorage.getItem("culture") : "nl-BE") as string;
        this.abortTransactions = false;
    }

    public async getCompanies(start: number, nbPerPage: number, search: string | null, sector: string[] | null, status: string | null, sorting: string | null, userId: string | null, longitude: string | null, latitude: string | null): Promise<ICompanyResult | null> {

        console.log(longitude);
        console.log(latitude);
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.postData("Company/GetCompanies", {
                    start: start,
                    nbPerPage: nbPerPage,
                    searchString: search,
                    sector: sector,
                    status: status,
                    sorting: sorting,
                    userId: userId,
                    longitude: longitude,
                    latitude: latitude,
                    favoriteCompanyIds: localStorage.getItem("favoriteCompanies") ? localStorage.getItem("favoriteCompanies")?.split(",") : []
                });
                if (res.status == 200) {
                    return res.data as ICompanyResult;
                } else {
                    return null;
                }
            } catch {
                return null;
            }
        }

        return null;
    }

    public async getCompanyById(id: string): Promise<ICompany | undefined> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("Company/GetCompanyById?id=" + id);

                if (res.status == 200) {
                    return res.data as ICompany;
                } else {
                    return undefined;
                }
            } catch {
                return undefined;
            }
        }

        return undefined;
    }

    public async getCompanyBySlug(path: string): Promise<ICompany | undefined> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("Company/GetCompanyBySlug?slug=" + path);
                console.log(res);
                if (res.status == 200) {
                    return res.data as ICompany | undefined;
                } else {
                    return undefined;
                }
            } catch {
                return undefined;
            }
        }

        return undefined;
    }

    public async getCompanyByDomain(path: string): Promise<ICompany | undefined> {
        console.log('getCompanyByDomain');
        console.log(path);
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("Company/GetCompanyByDomain?domain=" + path);
                console.log(res);
                if (res.status == 200) {
                    return res.data as ICompany | undefined;
                } else {
                    return undefined;
                }
            } catch {
                return undefined;
            }
        }

        return undefined;
    }


    public async getDisplaySectors(): Promise<string[]> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("Company/GetDisplaySectors");

                if (res.status == 200) {
                    return res.data as string[];
                } else {
                    return [];
                }
            } catch {
                return [];
            }
        }

        return [];
    }

    public async validateVat(vatNumber: string): Promise<any> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getWithoutBaseData("https://www.quickcollect.be/umbraco/surface/ClickCollectApi/GetSimpleCompanyByVat?vat=" + vatNumber);
                if (res.status == 200) {
                    return res.data as any;
                } else {
                    return null;
                }
            } catch {
                return null;
            }
        }

        return null;
    }



    public async getSectorImage(sector: string): Promise<any> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getWithoutBaseData("https://www.quickcollect.be/umbraco/surface/ClickCollectApi/GetSectorImage?sector=" + sector);
                if (res.status == 200) {
                    return res.data as any;
                } else {
                    return null;
                }
            } catch {
                return null;
            }
        }

        return null;
    }


    public async getSectors(): Promise<ISector[]> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("Company/GetSectors");

                if (res.status == 200) {
                    return res.data as ISector[];
                } else {
                    return [];
                }
            } catch {
                return [];
            }
        }

        return [];
    }

    public async getOpeningsHours(id: string): Promise<any[]> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("Company/GetOpeningsHoursByCompanyId?id=" + id);
                console.log(res);
                if (res.status == 200) {
                    return res.data as any[];
                } else {
                    return [];
                }
            } catch {
                return [];
            }
        }

        return [];
    }

    public async getTakeAwayHours(id: string): Promise<any[]> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("Company/GetTakeAwayHoursByCompanyId?id=" + id);
                console.log(res);
                if (res.status == 200) {
                    return res.data as any[];
                } else {
                    return [];
                }
            } catch {
                return [];
            }
        }

        return [];
    }

    public async getDeliveryHours(id: string): Promise<any[]> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("Company/GetDeliveryHoursByCompanyId?id=" + id);
                console.log(res);
                if (res.status == 200) {
                    return res.data as any[];
                } else {
                    return [];
                }
            } catch {
                return [];
            }
        }

        return [];
    }

    public async getVacations(id: string): Promise<any[]> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("Company/GetVacationsByCompanyId?id=" + id);
                console.log(res);
                if (res.status == 200) {
                    return res.data as any[];
                } else {
                    return [];
                }
            } catch {
                return [];
            }
        }

        return [];
    }

    public async getCompanyByIds(productIds: string[]): Promise<ICompany[] | null> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.postData("Company/GetCompanyByIds", productIds);

                if (res.status == 200) {
                    return res.data as ICompany[];
                } else {
                    return null;
                }
            } catch {
                return null;
            }
        }

        return null;
    }

    public async updateFavoriteStatus(companyId: string, userId: string, status: boolean): Promise<boolean> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.postData("Company/UpdateFavoriteStatus", {
                    companyId: companyId,
                    userId: userId,
                    status: status
                });
                if (res.status == 200) {
                    return true;
                } else {
                    return false;
                }
            } catch {
                return false;
            }
        }

        return false;
    }

    public async getCompanyByRoute(route: Route): Promise<ICompany | undefined> {
        var isMainSite = window.location.host.indexOf("quickcollect.shop") !== -1 || window.location.host.indexOf("quickcollectcustomer.debugged.be") !== -1 || window.location.host.indexOf("localhost") !== -1;

        if (route.name == "companyRedirect") {
            return await CompanyRepo.getCompanyBySlug(route.path);
        } else if (
            route.path == "/" ||
            route.name?.includes("CustomDomain")
            || !isMainSite
        ) {
            return await this.getCompanyByDomain(window.location.hostname)
        } else if (route.params.slug) {
            // @ts-ignore
            return await this.getCompanyBySlug(route.params.slug)
        }
    }

    public async getBranches(companyId: string): Promise<ICompany[]> {

        try {
            var res = await ClickCollectApi.getData("Company/GetBranches?companyId=" + companyId);
            if (res.status == 200) {
                return res.data;
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }
}
export interface ICompanyResult {
    pageCount: number;
    nbResults: number;
    companies: ICompany[];
}

export interface ISector {
    id: number;
    name: string;
    value: string;
    displayOrder: number;
    culture: string;
}


export interface ICompany {
    companyId: string;
    companyName: string;
    logo: string;
    closed: boolean | null;
    closestOpeningTime: string | null;
    favorite: boolean | null;
    latitude: string | null;
    longitude: string | null;
    sector: string | null;
    distance: string | null;
    paymentMethods: string | null;
    timeToPrepareDelivery: number | null,
    closeOrders: string | null,
    openingHourPrinted: string | null,
    street: string | null,
    number: string | null,
    postalcode: string | null,
    vatNumber: string | null,
    city: string | null,
    phone: string | null
    companyOrderNummer: number | null;
    newsItem: string | null;
    newsItemTitle: string | null;
    companySlug: string | null;
    enableDelivery: boolean;
    enableTakeAway: boolean;
    primaryColor: string | null;
    secondaryColor: string | null;
    buttonColor: string | null;
    atmosphericImage: string | null;
    openinghours: any[],
    deliveryhours: any[],
    takeawayhours: any[],
    onlinePaymentEnabled: boolean,
    deliveryHoursText?: string,
    takeAwayHoursText?: string,
    enableCategoryTileView: boolean
}
export const CompanyRepo = new CompanyRepository(Db);