












































































































































































































































































































































































































































































































































































import Vue from "vue";
import ClickCollectApi from "../libs/ClickCollectApi";
import { ProductRepo } from "../libs/ProductRepository";
import { Store } from "../libs/Store";
// @ts-ignore
import Password from "vue-password-strength-meter";
import DesktopNav from "../components/shared/DesktopNav.vue";
import { UtilsRepo, Utils } from "../libs/Utils";
import PageHeader from "../components/shared/PageHeader.vue";
import CompanyFooter from "../components/shared/CompanyFooter.vue";
import { CompanyRepo, ICompany } from "../libs/CompanyRepository";

export default Vue.extend({ 
  name: "Login" as string,
  components: {
    Password,
    DesktopNav,
    PageHeader,
    CompanyFooter,
  },
  data() {
    return {
      existingUserInput: {
        phoneNumber: "" as string,
        password: "" as string,
      },
      phoneConfig: {
        mode: "international",
        defaultCountry: "BE",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: "",
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: "username",
        name: "New-Phone",
        maxLen: 25,
        wrapperClasses: "",
        inputClasses: "form-control",
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      validateUser: {
        phoneNumber: "" as string,
        token: "" as string,
      },
      newUserInput: {
        phoneNumber: "" as string,
        email: "" as string,
        firstName: "" as string,
        lastName: "" as string,
        password: "" as string,
        confirmPassword: "" as string,
        culture: "nl-BE" as string,
        honeypot: "" as string,
        score: 0 as number,
        sendSms: false as boolean,
        sendPush: true as boolean,
        sendMail: true as boolean,
        privacyPolicy: false as boolean,
      },
      company: undefined as ICompany | undefined,
      validPhone: false as boolean,
      viewMode: "existing-user" as string,
      //viewMode : "validate-user" as string,
      errors: [] as string[],
      formError: [] as string[],
      store: Store.state,
      loading: false,
      authenticated: false,
      showMobileMenu: false,
      orderMode: false,
    };
  },
  methods: {
    isIos() {
      Utils.isIos();
    },
    submitOnEnter(e: any) {
      if (e.keyCode === 13) {
        this.login();
      }
    },
    updateScore(score: any) {
      this.newUserInput.score = score;
    },
    onInput(formattedNumber: any, number: any, valid: any, country: any) {
      console.log(formattedNumber);
      console.log(number);
      console.log(valid);
      console.log(country);
      this.validPhone = number.isValid;
    },
    setDisplayMode(mode: any) {
      this.$root.$emit("change-display-mode", mode);
    },
    validEmail: function (email: any) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    enterVerification() {
      this.validateUser.phoneNumber = this.existingUserInput.phoneNumber;
      this.errors = [];
      this.formError = [];
      this.viewMode = "validate-user";
      this.loading = false;
    },
    async login() {
      this.loading = true;
      this.errors = [];
      if (this.existingUserInput.phoneNumber == "") {
        this.errors.push("phoneNumber");
      } else if (this.validPhone == false) {
        this.errors.push("valid-phoneNumber");
      }

      if (this.existingUserInput.password == "") {
        this.errors.push("password");
      }

      if (this.errors.length > 0) {
        this.loading = false;
        return;
      }

      try {
        var res = await ClickCollectApi.postData("User/Login", {
          PhoneNumber: this.existingUserInput.phoneNumber,
          Password: this.existingUserInput.password,
        });

        console.log(res);

        var user = {
          userId: res.data.user.userId,
          phoneNumber: res.data.user.phoneNumber,
          email: res.data.user.email,
          firstName: res.data.user.firstName,
          lastName: res.data.user.lastName,
          culture: res.data.user.culture,
          sendSms: res.data.user.sendSms,
          sendPush: res.data.user.sendPush,
          sendMail: res.data.user.sendMail,
          loggedInAs: {},
        };

        this.store.loggedInUser = user;

        localStorage.setItem("user", JSON.stringify(user));
        var redirectUrl =
          this.$route.query.redirectUrl == null
            ? "/"
            : this.$route.query.redirectUrl?.toString();
        console.log("redirectUrl");
        console.log(redirectUrl);

        // @ts-ignore
        var companySlug = this.$session.get("companyRedirect");

        if (companySlug !== undefined && redirectUrl === "/") {
          // @ts-ignore
          this.$router.push({
            path: companySlug,
          });
        } else {
          this.$router.push(redirectUrl === "/login" ? "/" : redirectUrl);
        }
      } catch (error) {
        console.log(error);
        var errRes = error.response;
        if (errRes && errRes.status !== 200) {
          if ("" in errRes.data) {
            this.formError = errRes.data[""];
          }
        } else {
          this.formError = [this.$t("noConnection") as string];
        }
        this.loading = false;
      }
    },
    async register() {
      this.loading = true;
      this.errors = [];

      console.log(this.newUserInput.privacyPolicy);
      console.log(this.newUserInput.phoneNumber);
      if (this.newUserInput.phoneNumber == "") {
        this.errors.push("phoneNumber");
      } else if (this.validPhone == false) {
        this.errors.push("valid-phoneNumber");
      }

      if (this.newUserInput.email == "") {
        this.errors.push("email");
      } else if (!this.validEmail(this.newUserInput.email)) {
        this.errors.push("valid-email");
      }

      if (this.newUserInput.password == "") {
        this.errors.push("password");
      }

      if (this.newUserInput.confirmPassword == "") {
        this.errors.push("password-confirm");
      }

      if (
        this.newUserInput.password !== "" &&
        this.newUserInput.confirmPassword !== "" &&
        this.newUserInput.password !== this.newUserInput.confirmPassword
      ) {
        this.errors.push("password-not-equal");
      }

      if (this.newUserInput.password !== "" && this.newUserInput.score < 2) {
        this.errors.push("weak-password");
      }

      if (this.newUserInput.firstName == "") {
        this.errors.push("firstName");
      }

      if (this.newUserInput.lastName == "") {
        this.errors.push("lastName");
      }

      if (this.newUserInput.culture == "") {
        this.errors.push("culture");
      }

      if (this.newUserInput.honeypot !== "") {
        this.errors.push("bot");
      }

      if (this.newUserInput.privacyPolicy === false) {
        this.errors.push("privacy");
      }

      if (this.errors.length > 0) {
        this.loading = false;
        return;
      }

      try {
        var res = await ClickCollectApi.postData("User/Register", {
          PhoneNumber: this.newUserInput.phoneNumber,
          Email: this.newUserInput.email,
          Password: this.newUserInput.password,
          FirstName: this.newUserInput.firstName,
          LastName: this.newUserInput.lastName,
          Culture: this.newUserInput.culture,
          SendSms: this.newUserInput.sendSms,
          SendPush: this.newUserInput.sendPush,
          SendMail: this.newUserInput.sendMail,
        });

        console.log(res);
        this.validateUser.phoneNumber = this.newUserInput.phoneNumber;
        this.errors = [];
        this.formError = [];
        this.viewMode = "validate-user";
        this.loading = false;

        // var user = {
        //   userId: res.data.user.userId,
        //   phoneNumber: res.data.user.phoneNumber,
        //   firstName: res.data.user.firstName,
        //   lastName : res.data.user.lastName,
        //   culture: res.data.user.culture,
        //   loggedInAs: {}
        // };

        // this.store.loggedInUser = user;

        // localStorage.setItem("user", JSON.stringify(user));

        // var redirectUrl = this.$route.query.redirectUrl == null ? "/" : this.$route.query.redirectUrl?.toString();

        // this.$router.push('/companies');
      } catch (error) {
        console.log(error);
        var errRes = error.response;
        if (errRes && errRes.status !== 200) {
          if ("" in errRes.data) {
            this.formError = errRes.data[""];
          }
        } else {
          this.formError = [this.$t("noConnection") as string];
        }
        this.loading = false;
      }
    },
    async validate() {
      this.loading = true;
      this.errors = [];

      if (this.validateUser.phoneNumber == "") {
        this.errors.push("phoneNumber");
      } else if (this.validPhone == false) {
        this.errors.push("valid-phoneNumber");
      }

      if (this.validateUser.token == "") {
        this.errors.push("token");
      }

      if (this.errors.length > 0) {
        console.log(this.errors);
        this.loading = false;
        return;
      }

      try {
        var res = await ClickCollectApi.postData("User/ValidateUser", {
          PhoneNumber: this.validateUser.phoneNumber,
          Token: this.validateUser.token,
        });

        console.log(res);

        var user = {
          userId: res.data.user.userId,
          phoneNumber: res.data.user.phoneNumber,
          email: res.data.user.email,
          firstName: res.data.user.firstName,
          lastName: res.data.user.lastName,
          culture: res.data.user.culture,
          sendSms: res.data.user.sendSms,
          sendPush: res.data.user.sendPush,
          sendMail: res.data.user.sendMail,
          loggedInAs: {},
        };

        this.store.loggedInUser = user;

        localStorage.setItem("user", JSON.stringify(user));

        var redirectUrl =
          this.$route.query.redirectUrl == null
            ? "/"
            : this.$route.query.redirectUrl?.toString();
        console.log("redirectUrl");
        console.log(redirectUrl);
        // @ts-ignore
        var companySlug = this.$session.get("companyRedirect");
        console.log("company slug");
        console.log(companySlug);
        if (companySlug !== undefined && redirectUrl === "/") {
          // @ts-ignore
          this.$router.push({
            path: companySlug,
          });
        } else {
          this.$router.push(redirectUrl === "/login" ? "/" : redirectUrl);
        }
      } catch (error) {
        console.log(error);
        var errRes = error.response;
        if (errRes && errRes.status !== 200) {
          if ("" in errRes.data) {
            this.formError = errRes.data[""];
          }
        } else {
          this.formError = [this.$t("noConnection") as string];
        }
        this.loading = false;
      }
    },
    setViewMode(method: string) {
      this.errors = [];
      this.formError = [];
      this.viewMode = method;
    },
    previousStep() {
      if (this.viewMode == "new-user") {
        this.viewMode = "existing-user";
        return;
      }
      var previousUrl =
        this.$route.query.previousUrl == null
          ? "/"
          : this.$route.query.previousUrl?.toString();

      if (
        previousUrl == "/" &&
        // @ts-ignore
        this.$session.get("companyRedirect") !== undefined
      ) {
        // @ts-ignore
        var companySlug = this.$session.get("companyRedirect");
        previousUrl = "/" + companySlug;
      }
      console.log("previousurl");
      console.log(previousUrl);
      this.$router.push(previousUrl);
    },
    showSettingsMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
  },
  mounted: async function () {
    console.log("store logging");
    console.log(this.store);
    this.store.loggedInUser = {};
    console.log(this.$route);

    if (Utils.isIos()) {
      this.newUserInput.sendPush = false;
    }

    if (localStorage.getItem("user") != null) {
      this.authenticated = true;
    }

    var redirectUrl =
      this.$route.query.redirectUrl == null
        ? "/"
        : this.$route.query.redirectUrl?.toString();
    console.log("redirectUrl");
    console.log(redirectUrl);

    if (
      redirectUrl.startsWith("/company") &&
      redirectUrl.indexOf("pre-place-order") !== -1
    ) {
      var companyId = redirectUrl
        .replace("/company/", "")
        .replace("/pre-place-order", "");
      this.company = await CompanyRepo.getCompanyById(companyId);
    }

    // @ts-ignore


    this.orderMode =
      this.$route.query.redirectUrl &&
      this.$route.query.redirectUrl.includes("pre-place-order")
        ? true
        : false;
  },
});
