import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import Home from './components/Home.vue';
import Company from './components/Company.vue';
import LocationDenied from './components/LocationDenied.vue';
import Login from "./components/Login.vue";
import ProcessOrder from "./components/ProcessOrder.vue";
import MyAccount from "./components/MyAccount.vue";
import Profile from "./components/Profile.vue";
import Companies from "./components/Companies.vue";
import ForgotPassword from "./components/ForgotPassword.vue";
import ResetPassword from "./components/ResetPassword.vue";
import OrderOverview from "./components/OrderOverview.vue";
import Facturation from "./components/FacturationInformation.vue";
import ConfirmOrder from "./components/ConfirmOrder.vue";
import ConfirmOrderChanged from "./components/ConfirmOrderChanged.vue";
import ChangePassword from "./components/ChangePassword.vue";
import ProductDetail from "./components/ProductDetail.vue";
import AboutUs from "./components/AboutUs.vue";
import Allergene from "./components/Allergene.vue";
import Contact from "./components/Contact.vue";
import OpeningHours from "./components/OpeningHours.vue";



import LogOut from "./components/LogOut.vue";
import PreProcessOrder from "./components/PreProcessOrder.vue";
import CookiePolicy from "./components/CookiePolicy.vue";
import Privacy from "./components/Privacy.vue";
import Help from "./components/Help.vue";
import EditOrder from "./components/EditOrder.vue";
import VueI18n from 'vue-i18n';
import messages from './lang';
import './registerServiceWorker';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { configs } from './libs/config';
// @ts-ignore
import vueConfig from 'vue-config';
import { Store } from './libs/Store';
// @ts-ignore
import VueWorker from 'vue-worker';
import VueCookies from 'vue-cookies';
// @ts-ignore
import Datetime from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
// @ts-ignore
import * as VueGoogleMaps from 'vue2-google-maps'
// @ts-ignore
import vco from "v-click-outside";
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
// @ts-ignore
import VueTelInput from 'vue-tel-input'
// @ts-ignore
import VTooltip from 'v-tooltip'
// @ts-ignore
import ProductRedirect from "./components/ProductRedirect.vue";
// @ts-ignore
import VueFilterDateParse from '@vuejs-community/vue-filter-date-parse';




// @ts-ignore
import VueSession from 'vue-session'
import VueMeta from 'vue-meta'

var globalVTooltipOptions = {
  // Default tooltip placement relative to target element
  defaultPlacement: 'top',
  // Default CSS classes applied to the tooltip element
  defaultClass: 'vue-tooltip-theme',
  // Default CSS classes applied to the target element of the tooltip
  defaultTargetClass: 'has-tooltip',
  // Is the content HTML by default?
  defaultHtml: true,
  // Default HTML template of the tooltip element
  // It must include `tooltip-arrow` & `tooltip-inner` CSS classes (can be configured, see below)
  // Change if the classes conflict with other libraries (for example bootstrap)
  defaultTemplate: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
  // Selector used to get the arrow element in the tooltip template
  defaultArrowSelector: '.tooltip-arrow, .tooltip__arrow',
  // Selector used to get the inner content element in the tooltip template
  defaultInnerSelector: '.tooltip-inner, .tooltip__inner',
  // Delay (ms)
  defaultDelay: 0,
  // Default events that trigger the tooltip
  defaultTrigger: 'click hover focus',
  // Default position offset (px)
  defaultOffset: 0,
  // Default container where the tooltip will be appended
  defaultContainer: 'body',
  defaultBoundariesElement: undefined,
  defaultPopperOptions: {},
  // Class added when content is loading
  defaultLoadingClass: 'tooltip-loading',
  // Displayed when tooltip content is loading
  defaultLoadingContent: '...',
  // Hide on mouseover tooltip
  autoHide: true,
  // Close tooltip on click on tooltip target?
  defaultHideOnTargetClick: true,
  // Auto destroy tooltip DOM nodes (ms)
  disposeTimeout: 5000,
  // Options for popover
  popover: {
    defaultPlacement: 'bottom',
    // Use the `popoverClass` prop for theming
    defaultClass: 'vue-popover-theme',
    // Base class (change if conflicts with other libraries)
    defaultBaseClass: 'tooltip popover',
    // Wrapper class (contains arrow and inner)
    defaultWrapperClass: 'wrapper',
    // Inner content class
    defaultInnerClass: 'tooltip-inner popover-inner',
    // Arrow class
    defaultArrowClass: 'tooltip-arrow popover-arrow',
    // Class added when popover is open
    defaultOpenClass: 'open',
    defaultDelay: 0,
    defaultTrigger: 'click',
    defaultOffset: 0,
    defaultContainer: 'body',
    defaultBoundariesElement: undefined,
    defaultPopperOptions: {},
    // Hides if clicked outside of popover
    defaultAutoHide: true,
    // Update popper on content resize
    defaultHandleResize: true,
  },
}

Vue.use(vco);
Vue.config.productionTip = true;
Vue.use(VueRouter);
Vue.use(VueCookies);
Vue.use(Datetime);

Vue.use(VueTelInput)
Vue.use(VTooltip, globalVTooltipOptions)
Vue.use(VueSession, { persist: true })

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.filter('dateParse', function (value: string) {
  if (value) {
    return new Date(value)
  }
})

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyB0Wp7l4BPpclXI9-EW7S03-SSvz4CSp_c',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})


var defaultRoutes = [
  { name: 'locationDenied', path: '/location-denied', component: LocationDenied },
  { name: 'placeorder', path: '/company/:id/place-order', component: ProcessOrder, meta: { requiresAuth: true } },
  { name: 'ordercomplete', path: '/company/:id/order-complete/:orderid', component: ConfirmOrder, meta: { requiresAuth: true } },
  { name: 'profile', path: '/profile', component: Profile, meta: { requiresAuth: true } },
  
  { name: 'myaccount', path: '/myaccount', component: MyAccount, meta: { requiresAuth: true } },
  { name: 'login', path: '/login', component: Login },
  { name: 'forgotPassword', path: '/forgotPassword', component: ForgotPassword },
  { name: 'resetPassword', path: '/resetPassword', component: ResetPassword },
  { name: 'orderoverview', path: '/order-overview', component: OrderOverview },
  { name: 'editorder', path: '/company/:id/edit-order/:orderid', component: EditOrder, meta: { requiresAuth: true } },
  { name: 'orderchangecomplete', path: '/company/:id/order-changed/:orderid', component: ConfirmOrderChanged, meta: { requiresAuth: true } },
  { name: 'preplaceorder', path: '/company/:id/pre-place-order', component: PreProcessOrder, meta: { requiresAuth: true } },
  { name: 'help', path: '/help', component: Help },
  { name: 'changePassword', path: '/change-password', component: ChangePassword },
  { name: 'logOut', path: '/log-out', component: LogOut },
  { name: 'facturation', path: '/company/:id/facturation', component: Facturation, meta: { requiresAuth: true } },
]

var mainSite = window.location.host.indexOf("quickcollect.shop") !== -1 || window.location.host.indexOf("quickcollectcustomer.debugged.be") !== -1 || window.location.host.indexOf("localhost") !== -1;
// no custom domain url
if (mainSite) {
  defaultRoutes.push({ name: 'home', path: '/', component: Home });
  defaultRoutes.push({ name: 'companies', path: '/companies/:sector?', component: Companies });
  defaultRoutes.push({ name: 'company', path: '/:slug/d', component: Company });
  defaultRoutes.push({ name: 'companyProduct', path: '/:companySlug/d/product/:productId/:productSlug?', component: ProductDetail });
  defaultRoutes.push({ name: 'companyProductCategoryd', path: '/:slug/d/cat/:categoryId/:categorySlug?', component: Company });
  defaultRoutes.push({ name: 'companyProduct', path: '/:companySlug/product/:productId/:productSlug?', component: ProductDetail });
  defaultRoutes.push({ name: 'companyProductCategory', path: '/:slug/cat/:categoryId/:categorySlug?', component: Company });
  defaultRoutes.push({ name: 'about', path: '/:slug/about', component: AboutUs });
  defaultRoutes.push({ name: 'allergene', path: '/:slug/allergenen', component: Allergene });
  defaultRoutes.push({ name: 'contact', path: '/:slug/contact', component: Contact });
  defaultRoutes.push({ name: 'openingHours', path: '/:slug/hours', component: OpeningHours });
  defaultRoutes.push({ name: 'companyOrderOverview', path: '/:slug/order-overview', component: OrderOverview });
  defaultRoutes.push({ name: 'companyMyAccount', path: '/:slug/myaccount', component: MyAccount });
  defaultRoutes.push({ name: 'companyProfile', path: '/:slug/profile', component: Profile });
  defaultRoutes.push({ name: 'companyProfile', path: '/:slug/change-password', component: ChangePassword });
  
  defaultRoutes.push({ name: 'companyRedirect', path: '/:slug/*', component: Company })
  defaultRoutes.push({ name: 'companyRedirect', path: '/:slug', component: Company })
}
else {
  defaultRoutes.push({ name: 'CustomDomaincompany', path: '/', component: Company });
  defaultRoutes.push({ name: 'companyCustomDomainProduct', path: '/product/:productId/:productSlug?', component: ProductDetail });
  defaultRoutes.push({ name: 'companyCustomDomainProductCategory', path: '/cat/:categoryId/:categorySlug?', component: Company });

  defaultRoutes.push({ name: 'aboutCustomDomain', path: '/about', component: AboutUs });
  defaultRoutes.push({ name: 'allergeneCustomDomain', path: '/allergenen', component: Allergene });
  defaultRoutes.push({ name: 'contactCustomDomain', path: '/contact', component: Contact });
  defaultRoutes.push({ name: 'openingHoursCustomDomain', path: '/hours', component: OpeningHours });
  defaultRoutes.push({ name: 'companyOrderOverviewCustomDomain', path: '/order-overview', component: OrderOverview });
  defaultRoutes.push({ name: 'companyMyAccountCustomDomain', path: '/myaccount', component: MyAccount });
  defaultRoutes.push({ name: 'companyProfileCustomDomain', path: '/profile', component: Profile });
  defaultRoutes.push({ name: 'companyCustomDomainRedirect', path: '*', component: Company })
}

const router = new VueRouter({
  mode: 'history',
  routes: defaultRoutes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
  console.log(to);
  console.log(from);
  try {
    if (to.name == 'home') {

      var locationCheck = false;
      var firstTime = true;
      // @ts-ignore
      // Get current locationupdate
      console.log(navigator.geolocation);
      console.log(localStorage.getItem('location'))

      if (localStorage.getItem('location') !== null && localStorage.getItem('location') === "1") {
        locationCheck = true;
      }

      if (localStorage.getItem('firstTime') !== null && localStorage.getItem('firstTime') === "0") {
        firstTime = false;
      }

      localStorage.setItem('firstTime', "0");


      console.log(locationCheck);
      console.log('home');

      if (locationCheck) {
        console.log('inside locationcheck');
        if (navigator.geolocation) {
          console.log('has geolocation');
          navigator.geolocation.getCurrentPosition(location => {
            //localStorage.setItem('location',"1");

            // @ts-ignore       
            var geocoder = new google.maps.Geocoder;
            // @ts-ignore        
            var myLatlng = new google.maps.LatLng(parseFloat(location.coords.latitude), parseFloat(location.coords.longitude));
            geocoder.geocode({ 'location': myLatlng }, function (results: any, status: any) {
              if (status === 'OK') {
                if (results[0]) {
                  if (firstTime == false) {
                    //redirect to companies
                    console.log(results);
                    var saveObject = processGoogleMapsData(results[0]);
                    console.log('redirect');
                    console.log('save object');
                    console.log(saveObject);
                    // @ts-ignore
                    saveObject.latitude = location.coords.latitude;
                    saveObject.longitude = location.coords.longitude;
                    localStorage.setItem('address', JSON.stringify(saveObject));
                    next({
                      path: '/companies',
                    })
                  }
                  else {
                    console.log("2");
                    next();
                  }
                } else {
                  console.log("1");
                  next();
                  //window.alert('No results found');
                }
              } else {
                console.log("3");
                next();
                //window.alert('Geocoder failed due to: ' + status);
              }
            });
          }, function () {
            next();
          });
        } else {
          // get latest location and redirect if found
          var latestLocation = localStorage.getItem('address');
          if (latestLocation != null) {
            console.log("5");

            next({
              path: '/companies',
            })
          }
          else {
            console.log("4");
            next();
          }
        }
      }
      else {
        // get latest location and redirect if found
        var latestLocation = localStorage.getItem('address');
        if (latestLocation != null) {
          console.log("6");

          next({
            path: '/companies',
          })
        }
        else {
          console.log("7");
          next();
        }
      }

    }
    else {
      console.log(from)
      if (!to.matched.some(record => record.meta.requiresAuth)) {
        console.log("10");

        return next()
      }

      if (localStorage.getItem('user') == null) {
        next({
          path: '/login',
          query: { redirectUrl: to.fullPath, previousUrl: from.fullPath }
        })

      }
      console.log("8");

      next()
    }
  }
  catch (e) {
    next()
  }
});

// language
Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: 'nl',
  fallbackLocale: 'nl',
  messages
});

//config
Vue.use(vueConfig, configs)

Vue.use(VueSweetalert2);
Vue.use(VueWorker);

function processGoogleMapsData(res: any): any {

  var itemRoute = '';
  var itemLocality = '';
  var itemCountry = '';
  var itemPc = '';
  var itemSnumber = '';

  // iterate through address_component array
  // @ts-ignore
  $.each(res.address_components, function (i: number, address_component: any) {
    console.log('address_component:' + i);

    if (address_component.types[0] == "route") {
      console.log(i + ": route:" + address_component.long_name);
      itemRoute = address_component.long_name;
    }

    if (address_component.types[0] == "locality") {
      console.log("town:" + address_component.long_name);
      itemLocality = address_component.long_name;
    }

    if (address_component.types[0] == "country") {
      console.log("country:" + address_component.long_name);
      itemCountry = address_component.long_name;
    }

    if (address_component.types[0] == "postal_code_prefix") {
      console.log("pc:" + address_component.long_name);
      itemPc = address_component.long_name;
    }

    if (address_component.types[0] == "street_number") {
      console.log("street_number:" + address_component.long_name);
      itemSnumber = address_component.long_name;
    }
    //return false; // break the loop   
  });

  var saveObject = {
    // @ts-ignore
    latitude: res.geometry.location.lat,
    // @ts-ignore
    longitude: res.geometry.location.lng,
    locality: itemLocality,
    postal_code: itemPc,
    route: itemRoute
  }
  return saveObject;
}



export const Vm = new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app');
