





































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CompanyRepo, ICompany } from "../libs/CompanyRepository";
import { CustomerRepo } from "../libs/CustomerRepository";
import { ProductRepo } from "../libs/ProductRepository";
import Swal from "sweetalert2";
import { configs } from "../libs/config";
import CompanyFooter from "./shared/CompanyFooter.vue";

// @ts-ignore
import Autocomplete from "@trevoreyre/autocomplete-vue";
import { UtilsRepo, Utils } from "../libs/Utils";

// @ts-ignore
import imagesLoaded from "vue-images-loaded";
import { IArticle } from "../libs/QuickCollectDatabase";
import { Store } from "../libs/Store";
// @ts-ignore
import { directive as onClickaway } from "vue-clickaway";
import DesktopNav from "./shared/DesktopNav.vue";

//@ts-ignore
import VueExpandableImage from "vue-expandable-image";
import { ContentPageRepo, IContentPage } from "@/libs/ContentPageRepository";
Vue.use(VueExpandableImage);
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
// @ts-ignore
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
// @ts-ignore
import VideoEmbed from "../components/shared/VideoEmbed.vue";

export default Vue.extend({
  name: "Company" as string,
  components: {
    DesktopNav,
    VueExpandableImage,
    CompanyFooter,
    VueSlickCarousel,
    VideoEmbed
  },
  directives: {
    imagesLoaded,
    onClickaway: onClickaway,
  },
  data() {
    return {
      contentPage: null as IContentPage | null,
      companyRedirectMode: false,
      showCompanyNewsItem: false,
      authenticated: false,
      noResults: false,
      isMethodsOpen: false,
      basketCount: 0,
      searchCategoryMode: false,
      company: undefined as ICompany | undefined,
      products: [] as IArticle[],
      basketItems: [] as IArticle[],
      store: Store.state,
      sectorImage:
        "https://hips.hearstapps.com/hmg-prod/images/sourdough-bread-horizontal-half-1547669255.png" as string,
      firstUse: false,
      currentCategory: "" as string,
      paging: {
        page: 1,
        itemsPerPage: 10,
        pages: [] as number[],
        total: 0 as number,
      },
      categories: [] as string[] | null,
      searchTerm: "",
      viewMethod: "list",
      loading: false,
      orderOverlay: false,
      orderProduct: undefined as IArticle | undefined,
      imageHost: configs.ImageHost,
    };
  },
  methods: {
    setDisplayMode(mode: any) {
      this.$root.$emit("change-display-mode", mode);
    },
    redirectShoppingCart: function () {
      this.$router.push({ name: "basket" });
    },
    showOpeningshours(company: ICompany, event: any) {
      Utils.showOpeningshours(company, event, this.$swal);
    },
    openingHourList: function () {
      if (this.company !== undefined) {
        return Utils.openingHourList(this.company);
      }
      return "";
    },
    deliveryHourList: function () {
      if (this.company !== undefined) {
        return Utils.deliveryHourList(this.company);
      }
      return "";
    },
    redirectOrder: function () {
      if (this.company !== undefined) {
        this.$router.push({
          name: "preplaceorder",
          params: { id: this.company.companyId },
        });
      }
    },
    getProductCountInBasket: function (product: IArticle) {
      var productIds = product.otherProducts.map((f) => f.productId);

      var productCount = this.basketItems.filter(function (obj) {
        return productIds.indexOf(obj.productId) !== -1;
      });

      if (productCount.length === 0) {
        return 0;
      } else {
        var totalCount = 0;
        for (var i = 0; i < productCount.length; i++) {
          totalCount += productCount[i].amount;
        }
        return totalCount;
      }
    },
    checkLocalFavorite(company: ICompany) {
      console.log("checklocalfav");
      var favoriteCompanies = localStorage.getItem("favoriteCompanies");
      var companyIds = [] as string[];
      if (favoriteCompanies != null) {
        companyIds = favoriteCompanies.split(",");
      }
      var companyIndex = companyIds.indexOf(company.companyId);
      console.log(companyIndex);
      if (companyIndex === -1) {
        return false;
      } else {
        return true;
      }
    },
    toggleFavorite(company: ICompany, event: any) {
      event.preventDefault();

      if (localStorage.getItem("user") == null) {
        var favoriteCompanies = localStorage.getItem("favoriteCompanies");
        var companyIds = [] as string[];
        if (favoriteCompanies != null) {
          companyIds = favoriteCompanies
            .split(",")
            .filter((f) => f != "" && f != undefined);
        }
        var companyIndex = companyIds.indexOf(company.companyId);
        console.log(companyIds);
        if (companyIndex === -1) {
          companyIds.push(company.companyId);
        } else {
          companyIds.splice(companyIndex, 1);
        }
        console.log(companyIndex);
        console.log("toggle fav");
        console.log(companyIds);
        localStorage.setItem("favoriteCompanies", companyIds.join(","));

        if (company.favorite == null) {
          company.favorite = true;
        } else {
          company.favorite = !company.favorite;
        }
      } else {
        if (company.favorite == null) {
          company.favorite = true;
        } else {
          company.favorite = !company.favorite;
        }
        var self = this;
        var res = CompanyRepo.updateFavoriteStatus(
          company.companyId,
          self.store.loggedInUser.userId,
          company.favorite
        );
      }

      console.log(company);
    },
    onClickOutside: function (event: any, element: any) {
      console.log("handle focus out");
      this.orderOverlay = false;
    },
    imageError(imageId: string) {
      var el = document.getElementById(imageId);

      el?.setAttribute("src", "/images/product-placeholder.png");
    },
    loaded() {
      console.log("loaded");
      // @ts-ignore
      $(".products .products__list .product-item .card__name").matchHeight();
      // @ts-ignore
      $(".products .products__list .product-item figure").matchHeight();
    },
    openCompanyInfo: function () {
      var htmlString =
        "<h3>" +
        this.company?.companyName +
        "</h3>" +
        '<div class="merchant-info"><ul><li>' +
        this.$t(this.company?.sector ?? "") +
        "</li>";
      if (this.company?.timeToPrepareDelivery) {
        htmlString +=
          "<li>Gem. verwerkingstijd: " +
          this.company?.timeToPrepareDelivery +
          "min</li>";
      }
      htmlString +=
        '<li><i class="fas fa-map-marker-alt"></i> ' +
        this.company?.street +
        " " +
        this.company?.number +
        "<br />" +
        this.company?.postalcode +
        " " +
        this.company?.city +
        '</li><li><i class="fas fa-mobile-alt"></i> <a href="tel:' +
        this.company?.phone +
        '">' +
        this.company?.phone +
        "</a></li><li>" +
        this.$t("vat") +
        " " +
        this.company?.vatNumber +
        "</li>" +
        '<li><ul class="paymethods">';

      if (this.company?.paymentMethods) {
        if (this.company.paymentMethods.indexOf("CASH") !== -1) {
          htmlString +=
            '<li class="available"><img src="/images/euro.svg"alt="Cash" /></li>';
        }
        if (this.company.paymentMethods.indexOf("BANKCONTACT") !== -1) {
          htmlString +=
            '<li class="available"><img src="/images/debetkaart.svg" alt="Debietkaart" /></li>';
        }
        if (this.company.paymentMethods.indexOf("VISA") !== -1) {
          htmlString +=
            '<li class="available"> <img src="/images/kredietkaart.svg" alt="Kredietkaart" /></li>';
        }
        if (this.company.paymentMethods.indexOf("PAYCONIQ") !== -1) {
          htmlString +=
            '<li class="available"><img src="/images/payconiq.svg" alt="Payconic" /></li>';
        }
        if (this.company.paymentMethods.indexOf("E-CHEQUES") !== -1) {
          htmlString +=
            '<li class="available"><img src="/images/e-cheques.svg" alt="e-cheques" /></li>';
        }
        if (this.company.paymentMethods.indexOf("N-CHEQUES") !== -1) {
          htmlString +=
            '<li class="available"><img src="/images/cheques.svg"alt="Cheques" /></li>';
        }
      }

      htmlString +=
        "</ul></li></ul></div>" +
        '<div class="qc-logo">Powered by <a href="https://www.quickcollect.be/" target="_blanc"><img src="/images/QC-logo-color.svg" /></a></div>';

      this.$swal({
        title: "",
        html: htmlString,
        showConfirmButton: false,
        showCloseButton: true,
        customClass: {
          container: "merchant-info-container",
        },
      }).then((value: any) => {
        console.log(value);
        // if(value.dismiss && value.dismiss === Swal.DismissReason.close)
        //   self.$router.push({name: "basket"});
      });
    },
    lineBreakToHtml(text: string) {
      if (!text) return "";
      return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
    },
  },
  async created() {
    console.log("MOUNTED");
    // @ts-ignore
    if (this.$session.get("companyRedirect") !== undefined) {
      this.companyRedirectMode = true;
    }

    // @ts-ignore
    if (this.$session.get("companyDomain") !== undefined) {
      this.companyRedirectMode = true;
    }

    if (localStorage.getItem("user") != null) {
      this.authenticated = true;
    }

    this.showCompanyNewsItem = true;

    this.company = await CompanyRepo.getCompanyByRoute(this.$route)

    if (localStorage.getItem("user") == null && this.company !== undefined) {
      this.company.favorite = this.checkLocalFavorite(this.company);
    }

    var self = this;

    // this.$root.$on('close-overlay', function () {
    //   self.orderOverlay = false;
    // })
    this.$root.$on("close-blob-overlay", function () {
      self.isMethodsOpen = false;
    });
    if (localStorage.getItem("firstUse") === null) {
      self.firstUse = true;
      localStorage.setItem("firstUse", "1");
    }

    if (this.company)
      this.contentPage = await ContentPageRepo.getContentPage(
        this.company.companyId,
        "Allergene"
      );

    //rebuilding less
    this.store.currentCompany = this.company;
    console.log(this.store);
    if (this.store.currentCompany !== undefined) {
      //add custom variables
      if (
        this.store.currentCompany.primaryColor !== undefined &&
        this.store.currentCompany.primaryColor != null
      ) {
        try {
          setTimeout(
            () =>
              // @ts-ignore
              less.modifyVars({
                "primary-color": this.store.currentCompany.primaryColor,
                "secondary-color": this.store.currentCompany.secondaryColor,
                "highlight-color": this.store.currentCompany.buttonColor,
              }),
            500
          );
        } catch (e) {}
      }
    }
  },
  watch: {},
  computed: {},
});
