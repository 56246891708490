



















































































import { Component, Prop, Vue } from "vue-property-decorator";

export default Vue.extend({
  name: "Help" as string,
  methods: {
    setDisplayMode(mode: any) {
      this.$root.$emit("change-display-mode", mode);
    },
  },
});
