



























































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CompanyRepo, ICompany } from "../libs/CompanyRepository";
import { configs } from "../libs/config";
import { ProductRepo, BasketOverview, IOrder } from "../libs/ProductRepository";
import PageHeader from "../components/shared/PageHeader.vue";

// @ts-ignore
import Autocomplete from "@trevoreyre/autocomplete-vue";

// @ts-ignore
import imagesLoaded from "vue-images-loaded";
import { Store } from "../libs/Store";
import { IArticle } from "../libs/QuickCollectDatabase";
import { CustomerRepo } from "../libs/CustomerRepository";
import DesktopNav from "../components/shared/DesktopNav.vue";
export default Vue.extend({
  name: "OrderOverview" as string,
  components: {
    DesktopNav,
    PageHeader,
  },
  directives: {
    imagesLoaded,
  },
  data() {
    return {
      authenticated: false,
      noResults: true,
      noResultsClosed: true,
      store: Store.state,
      currentMethod: "open",
      address: "",
      orders: [] as any[],
      closedOrders: [] as any[],
      paging: {
        page: 1,
        itemsPerPage: 9,
        pages: [] as number[],
        total: 0 as number,
      },
      hiddenOrdersArr: [] as number[],
      loading: false,
      orderOverlay: false,
      orderProduct: undefined as IArticle | undefined,
      imageHost: configs.ImageHost,
      company: undefined as ICompany | undefined,
    };
  },
  methods: {
    setMethod: function (method: string) {
      this.currentMethod = method;
    },
    setDisplayMode(mode: any) {
      this.$root.$emit("change-display-mode", mode);
    },
    hideOrder: function (orderId: number) {
      console.log("hide order");
      var indexOrder = this.hiddenOrdersArr.indexOf(orderId);
      console.log(indexOrder);
      if (indexOrder === -1) {
        this.hiddenOrdersArr.push(orderId);
      }
      console.log(this.hiddenOrdersArr);
      localStorage.setItem("order-hidden", this.hiddenOrdersArr.join(","));
      console.log(this.hiddenOrdersArr);
    },
    showOrder: async function (orderId: number) {
      var indexOrder = this.hiddenOrdersArr.indexOf(orderId);
      console.log(indexOrder);
      if (indexOrder !== -1) {
        this.hiddenOrdersArr.splice(indexOrder, 1);
      }
      console.log(this.hiddenOrdersArr);
      localStorage.setItem("order-hidden", this.hiddenOrdersArr.join(","));
    },
    isVisibleOrder: function (orderId: number) {
      var indexOrder = this.hiddenOrdersArr.indexOf(orderId);
      console.log(orderId);
      console.log(indexOrder);
      if (indexOrder === -1) {
        console.log(true);
        return true;
      } else {
        console.log(false);
        return false;
      }
    },
    getData: async function () {
      this.loading = true;
      var self = this;
      var allOrders = await CustomerRepo.getOpenOrders(this.company?.companyId);
      this.hiddenOrdersArr = allOrders.map((o: any) => o.orderId);
      console.log(allOrders);
      this.orders = allOrders
        .filter(function (order) {
          return (order.completed ? false : true) && order.cancelled == false;
        })
        .sort((o1: IOrder, o2: IOrder) =>
          new Date(o1.created) < new Date(o2.created) ? 1 : -1
        );

      this.closedOrders = allOrders
        .filter(function (order) {
          return (order.completed ? true : false) || order.cancelled == true;
        })
        .sort((o1: IOrder, o2: IOrder) =>
          new Date(o1.created) < new Date(o2.created) ? 1 : -1
        );

      if (this.orders.length !== 0) {
        this.noResults = false;
      } else {
        this.noResults = true;
      }

      if (this.closedOrders.length !== 0) {
        this.noResultsClosed = false;
      } else {
        this.noResultsClosed = true;
      }

      this.loading = false;
    },
    editOrder(order: any) {
      this.$router.push({
        name: "editorder",
        params: { id: order.companyId, orderid: order.orderId },
      });
      this.orderOverlay = false;
    },
    async reorder(order: any) {
      var currentCompany = await CompanyRepo.getCompanyById(order.companyId);
      if (currentCompany === undefined) {
        this.$swal({
          title: "",
          icon: "warning",
          text: "Dit bedrijf is momenteel niet beschikbaar voor bestellingen",
          timerProgressBar: true,
          showConfirmButton: true,
          animation: false,
        });
        return;
      }

      var productIdList = order.orderItems.map(function (orderItem: any) {
        return orderItem.productId;
      });

      var addedProduct = false;
      var allProducts = await ProductRepo.getProductsByIds(productIdList);
      if (allProducts != null) {
        for (var i = 0; i < order.orderItems.length; i++) {
          var currentOrderItem = order.orderItems[i];

          var foundProduct = allProducts.find(function (item) {
            return (item.productId == currentOrderItem.productId);
          });

          if (foundProduct !== undefined) {
            if (
              foundProduct.unitType === currentOrderItem.unitType &&
              foundProduct.deleted != true
            ) {
              foundProduct.companyId = order.companyId;
              foundProduct.amount = currentOrderItem.quantity;
              console.log(foundProduct);
              addedProduct = true;
              await ProductRepo.addToBasketKeepingExistingAmount(foundProduct);
            }
          }
        }
      }

      if (addedProduct) {
        console.log("companyredirect");
        // @ts-ignore
        if (this.$session.get("companyDomain") !== undefined) {
          // @ts-ignore
          this.$session.set("reorder", "1");
          // @ts-ignore
          this.$router.push({
            path: "/",
            params: { openShoppingCart: "1" },
          });
        }
        // @ts-ignore
        else if (this.$session.get("companyRedirect") !== undefined) {
          if (currentCompany.companySlug != null) {
            // @ts-ignore
            this.$session.set("reorder", "1");
            // @ts-ignore
            this.$router.push({
              path: currentCompany.companySlug,
              params: { openShoppingCart: "1" },
            });
          }
        } else {
          if (currentCompany.companySlug != null) {
            this.$router.push({
              name: "company",
              params: { id: currentCompany.companySlug, openShoppingCart: "1" },
            });
          }
        }
      } else {
        this.$swal({
          title: "",
          icon: "warning",
          text: "Deze producten zijn niet meer beschikbaar voor bestelling",
          timerProgressBar: true,
          showConfirmButton: true,
          animation: false,
        });
      }
    },
    imageError(imageId: string) {
      var el = document.getElementById(imageId);

      el?.setAttribute("src", "/images/product-placeholder.png");
    },
    loaded() {
      console.log("loaded");
      // @ts-ignore
      $(".products .products__list .product-item .card__name").matchHeight();
      // @ts-ignore
      $(".products .products__list .product-item figure").matchHeight();
    },
  },
  async mounted() {
    var self = this;
    if (localStorage.getItem("user") != null) {
      this.authenticated = true;
      var hiddenOrdersStr = localStorage.getItem("order-hidden");

      if (hiddenOrdersStr != null) {
        this.hiddenOrdersArr = hiddenOrdersStr.split(",").map(Number);
      }
      console.log("start");
      console.log(this.hiddenOrdersArr);
      this.company = await CompanyRepo.getCompanyByRoute(this.$route);

      // @ts-ignore
      await this.getData();
    }
  },
  watch: {
    currentPage: function () {
      //deze 'watch' traceert page changes en kan de data anders ophalen
      this.getData();
    },
  },
  computed: {
    maxPage: function maxPage(): number {
      //computed om max page te bepalen, wordt gebruikt in paging html
      if (this.paging.pages.length == 0) return 0;
      return this.paging.pages.reduce((a, b) => (a > b ? a : b));
    },
    currentPage: function currentPage(): number {
      //deze computed value wordt gebruikt om page changes te traceren, zie watch
      return this.paging.page;
    },
  },
});
