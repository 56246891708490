






































































































































import Vue from "vue";
import ClickCollectApi from "../libs/ClickCollectApi";
import { ProductRepo } from "../libs/ProductRepository";
import { Store } from "../libs/Store";
// @ts-ignore
import Password from "vue-password-strength-meter";
import DesktopNav from "../components/shared/DesktopNav.vue";
import PageHeader from "../components/shared/PageHeader.vue";
import { CompanyRepo, ICompany } from "@/libs/CompanyRepository";
export default Vue.extend({
  name: "Login" as string,
  components: {
    Password,
    DesktopNav,
    PageHeader,
  },
  data() {
    return {
      oldPassword: "" as string,
      newPassword: "" as string,
      confirmPassword: "" as string,
      score: 0 as number,
      errors: [] as string[],
      formError: [] as string[],
      store: Store.state,
      loading: false,
      success: false,
      showMobileMenu: false,
      authenticated: false,
      company: undefined as ICompany | undefined,
    };
  },
  methods: {
    updateScore(score: any) {
      this.score = score;
    },
    setDisplayMode(mode: any) {
      this.$root.$emit("change-display-mode", mode);
    },
    async editInfo() {
      this.loading = true;
      this.errors = [];

      if (this.oldPassword == "") {
        this.errors.push("old-password");
      }

      if (this.newPassword == "") {
        this.errors.push("new-password");
      } else if (this.score < 2) {
        this.errors.push("weak-password");
      }

      if (this.confirmPassword == "") {
        this.errors.push("password-confirm");
      } else if (this.confirmPassword !== this.newPassword) {
        this.errors.push("password-not-equal");
      }

      if (this.errors.length > 0) {
        this.loading = false;
        return;
      }

      try {
        var res = await ClickCollectApi.postData("User/ChangePassword", {
          OldPassword: this.oldPassword,
          NewPassword: this.newPassword,
          PhoneNumber: Store.state.loggedInUser.phoneNumber,
        });

        this.success = true;
        this.loading = false;
      } catch (error) {
        console.log(error);
        var errRes = error.response;
        if (errRes && errRes.status !== 200) {
          if ("" in errRes.data) {
            this.formError = errRes.data[""];
          }
        } else {
          this.formError = [this.$t("noConnection") as string];
        }
        this.loading = false;
      }
    },
    showSettingsMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
  },
  mounted: async function () {
    console.log(Store);
    if (localStorage.getItem("user") != null) {
      this.authenticated = true;
    }

    this.company = await CompanyRepo.getCompanyByRoute(this.$route);
  },
  computed: {
    basePath: function (): string {
      var segments = this.$route.path.split("/");
      console.log(segments);
      return segments.length > 2 ? "/" + segments[1] : "";
    },
  },
});
