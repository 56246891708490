var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.company)?_c('div',{staticClass:"company-footer",attrs:{"id":"product-page"}},[_c('desktop-nav',{attrs:{"company":_vm.company}}),_c('div',{staticClass:"app-content blobnav-overlay"},[_c('section',{staticClass:"products-container"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"sixteen"},[_c('div',{staticClass:"text-left-image-right"},[_c('div',{staticClass:"text"},[_c('div',{staticClass:"merchant-info practical"},[(_vm.branches.length > 1)?_c('ul',{staticClass:"filialen-tabs"},_vm._l((_vm.branches),function(branch){return _c('li',{key:branch.companyId},[_c('button',{class:{ active: branch.companyId == _vm.selectedBranchId },on:{"click":function($event){_vm.selectedBranchId = branch.companyId}}},[_vm._v(_vm._s(branch.branchName))])])}),0):_vm._e(),_vm._l((_vm.branches),function(branch){return _c('div',{key:branch.companyId,staticClass:"openingsuren-container"},[(branch && branch.companyId == _vm.selectedBranchId)?_c('div',[(branch.openinghours && branch.openinghours.length > 0)?_c('div',{staticClass:"swal2-content",staticStyle:{"text-align":"center"}},[_c('h5',[_vm._v(_vm._s(_vm.$t("OpeningHours")))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.utils.openingHourList(branch))}}),(branch.enableTakeAway)?_c('div',[_c('h5',[_vm._v(_vm._s(_vm.$t("TakeAwayHours")))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.utils.takeAwayHourList(branch))}}),(branch.takeAwayHoursText)?_c('small',{domProps:{"innerHTML":_vm._s(
                            _vm.lineBreakToHtml(branch.takeAwayHoursText)
                          )}}):_vm._e()]):_vm._e()]):_vm._e(),_c('div',{staticClass:"swal2-content",staticStyle:{"text-align":"center"}},[(branch.enableDelivery)?_c('div',[_c('h5',[_vm._v(_vm._s(_vm.$t("DeliveryHours")))]),_c('div',{attrs:{"html":_vm.utils.deliveryHourList(branch)}}),(branch.deliveryHoursText)?_c('small',{domProps:{"innerHTML":_vm._s(_vm.lineBreakToHtml(branch.deliveryHoursText))}}):_vm._e()]):_vm._e()]),(branch.paymentMethods)?_c('div',{staticClass:"paymethods"},[_c('ul',[(branch.paymentMethods.indexOf('CASH') !== -1)?_c('li',{staticClass:"available"},[_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Cash'),expression:"'Cash'"}],attrs:{"src":"/images/euro.svg","alt":"Cash"}})]):_vm._e(),(
                            branch.paymentMethods.indexOf('BANKCONTACT') !==
                            -1
                          )?_c('li',{staticClass:"available"},[_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Bankcontact'),expression:"'Bankcontact'"}],attrs:{"src":"/images/debetkaart.svg","alt":"Debietkaart"}})]):_vm._e(),(branch.paymentMethods.indexOf('VISA') !== -1)?_c('li',{staticClass:"available"},[_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Kredietkaart'),expression:"'Kredietkaart'"}],attrs:{"src":"/images/kredietkaart.svg","alt":"Kredietkaart"}})]):_vm._e(),(
                            branch.paymentMethods.indexOf('PAYCONIQ') !== -1
                          )?_c('li',{staticClass:"available"},[_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Payconiq'),expression:"'Payconiq'"}],attrs:{"src":"/images/payconiq.svg","alt":"Payconic"}})]):_vm._e(),(
                            branch.paymentMethods.indexOf('E-CHEQUES') !== -1
                          )?_c('li',{staticClass:"available"},[_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('E-cheques'),expression:"'E-cheques'"}],attrs:{"src":"/images/e-cheques.svg","alt":"e-cheques"}})]):_vm._e(),(
                            branch.paymentMethods.indexOf('N-CHEQUES') !== -1
                          )?_c('li',{staticClass:"available"},[_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Cheques'),expression:"'Cheques'"}],attrs:{"src":"/images/cheques.svg","alt":"Cheques"}})]):_vm._e(),(branch.onlinePaymentEnabled)?_c('li',[_vm._v(" "+_vm._s(_vm.$t("OrOnlinePayment"))+" ")]):_vm._e()])]):_vm._e()]):_vm._e()])}),_c('div',{staticClass:"clear"})],2)])])])])])]),_c('div',{staticClass:"desktop-qc-block desktop-footer-container"},[_c('company-footer',{attrs:{"company":_vm.company}}),_c('section',{staticClass:"desktop-footer desktop-qc-block"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"sixteen"},[_c('div',{staticClass:"text-right"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.setDisplayMode(6)}}},[_vm._v(_vm._s(_vm.$t("GeneralConditions")))])]),_c('li',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.setDisplayMode(5)}}},[_vm._v(_vm._s(_vm.$t("PrivacyPolicy")))])]),_c('li',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.setDisplayMode(4)}}},[_vm._v(_vm._s(_vm.$t("CookiePolicy")))])]),_vm._m(0)])])])])])],1)],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_vm._v(" by "),_c('a',{attrs:{"href":"https://www.quickcollect.be/een-handige-online-besteldienst-voor-handelaars/","target":"_blank"}},[_vm._v("Quickcollect")])])}]

export { render, staticRenderFns }