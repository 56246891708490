















import { Store } from "@/libs/Store";
import Vue from "vue";
import Api from "@/libs/ClickCollectApi";
import axios from "axios";
import { configs } from '@/libs/config';
import { UtilsRepo, Utils } from "@/libs/Utils";

export default Vue.extend({
  data() {
    return {
      canInstall: false,
      deferredPrompt: null as any,
      installIos: false,
      installFirefox : false
    };
  },
  methods: {
    installPwa: function() {
      // Show the prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt

      this.deferredPrompt.userChoice
        // @ts-ignore
        .then(choiceResult => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the A2HS prompt");
          } else {
            console.log("User dismissed the A2HS prompt");
          }
          this.canInstall = false;

          this.deferredPrompt = null;
        });
    },
    hideInstall: function() {
      localStorage.setItem("installDismissed", new Date().toJSON());
    },
    isFirefox: function() {
      return (
        navigator.userAgent.toLowerCase().indexOf("firefox") > -1 &&
        navigator.platform.toLowerCase().indexOf("android") > -1
      );
    },
    isInStandaloneMode: function(): boolean {
      // @ts-ignore
      return "standalone" in window.navigator && window.navigator.standalone;
    }
  },
  created() {
    var self = this;
    console.log('install banner created');
    // check whether the message has been shown before too early
    var inCooloffPeriod =
      localStorage.getItem("installDismissed") != null &&
      new Date().getTime() -
        new Date(localStorage.getItem("installDismissed") ?? "").getTime() <
        1000 * 60 * 60 * 24 * 10;

    if (inCooloffPeriod) return;
    // ios device
    if (Utils.isIos() && !this.isInStandaloneMode()) {
      this.installIos = true;
      return;
    }else if(this.isFirefox() && !this.isInStandaloneMode()){
      this.installFirefox = true;
      return;
    }

    window.addEventListener("beforeinstallprompt", function(e) {
      console.log('beforeinstallprompt');
      console.log(e);
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.

      self.deferredPrompt = e;

      // @ts-ignore
      console.log(self.deferredPrompt);
      if (self.deferredPrompt) self.canInstall = true;
    });
  }
});
