






















































































































































































































































































import { configs } from "@/libs/config";
import { ContentPageRepo, IContentPage } from "@/libs/ContentPageRepository";
import { ProductRepo } from "@/libs/ProductRepository";
import Vue from "vue";
import { Store } from "../../libs/Store";

export default Vue.extend({
  props: ["company", "title", "nbProductsInBasket"],
  data() {
    return {
      store: Store.state,
      contentPages: [] as IContentPage[],
      baseRoute: "",
      showMobileMenu: false,
      basketCount: 0,
      imageHost: configs.ImageHost,
      showLangDropdown: false,
    };
  },
  methods: {
    redirectStart() {
      console.log("redirect start");
      // @ts-ignore
      console.log(this.$session.get("companyRedirect"));
      var mainSite =
        window.location.host.indexOf("quickcollect.shop") !== -1 ||
        window.location.host.indexOf("quickcollectcustomer.debugged.be") !==
          -1 ||
        window.location.host.indexOf("localhost") !== -1;

      // @ts-ignore
      if (
        !mainSite ||
        this.$route.path == "/" ||
        this.$route.name?.includes("CustomDomain")
      ) {
        // @ts-ignore
        this.$router.push("/?k=" + new Date().valueOf());
      } else if (
        // @ts-ignore
        !this.$session.get("fromCompanyOverview") &&
        this.$route.params.slug
      ) {
        // @ts-ignore
        var companySlug = encodeURIComponent(this.$route.params.slug);
        this.$router.push("/" + companySlug + "?k=" + new Date().valueOf());
      } else {
        this.$router.push({
          name: "home",
          query: { k: new Date().valueOf() + "" },
        });
      }

      //this.$emit("homeRedirect");
    },
    redirectBasket() {
      console.log("redirect basket");
      console.log(this.$route);
      // @ts-ignore
      console.log(this.$session.get("companyRedirect"));

      if (this.$route.name == "companyRedirect") {
        this.baseRoute =
          "/" + encodeURIComponent(this.$route.params.slug) + "/";
      } else if (
        this.$route.path == "/" ||
        this.$route.name?.includes("CustomDomain")
      ) {
        this.baseRoute = "/";
      } else if (this.$route.params.slug) {
        // @ts-ignore
        this.baseRoute =
          "/" + encodeURIComponent(this.$route.params.slug) + "/";
      } else {
        this.baseRoute = "/";
      }

      //this.$emit("homeRedirect");
    },
    showHamburgerMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
    switchLanguage(lang: string) {
      lang = lang.toLowerCase();
      this.store.culture = lang;
      this.$i18n.locale = lang;
      localStorage.setItem("culture", lang);

      // fix non vue hamburger menu
      setTimeout(function () {
        // @ts-ignore
        window.filterWindow();
      }, 200);
      this.$route.query.k = new Date().valueOf() + "";
    },
    updateBasketCount: async function () {
      var res = await ProductRepo.getProductsInBasket();
      var self = this;
      console.log(res);

      //only show product from current company
      var basketItems = res.filter(function (obj) {
        return (
          obj.companyId ===
          (self.company !== undefined ? self.company.companyId : "")
        );
      });

      //paging

      if (!basketItems) {
        this.basketCount = 0;
        return;
      } else if (basketItems.length == 0) {
        this.basketCount = 0;
      } else {
        var count = 0;
        for (var i = 0; i < basketItems.length; i++) {
          if (ProductRepo.IsSingularUnitType(basketItems[i].unitType)) {
            count += basketItems[i].amount;
          } else {
            count += 1;
          }
        }
        this.basketCount = count;
      }
    },
    goToBasket: function () {
      if (this.$listeners && this.$listeners.basketRedirect)
        this.$emit("basketRedirect");
      else this.$router.push(this.baseRoute + "#basket");
    },
    setDisplayMode(mode: any) {
      console.log("set display mode");
      this.$root.$emit("change-display-mode", mode);
    },
  },
  async created() {
    if (this.company) {
      this.contentPages = await ContentPageRepo.getContentPagesByCompanyId(
        this.company.companyId
      );
    }

    if (this.$route.params.slug) {
      this.baseRoute = "/" + encodeURIComponent(this.$route.params.slug) + "/";
    } else {
      this.baseRoute = "/";
    }
    this.updateBasketCount();
  },
  watch: {
    company: async function () {
      console.log("company", this.company);
      if (this.company) {
        this.contentPages = await ContentPageRepo.getContentPagesByCompanyId(
          this.company.companyId
        );
      }
      this.updateBasketCount();
    },
  },
});
