







































import { ProductRepo } from "@/libs/ProductRepository";
import Vue from "vue";
import { configs } from "../../libs/config";
import { IArticle } from "@/libs/QuickCollectDatabase";

// @ts-ignore
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default Vue.extend({
  props: {
    companyId: String,
  },
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      slickSettings: {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              infinite: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      featuredProducts: [] as any[],
      imageHost: configs.ImageHost,
    };
  },
  methods: {
    displayPrice: function (product: IArticle) {
      var priceDifference = false;
      var minPrice = product.pricePerUnit;
      var maxPrice = product.pricePerUnit;

      console.log("display price");
      console.log(product);
      var originalPrice = product.originalPrice;
      if (product.otherProducts && product.otherProducts.length > 1) {
        var productWithDifferentPrice = product.otherProducts.filter(
          (f) => f.pricePerUnit !== product.pricePerUnit
        );
        if (productWithDifferentPrice !== undefined) {
          priceDifference = true;
          for (var i = 0; i < product.otherProducts.length; i++) {
            var currPrice = product.otherProducts[i].pricePerUnit;
            if (
              currPrice !== undefined &&
              minPrice !== undefined &&
              maxPrice !== undefined
            ) {
              if (currPrice > maxPrice) {
                maxPrice = product.otherProducts[i].pricePerUnit;
              }

              if (currPrice < minPrice) {
                minPrice = product.otherProducts[i].pricePerUnit;
              }

              // take the most expensive original price
              if (product?.otherProducts[i].originalPrice)
                if (
                  !originalPrice ||
                  (product?.otherProducts[i]?.originalPrice ?? -1) >
                    originalPrice
                )
                  //@ts-ignore
                  originalPrice = product?.otherProducts[i]?.originalPrice;
            }
          }

          if (minPrice === maxPrice) priceDifference = false;
        }
      }

      var htmlString =
        "<strong " +
        (product.originalPrice ? "class='discounted-price'" : "") +
        ">";
      if (priceDifference) {
        htmlString +=
          "€" +
          (minPrice ?? 0).toFixed(2).replace(".", ",") +
          " Tot € " +
          (maxPrice ?? 0).toFixed(2).replace(".", ",") +
          "<span>";
      } else {
        htmlString +=
          "€" +
          (product?.pricePerUnit ?? 0).toFixed(2).replace(".", ",") +
          "<span>";
      }
      htmlString += "/" + this.$t(product.unitType + "_base");

      htmlString += "</span></strong>";
      if (product.originalPrice) {
        htmlString +=
          "<span class='original-price'>€" +
          (product?.originalPrice ?? 0).toFixed(2).replace(".", ",") +
          "<span>";
        htmlString += "/" + this.$t(product.unitType + "_base");
        htmlString += "</span></span>";
      }

      return htmlString;
    },
    openProduct(product: any) {
      this.$emit("open-product", product);
    },
  },
  async mounted() {
    this.featuredProducts = await ProductRepo.getFeaturedProducts(
      this.companyId
    );
  },
});
