



import { Store } from "@/libs/Store";
import Vue from "vue";
import Api from "@/libs/ClickCollectApi";
import axios from "axios";
import { configs } from "@/libs/config";

export default Vue.extend({
  data() {
    return {
      notificationsSupported: false,
      notificationsEnabled: false,
      buttonDisabled: false,
      serviceWorkerRegistation: null as ServiceWorkerRegistration | null,
      subscription: undefined as PushSubscription | undefined
    };
  },
  methods: {
    toggleSubscription: async function(): Promise<void> {
      if (this.notificationsSupported) {
        this.buttonDisabled = true;
        // Find out if we need to create a subscription or delete it
        if (!this.notificationsEnabled) {
          // Ask permission and when granted, create new subscription
          var result = await Notification.requestPermission();
          // if granted, create new subscription
          if (result === "granted") {
            this.subscription = await this.createSubscription();
            console.log(
              "subscription created on the client",
              this.subscription
            );

            if (this.subscription == undefined) return;

            // store new subscription on the server
            await Api.postData("push/subscribe", this.subscription);

            this.showNotification();
            this.buttonDisabled = false;
            this.notificationsEnabled = true;
          } else {
            console.log("User did not granted permission");
          }
        } else {
          // Destroy subscription
          console.log("Disable subscription");
          if (this.subscription !== undefined) {
            // destroy on the server

            await Api.postData(
              "push/delete" ,{ endpoint : this.subscription.endpoint }
            );
            // unsubscribe on the client
            this.subscription.unsubscribe();

            // update the data
            this.notificationsEnabled = false;
            this.buttonDisabled = false;
            this.subscription = undefined;
          }
        }
      }
    },
    async createSubscription() {
      console.log("ask for active service worker registration");
      console.log(this.serviceWorkerRegistation);
      if (this.serviceWorkerRegistation === null) {
        this.serviceWorkerRegistation = await navigator.serviceWorker.ready;
        console.log(this.serviceWorkerRegistation);
        // returns a Promise, the active SW registration
        return await this.subscribe(this.serviceWorkerRegistation);
      } else {
        return await this.subscribe(this.serviceWorkerRegistation);
      }
    },
    async getSubscription(swreg: ServiceWorkerRegistration) {
      console.log("ask for available subscription");
      return await swreg.pushManager.getSubscription();
    },
    async subscribe(swreg: ServiceWorkerRegistration) {
      console.log("create new subscription for this browser on this device");
      // create new subscription for this browser on this device
      const vapidPublicKey = configs.vapidPublicKey;
      if (!vapidPublicKey) return;

      const convertedVapidPublicKey = this.urlBase64ToUint8Array(
        vapidPublicKey
      );
      // return the subscription promise
      return await swreg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidPublicKey
      });
    },
    showNotification() {
      this.serviceWorkerRegistation?.showNotification(
        this.$t("notificationsGranted").toString(),
        {
          body: this.$t("notificationsGrantedBody").toString(),
          icon: "/img/icons/android-chrome-192x192.png",
          vibrate: [300, 200, 300]
        }
      );
    },
    async findSubscription() {
      console.log("get active service worker registration");

      console.log("get active subscription");
      console.log(navigator.serviceWorker.ready);
      console.log(this.serviceWorkerRegistation);
      this.serviceWorkerRegistation = await navigator.serviceWorker.ready;
      return await this.getSubscription(this.serviceWorkerRegistation);
    },
    urlBase64ToUint8Array(base64String: string) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");
      const rawData = window.atob(base64);
      let outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    }    
  },
  created() {
    if ("Notification" in window && "serviceWorker" in navigator) {
      this.notificationsSupported = true;
    }
  },
  async mounted() {
    var self = this;
          
    // Find out if the user has a subscription at the moment.
    // If so, update the enabled flag in data
    var sub = await this.findSubscription();
    console.log('current subscription');
    console.log(sub);
    console.log(
      new Date().getTime() -
        new Date(localStorage.getItem("notificationLastAsked") ?? "").getTime()
    );

    // check whether the message has been shown before too early
    var inCooloffPeriod =
      localStorage.getItem("notificationLastAsked") != null &&
      new Date().getTime() -
        new Date(
          localStorage.getItem("notificationLastAsked") ?? ""
        ).getTime() <
        1000 * 60 * 60 * 24 * 2;

    if (
      !inCooloffPeriod &&
      this.notificationsSupported &&
      sub === null &&
      Notification.permission != "denied"
    ) {
      this.$swal({
        position: "top-left",
        toast: true,
        width: "25rem",
        text: self.$t("activateNotificationsBody").toString(),
        showConfirmButton: true,
        confirmButtonText: self.$t("yes").toString(),
        showCancelButton: true,
        
        cancelButtonText: self.$t("notNow").toString(),
        customClass: {
          container: "enable-notification push-popup"
        }
      }).then(result => {
        if (result.value) {
          this.toggleSubscription();
        } else {
          localStorage.setItem("notificationLastAsked", new Date().toJSON());
        }
      });
    
      console.log("no active subscription found on the client", sub);
      this.buttonDisabled = false;
      this.notificationsEnabled = false;

    } else {
      console.log("Active subscription found", sub);

      this.buttonDisabled = false;
      this.notificationsEnabled = true;
      this.subscription = sub ?? undefined;
    }
  }
});
