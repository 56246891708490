














import { Component, Prop, Vue } from "vue-property-decorator";

// @ts-ignore
import Autocomplete from "@trevoreyre/autocomplete-vue";


export default Vue.extend({
  name: "LocationDenied" as string
});
