





















import Vue from "vue";
import { CompanyRepo, ICompany } from "../../libs/CompanyRepository";
import { configs } from "../../libs/config";

export default Vue.extend({
  props : {
    title : String
  },
  data() {
    return {
        companyRedirectMode : false,
        company : undefined as ICompany | undefined,
        imageHost: configs.ImageHost,
    };
  },
  methods: {
   
  },
  async created() {
    // @ts-ignore
    if(this.$session.get('companyRedirect') !== undefined){
        this.companyRedirectMode  = true;
        // @ts-ignore
        this.company = await CompanyRepo.getCompanyBySlug(this.$session.get('companyRedirect'));
    }   
    // @ts-ignore
    else if(this.$session.get('companyDomain') !== undefined){
        this.companyRedirectMode  = true;
        // @ts-ignore
        this.company = await CompanyRepo.getCompanyByDomain(window.location.hostname);
    }  
    console.log(this.company);
    console.log(this.companyRedirectMode);
  }
});
