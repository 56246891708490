


































































































import { Store } from "@/libs/Store";
import Vue from "vue";


export default Vue.extend({
  name: "modal" as string,
  components: {
    
  },
  data() {
    return {
      
    };
  },    
  methods: {
   close() {
      this.$emit('close');
    },
  }
});
