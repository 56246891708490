


































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";

import { CompanyRepo, ICompany } from "./libs/CompanyRepository";
import { ProductRepo } from "./libs/ProductRepository";
import { Store } from "./libs/Store";
import BraetDatabase, { Db } from "./libs/QuickCollectDatabase";
import { CustomerRepo } from "./libs/CustomerRepository";
import VueCookies from "vue-cookies";
import { uuid } from "vue-uuid";
import InstallBanner from "./components/InstallBanner.vue";
import DesktopNav from "./components/shared/DesktopNav.vue";
import Multiselect from "vue-multiselect";
import PushSubscribe from "./components/PushSubscribe.vue";
import Privacy from "./components/shared/Privacy.vue";
import GeneralTerms from "./components/shared/GeneralTerms.vue";
import CookiePolicy from "./components/shared/CookiePolicy.vue";
import { UtilsRepo, Utils } from "./libs/Utils";
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';

export default Vue.extend({
  name: "App" as string,
  data() {
    return {
      loaded: false,
      displayMode: 0,
      online: navigator.onLine,
      store: Store.state,
      //sectorObjects : Store.state.filter.sectorObjects,
      componentKey: 0,
      displaySectors: [] as string[],
      categories: [] as any[],
    };
  },
  metaInfo(this: any) {
    return {
      title: this.$t('PageTitle') + " | QuickCollect",
      meta: [
        {
          vmid: "site_name",
          property: "og:site_name",
          content: "QuickCollect",
        },
        { vmid: "site_url", property: "og:url", content: window.location.href },
        { vmid: "site_image", property: "og:image", content: "" },
      ],
    };
  },
  async created() {
    var self = this;
    var mainSite =
      window.location.host.indexOf("quickcollect.shop") !== -1 ||
      window.location.host.indexOf("quickcollectcustomer.debugged.be") !== -1 ||
      window.location.host.indexOf("localhost") !== -1;

    //set user session

    var userSession = this.$cookies.get("user_session");

    if (userSession === undefined || userSession === null) {
      this.$cookies.set("user_session", uuid.v4(), "2d");
    } else {
      // prolong cookie every visit
      this.$cookies.set("user_session", userSession, "2d");
    }

    //set user
    if (localStorage.getItem("user") != null) {
      this.store.loggedInUser = JSON.parse(
        localStorage.getItem("user") ?? "{}"
      );
    }

    //set language
    this.store.culture = (
      localStorage.getItem("culture")
        ? localStorage.getItem("culture")
        : "nl"
    ) as string;
    this.$i18n.locale = (
      localStorage.getItem("culture") ? localStorage.getItem("culture") : "nl"
    ) as string;

    console.log("locale", this.$i18n.locale)

    Vue.use(VueFilterDateFormat, {
      dayOfWeekNames: [
        this.$i18n.t('Sunday'), this.$i18n.t('Monday'), this.$i18n.t('Tuesday'), this.$i18n.t('Wednesday'), this.$i18n.t('Thursday'),
        this.$i18n.t('Friday'), this.$i18n.t('Saturday')
      ],
});

    if (this.$route.name == "companyRedirect") {
      var company = await Promise.resolve(
        CompanyRepo.getCompanyBySlug(this.$route.path)
      );
      if (company !== undefined) {
        this.store.currentCompany = company;
      }
    }
    // @ts-ignore
    else if (this.$session.get("companyRedirect") !== undefined) {
      
      var company = await Promise.resolve(
        // @ts-ignore
        CompanyRepo.getCompanyBySlug(this.$session.get("companyRedirect"))
      );
      if (company !== undefined) {
        this.store.currentCompany = company;
      }
    } else if (
      this.$route.name == "company" &&
      this.$route.path.indexOf("/d") !== -1
    ) {
      // @ts-ignore
      var company = await Promise.resolve(
        CompanyRepo.getCompanyBySlug(this.$route.path.replace("/d", ""))
      );
      if (company !== undefined) {
        this.store.currentCompany = company;
      }
    } else if (mainSite == false) {
      var company = await Promise.resolve(
        CompanyRepo.getCompanyByDomain(window.location.hostname)
      );
      if (company !== undefined) {
        this.store.currentCompany = company;
      }
    }

    console.log("store logging");
    console.log(this.store);
    this.addCustomLessScript();
    this.loaded = true;

    this.store.culture = (
      localStorage.getItem("culture") ? localStorage.getItem("culture") : "nl"
    ) as string;
    this.$i18n.locale = (localStorage.getItem("culture") ?? "nl") as string;
  },
  methods: {
    isAuthenticated() {
      return localStorage.getItem("user") !== null ? true : false;
    },
    redirectStart() {
      // @ts-ignore
      if (this.$session.get("companyRedirect") !== undefined) {
        // @ts-ignore
        var companySlug = this.$session.get("companyRedirect");
        this.$router.push(companySlug);
      }
      // @ts-ignore
      else if (this.$session.get("companyDomain") !== undefined) {
        this.$router.push("/");
      } else {
        this.$router.push({ name: "home" });
      }
    },
    wrapperClicked(event: any) {
      const element = this.$el.querySelector(".wrapper");
      if (element != null) {
        if (element.classList.contains("js-close-any")) {
          this.$root.$emit("filter-companies");
        }
      }

      if (
        event.target.classList.contains("blob-toggle") == false &&
        event.target.classList.contains("hamburger") == false
      ) {
        this.$root.$emit("close-blob-overlay");
      }
    },
    setDisplayMode(mode: any) {
      this.$root.$emit("change-display-mode", mode);
    },
    submitFilter() {
      this.$root.$emit("filter-companies");
    },
    confirmLocation() {
      localStorage.setItem("location", "1");
      this.displayMode = 0;
    },
    denyLocation() {
      localStorage.setItem("location", "0");
      this.displayMode = 0;
    },
    installPwa: function () {
      this.$root.$emit("install-pwa");
    },
    hideInstall: function () {
      localStorage.setItem("installDismissed", new Date().toJSON());
      this.displayMode = 0;
    },

    async logout() {
      // purge database
      /* Db.close();
                await Db.delete();
                Db.open(); */
      await CustomerRepo.clearPersonalData();

      this.store.loggedInUser = {};
      localStorage.removeItem("user");
      this.$router.push({ name: "login" });
      setTimeout(function () {
        // @ts-ignore
        var headerHeight = $("header.header").outerHeight(true);
        // @ts-ignore
        $(".wrapper > .main").css("padding-top", headerHeight);
      }, 200);
    },
    switchLanguage(lang: string) {
      this.store.culture = lang;
      this.$i18n.locale = lang;
      localStorage.setItem("culture", lang);

      // update the component
      this.componentKey += 1;

      // fix non vue hamburger menu
      setTimeout(function () {
        // @ts-ignore
        window.filterWindow();
      }, 200);
    },
    isOnline() {
      return navigator.onLine;
    },
    updateConnectionStatus() {
      this.online = navigator.onLine; // this method
      setTimeout(function () {
        // @ts-ignore
        var headerHeight = $("header.header").outerHeight(true);
        // @ts-ignore
        $(".wrapper > .main").css("padding-top", headerHeight);
      }, 200);
    },
    hideNavigation: function (): boolean {
      return this.$router.currentRoute.name == "customers";
    },
    addCustomLessScript: function () {
      var url = window.location.href;
      var splittedUrl = url.split("/");

      //add less script
      let lessScript = document.createElement("link");
      lessScript.setAttribute(
        "href",
        splittedUrl[0] + "//" + splittedUrl[2] + "/less/style.less"
      );
      lessScript.setAttribute("type", "text/css");
      lessScript.setAttribute("rel", "stylesheet/less");

      document.head.appendChild(lessScript);

      if (this.store.currentCompany !== undefined) {
        //add custom variables
        let customAttributes = document.createElement("script");
        customAttributes.id = "custom-less-attributes";
        var scriptString = "var less = {";
        scriptString += 'env: "development",';
        scriptString += "modifyVars: {";

        if (
          this.store.currentCompany.primaryColor !== undefined &&
          this.store.currentCompany.primaryColor !== null
        ) {
          scriptString +=
            " 'primary-color': '" +
            this.store.currentCompany.primaryColor +
            "',";
        }

        if (
          this.store.currentCompany.secondaryColor !== undefined &&
          this.store.currentCompany.secondaryColor !== null
        ) {
          scriptString +=
            " 'secondary-color':'" +
            this.store.currentCompany.secondaryColor +
            "',";
        }

        if (
          this.store.currentCompany.buttonColor !== undefined &&
          this.store.currentCompany.buttonColor !== null
        ) {
          scriptString +=
            " 'highlight-color': '" +
            this.store.currentCompany.buttonColor +
            "',";
        }

        scriptString += "}";
        scriptString += "}";

        customAttributes.innerHTML = scriptString;

        document.head.appendChild(customAttributes);
      }

      //add less js script
      let lessJsScript = document.createElement("script");
      lessJsScript.setAttribute(
        "src",
        "//cdnjs.cloudflare.com/ajax/libs/less.js/2.7.2/less.min.js"
      );

      document.head.appendChild(lessJsScript);
    },
    isOldBrowser(): boolean {
      var ua = window.navigator.userAgent;
      var msie = ua.indexOf("MSIE ");

      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        // If Internet Explorer, return true
        return true;
      } // If another browser, return 0
      else {
        return false;
      }

      return false;
    },
  },
  computed: {
    IsLoggedInAs: function (): boolean {
      setTimeout(function () {
        // @ts-ignore
        var headerHeight = $("header.header").outerHeight(true);
        // @ts-ignore
        $(".wrapper > .main").css("padding-top", headerHeight);
      }, 200);

      return (
        this.store.loggedInUser.loggedInAs &&
        JSON.stringify(this.store.loggedInUser.loggedInAs) !==
          JSON.stringify({})
      );
    },
  },
  watch: {
    "store.filter.sectorObjects": function (newValues) {
      this.store.filter.sectors = newValues.map((obj: any) => obj.value);
    },
  },

  mounted: async function () {
    console.log("store logging");
    console.log(this.store);
    var self = this;
    this.displaySectors = await CompanyRepo.getDisplaySectors();
    var availableSectors = await CompanyRepo.getSectors();
    for (let index = 0; index < availableSectors.length; index++) {
      if (this.displaySectors.indexOf(availableSectors[index].value) !== -1) {
        this.categories.push({
          name: this.$t(availableSectors[index].value),
          value: availableSectors[index].value,
        });
      }
    }

    console.log(this.$route.name);

    this.$root.$on("change-display-mode", function (mode: any) {
      console.log("change display mode");
      console.log(mode);
      self.displayMode = mode;
    });

    //set less
    //this.addCustomLessScript();

    // // @ts-ignore
    // settingsFlyout();
  },
  components: {
    InstallBanner,
    DesktopNav,
    Privacy,
    CookiePolicy,
    GeneralTerms,
    Multiselect,
    PushSubscribe,
  },
});
