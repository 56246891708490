export const Store = {
    state: {
        nbProductsInBasket: 0 as number,
        culture: "nl-BE",
        currentAddress : '',
        filter : {
            sorting : 'Voorkeur',
            sectors : [] as string[],
            sectorObjects : [] as any[],
            open : '',
            searchTerm : ''
        },
        showDefault : true,
        currentCompany : {} as any,
        loggedInUser: {
            loggedInAs: {}
        } as any
    }
}