









































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CompanyRepo, ICompany } from "../libs/CompanyRepository";
import { configs } from "../libs/config";
import VueGoogleAutocomplete from "../../node_modules/vue-google-autocomplete/src/VueGoogleAutocomplete.vue";

import { ProductRepo } from "../libs/ProductRepository";
// @ts-ignore
import { IArticle } from "../libs/QuickCollectDatabase";

import { UtilsRepo, Utils } from "../libs/Utils";

// @ts-ignore
import Autocomplete from "@trevoreyre/autocomplete-vue";

// @ts-ignore
import imagesLoaded from "vue-images-loaded";
import { Store } from "../libs/Store";
import modal from "./Modal.vue";
// @ts-ignore

import { gmapApi } from "../../node_modules/vue2-google-maps";
import DesktopNav from "../components/shared/DesktopNav.vue";

export default Vue.extend({
  name: "Companies" as string,
  components: {
    VueGoogleAutocomplete,
    modal,
    DesktopNav,
  },
  directives: {
    imagesLoaded,
  },
  data() {
    return {
      enableGeo: true,
      noResults: false,
      isMethodsOpen: false,
      isModalVisible: false,
      authenticated: false,
      store: Store.state,
      address: {
        latitude: 50.8499966,
        longitude: 4.3499986,
        locality: "Brussel",
        postal_code: "",
        route: "",
      },
      categories: [] as any[],
      displaySectors: [] as string[],
      products: [] as IArticle[],
      companies: [] as ICompany[],
      isInstalled: false,
      paging: {
        page: 1,
        itemsPerPage: 9,
        pages: [] as number[],
        total: 0 as number,
      },
      searchTerm: "",
      viewMethod: "list",
      loading: false,
      imageHost: configs.ImageHost,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null as number | null,
      utilsRepo: UtilsRepo,
      infoOptions: {
        content: "",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      canInstall: false,
      alreadyAccepted: false,
      deferredPrompt: null as any,
      installIos: false,
      installFirefox: false,
      showLangDropdown: false,
      availableLanguages: ["NL", "FR"]
    };
  },
  methods: {
    openMethods: function () {
      this.isMethodsOpen = !this.isMethodsOpen;
    },
    isActiveSector(sector: string) {
      console.log("sectors", this.store.filter.sectors);
      console.log(sector);
      if (this.store.filter.sectors.indexOf(sector.toLowerCase()) !== -1) {
        return true;
      }
      return false;
    },
    installPwa: function () {},
    setDisplayMode(mode: any) {
      console.log("set display mode");
      this.$root.$emit("change-display-mode", mode);
    },
    isFirefox: function () {
      return (
        navigator.userAgent.toLowerCase().indexOf("firefox") > -1 &&
        navigator.platform.toLowerCase().indexOf("android") > -1
      );
    },
    isInStandaloneMode: function (): boolean {
      // @ts-ignore
      return "standalone" in window.navigator && window.navigator.standalone;
    },
    showOpeningshours(company: ICompany, event: any) {
      Utils.showOpeningshours(company, event, this.$swal);
    },
    setSector: function (sector: string) {
      var foundObject = this.store.filter.sectorObjects.find(function (item) {
        return item.value === sector.toLowerCase();
      });

      if (foundObject === undefined) {
        var currentCategory = this.categories.find(function (item) {
          return item.value === sector.toLowerCase();
        });
        if (currentCategory === undefined) {
          this.store.filter.sectorObjects.push({
            name: sector,
            value: sector.toLowerCase(),
          });
        } else {
          this.store.filter.sectorObjects.push(currentCategory);
        }
      } else {
        var sectorIndex = this.store.filter.sectorObjects.indexOf(foundObject);
        this.store.filter.sectorObjects.splice(sectorIndex, 1);
      }

      this.store.filter.sectors = this.store.filter.sectorObjects.map(
        (obj: any) => obj.value
      );
      this.setViewMethod(this.viewMethod, true);
    },
    getData: async function () {
      console.log(this.store);
      this.isModalVisible = false;
      this.loading = true;
      var self = this;
      console.log("adress");
      console.log(this.address);
      var res = await CompanyRepo.getCompanies(
        this.paging.page,
        this.paging.itemsPerPage,
        this.viewMethod === "search"
          ? this.searchTerm
          : this.store.filter.searchTerm,
        this.store.filter.sectors,
        this.store.filter.open,
        this.store.filter.sorting,
        self.store.loggedInUser.userId,
        // @ts-ignore
        this.address.longitude,
        // @ts-ignore
        this.address.latitude
      );

      console.log(res);
      this.companies = res?.companies ?? [];
      //paging
      this.paging.pages = [];
      this.paging.total = res?.nbResults ?? 0;

      if (!res) {
        this.loading = false;
        this.noResults = true;
        return;
      } else if (this.paging.total == 0) {
        this.noResults = true;
      } else {
        this.noResults = false;
      }

      for (var index = 1; index <= res.pageCount; index++) {
        this.paging.pages.push(index);
      }

      //apply favorites if offline
      if (localStorage.getItem("user") == null) {
        var favoriteCompanies = localStorage.getItem("favoriteCompanies");
        var companyIds = [] as string[];

        if (favoriteCompanies != null) {
          companyIds = favoriteCompanies.split(",");
        }

        for (var index = 0; index < this.companies.length; index++) {
          if (companyIds.indexOf(this.companies[index].companyId) !== -1) {
            this.companies[index].favorite = true;
          }
        }
      }

      this.loading = false;
    },
    toggleInfoWindow: function (marker: any, idx: number) {
      // this.$router.push({name:'company', params: {id: marker.companyId }})
      console.log(idx);
      console.log(marker);
      // @ts-ignore
      this.infoWindowPos = new google.maps.LatLng(
        marker.latitude,
        marker.longitude
      );
      var contentString =
        '<div class="card maps-card"><h4>' + marker.companyName + "</h4>";
      contentString +=
        '<div class="sector">' +
        this.categories.find((c) => c.value == marker.sector.toLowerCase())
          ?.name +
        "</div>";
      contentString += '<div class="card__read">';
      contentString += '<div class="card__price">';

      if (marker.closed) {
        contentString +=
          '<div ><span class="status closed"></span> Gesloten</div>';
      } else {
        contentString += '<div ><span class="status open"></span> Open</div>';
      }
      if (marker.closestOpeningTime) {
        contentString +=
          "<div >Open vanaf " + marker.closestOpeningTime + "</div>";
      }
      contentString +=
        '<a href="/' +
        encodeURIComponent(marker.companySlug) +
        '/d">Details</a>';

      contentString += "</div> </div>";

      this.infoOptions.content = contentString;
      //    <div class="card__read">
      //      <div class="card__price">
      //        <span v-if="company.favorite !== true" v-on:click="toggleFavorite(company,$event)"><i aria-hidden="true" class="far fa-star"></i> Favorite</span>
      //        <span v-if="company.favorite === true" v-on:click="toggleFavorite(company,$event)"><i aria-hidden="true" class="fa fa-star"></i> Favorite</span>
      //        <span class="card__bottom" v-if="company.closestOpeningTime">
      //          <div >{{"Open vanaf " + company.closestOpeningTime}}</div>
      //        </span>
      //       </div>
      //     </div>
      //   </router-link>
      // </div>';

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = true;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    getAddressData: function (addressData: any, placeResultData: any, id: any) {
      console.log("get address data");
      if (!addressData) return;
      this.address = addressData;
      console.log(this.address);
      localStorage.setItem("address", JSON.stringify(this.address));
    },
    imageError(imageId: string) {
      var el = document.getElementById(imageId);

      el?.setAttribute("src", "/images/product-placeholder.png");
    },
    loaded() {
      console.log("loaded");
      // @ts-ignore
      $(".products .products__list .product-item .card__name").matchHeight();
      // @ts-ignore
      $(".products .products__list .product-item figure").matchHeight();
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    saveModal() {
      // @ts-ignore
      this.$refs.addressAutoComplete.$refs.autocomplete.value = "";
      this.isModalVisible = false;
      this.getData();
    },
    toggleFavorite(company: ICompany, event: any) {
      event.preventDefault();

      if (localStorage.getItem("user") == null) {
        var favoriteCompanies = localStorage.getItem("favoriteCompanies");
        var companyIds = [] as string[];
        if (favoriteCompanies != null) {
          companyIds = favoriteCompanies
            .split(",")
            .filter((f) => f != "" && f != undefined);
        }
        var companyIndex = companyIds.indexOf(company.companyId);
        if (companyIndex === -1) {
          companyIds.push(company.companyId);
        } else {
          companyIds.splice(companyIndex, 1);
        }

        localStorage.setItem("favoriteCompanies", companyIds.join(","));

        if (company.favorite == null) {
          company.favorite = true;
        } else {
          company.favorite = !company.favorite;
        }
      } else {
        if (company.favorite == null) {
          company.favorite = true;
        } else {
          company.favorite = !company.favorite;
        }
        var self = this;
        var res = CompanyRepo.updateFavoriteStatus(
          company.companyId,
          self.store.loggedInUser.userId,
          company.favorite
        );
      }

      console.log(company);
    },
    hasOrders(companyId: string) {
      var existingItem = this.products.filter(function (item) {
        return item.companyId == companyId;
      });
      return existingItem.length;
    },
    setViewMethod(method: string, ignoreOpen: boolean) {
      console.log(method);

      this.viewMethod = method;

      if (!ignoreOpen) {
        this.store.filter.sorting = "Voorkeur";
        this.store.filter.sectors = [];
        this.store.filter.sectorObjects = [];
        this.store.filter.open = "";
        this.isMethodsOpen = !this.isMethodsOpen;
      }

      if (method == "list") {
        this.companies = [];
        this.paging.pages = [];
        this.paging.total = 0;
        this.searchTerm = "";
        this.getData();
      } else if (method == "search") {
        this.companies = [];
        this.paging.pages = [];
        this.paging.total = 0;
        this.noResults = false;
      } else if (method == "maps") {
        this.companies = [];
        this.paging.pages = [];
        this.paging.total = 0;
        this.getData();
      }
    },
    search: async function () {
      console.log("search");
      console.log(this.searchTerm);
      if (this.searchTerm.length < 1) {
        this.noResults = true;
        this.companies = [];
        this.paging.pages = [];
        this.paging.total = 0;
        return [];
      }
      var self = this;
      var res = await CompanyRepo.getCompanies(
        this.paging.page,
        this.paging.itemsPerPage,
        this.searchTerm,
        this.store.filter.sectors,
        this.store.filter.open,
        this.store.filter.sorting,
        self.store.loggedInUser.userId,
        // @ts-ignore
        this.address.longitude,
        // @ts-ignore
        this.address.latitude
      );

      this.companies = res?.companies ?? [];
      //paging
      this.paging.pages = [];
      this.paging.total = res?.nbResults ?? 0;

      if (!res) {
        this.loading = false;
        this.noResults = true;
        return;
      } else if (this.paging.total == 0) {
        this.noResults = true;
      } else {
        this.noResults = false;
      }

      for (var index = 1; index <= res.pageCount; index++) {
        this.paging.pages.push(index);
      }

      this.loading = false;
    },
    switchLanguage(lang: string) {
      lang = lang.toLowerCase();
      this.store.culture = lang;
      this.$i18n.locale = lang;
      localStorage.setItem("culture", lang);

      // fix non vue hamburger menu
      setTimeout(function () {
        // @ts-ignore
        window.filterWindow();
      }, 200);
      this.$route.query.k = new Date().valueOf() + "";
    },
    // submitSearch: function(result: any) {
    //   return;
    // },
    // toListView: function() {
    //   this.listView = true;
    // },
    // toGridView: function() {
    //   this.listView = false;
    // },
    // getStockIndication: function(product: IArticle) {
    //   var classes = "card__name matchHeight ";
    //   if (product.stock > 5) {
    //     classes += "has-success";
    //   } else if (product.stock > 0) {
    //     classes += "has-warning";
    //   } else {
    //     classes += "has-error";
    //   }

    //   return classes;
    // },

    // imageError(imageId: string) {
    //   var el = document.getElementById(imageId);

    //   el?.setAttribute("src", "/images/product-placeholder.png");
    // },
    // loaded() {
    //   console.log("loaded");
    //   // @ts-ignore
    //   $(".products .products__list .product-item .card__name").matchHeight();
    //   // @ts-ignore
    //   $(".products .products__list .product-item figure").matchHeight();
    // }
  },
  async created() {
    // @ts-ignore
    this.$session.remove("companyRedirect");
    // @ts-ignore
    this.$session.set("fromCompanyOverview", true);

    var self = this;
    //check if cookie policy is set
    var preferencesSet = this.$cookies.get("_cookiePreferencesSet");
    if (preferencesSet !== null && preferencesSet != undefined) {
      var inCooloffPeriod =
        localStorage.getItem("installDismissed") != null &&
        new Date().getTime() -
          new Date(localStorage.getItem("installDismissed") ?? "").getTime() <
          1000 * 60 * 60 * 24 * 10;

      if (inCooloffPeriod) return;
      // ios device
      if (Utils.isIos() && !this.isInStandaloneMode()) {
        console.log("is ios");

        setTimeout(function () {
          // @ts-ignore
          console.log("timeout & accepted");
          console.log(self.alreadyAccepted);
          if (self.alreadyAccepted == false) {
            self.alreadyAccepted = true;
            self.$root.$emit("change-display-mode", 3);
          }
        }, 5000);
        return;
      } else if (this.isFirefox() && !this.isInStandaloneMode()) {
        this.installFirefox = true;
        return;
      }
      window.addEventListener("beforeinstallprompt", function (e) {
        console.log("beforeinstallprompt");
        console.log(e);
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.

        self.deferredPrompt = e;
        setTimeout(function () {
          // @ts-ignore
          if (self.deferredPrompt && self.alreadyAccepted == false) {
            self.canInstall = true;
            self.alreadyAccepted = true;
            self.$root.$emit("change-display-mode", 2);
          }
        }, 5000);
      });
    }
  },
  async mounted() {
    var self = this;
    console.log("MOUNTED");
    console.log(JSON.stringify(this.address));
    if (localStorage.getItem("address") !== null) {
      // @ts-ignore
      this.address = JSON.parse(localStorage.getItem("address"));
    }

    if (localStorage.getItem("user") != null) {
      this.authenticated = true;
    }

    var res = await ProductRepo.getProductsInBasket();
    this.products = res;

    this.store.filter.searchTerm = "";
    this.store.filter.sectors = [];
    this.store.filter.open = "";
    this.store.filter.sectorObjects = [];

    // @ts-ignore
    settingsFlyout();

    // @ts-ignore
    // Get current locationupdate
    self.getData();

    this.displaySectors = await CompanyRepo.getDisplaySectors();
    var availableSectors = await CompanyRepo.getSectors();

    for (let index = 0; index < availableSectors.length; index++) {
      if (this.displaySectors.indexOf(availableSectors[index].value) !== -1) {
        this.categories.push({
          name: availableSectors[index].name,
          value: availableSectors[index].value,
        });
      }
    }

    this.categories.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );

    // @ts-ignore
    less.modifyVars({
      "primary-color": "#1a8994",
      "secondary-color": "#64bda1",
      "highlight-color": "#ff8100",
    });

    this.$root.$on("filter-companies", function () {
      console.log("filter-companies");
      self.getData();
    });

    this.$root.$on("close-blob-overlay", function () {
      console.log("close blob nav");
      self.isMethodsOpen = false;
    });

    this.$root.$on("install-pwa", function () {
      self.$root.$emit("change-display-mode", 0);
      self.alreadyAccepted = true;
      // Show the prompt
      self.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt

      self.deferredPrompt.userChoice
        // @ts-ignore
        .then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the A2HS prompt");
          } else {
            console.log("User dismissed the A2HS prompt");
          }
          self.canInstall = false;

          self.deferredPrompt = null;
        });
    });
    var preferencesSet = this.$cookies.get("_cookiePreferencesSet");
    if (preferencesSet && navigator.geolocation) {
      if (Utils.isIos() == false) {
        setTimeout(() => {
          if (localStorage.getItem("location") === null) {
            console.log("no-location");
            this.$root.$emit("change-display-mode", 1);
          } else {
            this.$root.$emit("change-display-mode", 0);
          }
        }, 500);
      }
      navigator.geolocation.getCurrentPosition((location) => {
        console.log(location);
        localStorage.setItem("location", "1");

        // @ts-ignore
        var geocoder = new google.maps.Geocoder();
        // @ts-ignore
        var myLatlng = new google.maps.LatLng(
          // @ts-ignore
          parseFloat(location.coords.latitude),
          // @ts-ignore
          parseFloat(location.coords.longitude)
        );
        geocoder.geocode(
          { location: myLatlng },
          function (results: any, status: any) {
            if (status === "OK") {
              if (results[0]) {
                console.log(self.$refs);
                console.log(self.$refs.addressAutoComplete);
                console.log(results[0]);
                // @ts-ignore
                var response = self.$refs.addressAutoComplete?.update(
                  results[0].formatted_address
                );

                var saveObject = Utils.processGoogleMapsData(results[0]);

                console.log("save object");
                console.log(saveObject);
                // @ts-ignore
                saveObject.latitude = location.coords.latitude;
                saveObject.longitude = location.coords.longitude;
                localStorage.setItem("address", JSON.stringify(saveObject));

                self.address = saveObject;
              } else {
                //window.alert('No results found');
              }
            } else {
              //window.alert('Geocoder failed due to: ' + status);
            }
          }
        );
      });
    }

    if (this.$route.params.sector) {
      this.setSector(this.$route.params.sector);
    }
  },
  watch: {
    currentPage: function () {
      //deze 'watch' traceert page changes en kan de data anders ophalen
      this.getData();
    },
  },
  computed: {
    google: gmapApi,
    isFilterActive: function isFilterActive(): boolean {
      return (
        (this.store.filter.searchTerm !== undefined &&
          this.store.filter.searchTerm != "") ||
        this.store.filter.sectors.length !== 0
      );
    },
    maxPage: function maxPage(): number {
      //computed om max page te bepalen, wordt gebruikt in paging html
      if (this.paging.pages.length == 0) return 0;
      return this.paging.pages.reduce((a, b) => (a > b ? a : b));
    },
    currentPage: function currentPage(): number {
      //deze computed value wordt gebruikt om page changes te traceren, zie watch
      return this.paging.page;
    },
    addressString: function addressString(): string {
      // @ts-ignore
      return (
        (this.address.route ? this.address.route + " " : "") +
        // @ts-ignore
        (this.address.street_number ? this.address.street_number + " " : "") +
        (this.address.locality ?? "")
      );
    },
    getPaging: function (): number[] {
      // <li v-bind:class="{'page-item': 'page-item', 'active': paging.page == pageNumber}"
      //     v-if="paging.page == 1"
      //     v-for="pageNumber in paging.pages.slice(paging.page-1, paging.page+4)"
      //     :key="pageNumber">
      //     <a class="page-link"
      //         href
      //         v-on:click.prevent="paging.page = pageNumber">{{pageNumber}}</a>
      // </li>
      // <li v-bind:class="{'page-item': 'page-item', 'active': paging.page == pageNumber}"
      //     v-if="paging.page == 2"
      //     v-for="pageNumber in paging.pages.slice(paging.page-2, paging.page+3)"
      //     :key="pageNumber">
      //     <a class="page-link"
      //         href
      //         v-on:click.prevent="paging.page = pageNumber">{{pageNumber}}</a>
      // </li>
      // <li v-bind:class="{'page-item': 'page-item', 'active': paging.page == pageNumber}"
      //     v-if="paging.page > 2 && paging.page <= maxPage - 2"
      //     v-for="pageNumber in paging.pages.slice(paging.page-3, paging.page+2)"
      //     :key="pageNumber">
      //     <a class="page-link"
      //         href
      //         v-on:click.prevent="paging.page = pageNumber">{{pageNumber}}</a>
      // </li>
      // <li v-bind:class="{'page-item': 'page-item', 'active': paging.page == pageNumber}"
      //     v-if="paging.page > maxPage - 2 && maxPage - 2 > 0"
      //     v-for="pageNumber in paging.pages.slice(maxPage-5, maxPage)"
      //     :key="pageNumber">
      //     <a class="page-link"
      //         href
      //         v-on:click.prevent="paging.page = pageNumber">{{pageNumber}}</a>
      // </li>

      var minPage = this.paging.page > 3 ? this.paging.page - 3 : 0;
      var maxPage = this.paging.page + 4;
      return this.paging.pages.slice(minPage, maxPage);
    },
  },
});
