































































































































































































































































































































































































import Vue from "vue";

export default Vue.extend({
    props : {
        popup : Boolean
    },
  data() {
    return {
    };
  },
  methods: {
    setDisplayMode(mode : any) {
      console.log('set display mode');
       this.$root.$emit('change-display-mode', mode);
    },
  },
  created() {
   
  }
});
