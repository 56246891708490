import Dexie from 'dexie'

export default class QuickCollectDatabase extends Dexie {
  public baskets: Dexie.Table<IBasket, string>;

  constructor() {
    super("QuickCollectDatabase");
  
    //
    // Define tables and indexes
    // (Here's where the implicit table props are dynamically created)
    //
    this.version(1.5).stores({
      baskets: 'id',
    });

    this.version(1.6).stores({
      baskets: 'id',
    });

    // The following lines are needed for it to work across typescipt using babel-preset-typescript:
    this.baskets = this.table("baskets");
  }
}

export interface IArticle {
  categoryName: string;
  productName: string;
  unitType: string;
  pricePerUnit : number | undefined;
  originalPrice : number | undefined;
  productId : number;
  companyId : string;
  amount : number;
  image : string;
  availableDays : string;
  deleted : boolean  | undefined;
  customUnitType : string | undefined;
  customUnitValue : string | undefined;
  customUnitType2? : string | undefined;
  customUnitValue2? : string | undefined;
  customUnitType3? : string | undefined;
  customUnitValue3? : string | undefined;
  otherProducts : IArticle[];
  stock : number | undefined;
  originalAmount : number;
  depleted?: boolean;
  childProducts?: IArticle[],
  editMode?: boolean;
  infoText? : string
  orderItemId?: number
  slug?: string;
  productDescription? :string;
  orderItemRemarkText? :string;
  enableOrderItemRemarks? :string;
  remark? :string;
  media?: any[];
  deposit?: number;
}

export interface IBasket {
  id: number;
  finished: boolean;
  remark: string;
  synced: boolean;
  added: Date | undefined;
  productIds: IArticleQuantity[];
  customerId: string;
  sessionId : string;
}

export class Basket implements IBasket {

  constructor(id: number, customerId : string , sessionId : string) {
    this.id = id;
    this.finished = false;
    this.synced = false;
    this.productIds = [];
    this.remark = "";
    this.customerId = customerId;
    this.sessionId = sessionId;
  }

  id: number;
  finished: boolean;
  synced: boolean;
  productIds: IArticleQuantity[];
  remark: string;
  customerId: string;
  sessionId: string;
  added: Date | undefined;
}

export interface IArticleQuantity {
  productId: number;
  quantity: number;
  companyId : string;
  unitPrice: number | undefined;
  unitType : string;
  remark: string | undefined;
  subArticles: IArticleQuantity[];
}

export class ArticleQuantity implements IArticleQuantity{
  constructor(productId : number, quantity: number, unitPrice : number | undefined, unitType : string, companyId : string, subArticles? : IArticleQuantity[], 
    remark? : string) {
    this.productId = productId;
    this.quantity = quantity;
    this.unitType = unitType;
    this.unitPrice = unitPrice;
    this.companyId = companyId;
    this.subArticles = subArticles ?? [];
    this.remark = remark;
  }
  productId: number;
  quantity: number;
  unitPrice: number | undefined;
  unitType: string;
  companyId: string;
  remark: string | undefined;
  subArticles: IArticleQuantity[];
}

export var Db = new QuickCollectDatabase();

Db.on("versionchange", function (event) {
  console.log("closed");
  Db.close();
});