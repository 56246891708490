





















































































































































import { CompanyRepo, ICompany } from "@/libs/CompanyRepository";
import { ProductRepo } from "@/libs/ProductRepository";
import Vue from "vue";
// @ts-ignore
import Datepicker from "vuejs-datepicker";
// @ts-ignore
import { nl } from "vuejs-datepicker/dist/locale";
import { ITimeRange } from "../../libs/ProductRepository";

export default Vue.extend({
  props: {
    value: Array,
    companyId: String,
    company: Object,
    deliveryMethod: String,
  },
  components: { Datepicker },
  data() {
    return {
      showTimeSlotPopup: false,
      nl: nl,
      daySelected: false,
      unavailableDays: [] as Date[],
      disabledDates: {
        vueContext: this,
        to: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ),
        customPredictor: function (date: Date): boolean {
          if (
            // @ts-ignore
            this.vueContext.unavailableDays.some(
              (d: Date) =>
                date.getDate() == d.getDate() &&
                date.getMonth() == d.getMonth() &&
                date.getFullYear() == d.getFullYear()
            )
          )
            return true;
          // Disable all dates that are a closing day
          var dayOfTheWeek = date.getDay();
          var today = new Date();
          console.log(dayOfTheWeek);
          //@ts-ignore
          console.log("hours", this.vueContext.selectableHours);
          //@ts-ignore
          var currentHour = this.vueContext.selectableHours.find(
            (f: any) => f.dayOfTheWeek == dayOfTheWeek
          ) as any;
          console.log(currentHour);

          if (!currentHour || currentHour.closed == true) {
            return true;
          }

          if (
            this.vueContext.deliveryMethod == "takeaway" &&
            (!currentHour.takeAwayHourRanges ||
              currentHour.takeAwayHourRanges.length == 0)
          )
            return true;

          if (
            this.vueContext.deliveryMethod == "delivery" &&
            (!currentHour.deliveryhourRanges ||
              currentHour.deliveryhourRanges.length == 0)
          )
            return true;

          // if the day is today, check the opening ranges too
          if (
            currentHour.takeAwayHourRanges &&
            date.getDate() == today.getDate() &&
            date.getMonth() == today.getMonth() &&
            date.getFullYear() == today.getFullYear()
          ) {
            var hourString = new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });

            if (
              !currentHour.takeAwayHourRanges.some(
                (r: any) => r.until >= hourString
              )
            )
              return true;
          }

          // if the day is today, check the opening ranges too
          if (
            currentHour.deliveryhourRanges &&
            date.getDate() == today.getDate() &&
            date.getMonth() == today.getMonth() &&
            date.getFullYear() == today.getFullYear()
          ) {
            var hourString = new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });

            if (
              !currentHour.deliveryhourRanges.some(
                (r: any) => r.until >= hourString
              )
            )
              return true;
          }

          var validDate = true as Boolean;
          //@ts-ignore
          this.vueContext.vacations.forEach((element: any) => {
            var fromDate = new Date(Date.parse(element.from));
            fromDate.setHours(0, 0, 0);
            var untilDate = new Date(Date.parse(element.until));
            untilDate.setHours(0, 0, 0);

            date.setHours(0, 0, 0);
            console.log("vacation", element);
            console.log("date", date);

            if (fromDate <= date && untilDate >= date) {
              validDate = false;
            }
          });

          if (validDate === false) {
            return true;
          } else return false;
        },
      },
      date: null as Date | null,
      timeRange: [] as ITimeRange[],
      interval: 30,
      isTodaySelectable: true,
      branches: [] as ICompany[],
      selectedBranch: null as ICompany | null,
      loading: true,
      selectableHours: [] as any[],
      vacations: [] as any[],
    };
  },
  methods: {
    closePopup: function () {
      this.showTimeSlotPopup = false;
    },
    onSelectedDate: async function (date: Date) {
      this.timeRange = [];
      console.log("selected pickup date");

      var res =
        this.deliveryMethod == "takeaway"
          ? await ProductRepo.getAvailableTakeAwayTimeslots(
              this.company.companyId,
              date
            )
          : await ProductRepo.getAvailableDeliveryTimeslots(
              this.company.companyId,
              date
            );

      this.timeRange = res?.slots ?? [];
      this.interval = res?.interval ?? 30;
    },
    selectSlot(slot: any) {
      console.log(slot);
      if (!this.date) this.$emit("input", []);

      var startInterval = new Date(this.date as Date);
      startInterval.setHours(Number.parseInt(slot.value.split(":")[0]));
      startInterval.setMinutes(Number.parseInt(slot.value.split(":")[1]));

      var endInterval = new Date(
        startInterval.getTime() + this.interval * 60000
      );

      this.$emit("input", [startInterval, endInterval]);

      this.closePopup();
    },
    selectToday: function () {
      this.date = new Date();
      this.onSelectedDate(this.date);
      this.daySelected = true;
    },
    openPopup: function () {
      this.selectedBranch = null;
      this.showTimeSlotPopup = true;
      this.daySelected = false;
      this.timeRange = [];
      this.$emit("input", []);
    },
    selectOtherDay: function () {
      this.daySelected = true;
      var nextDay = new Date();
      nextDay.setDate(new Date().getDate() + 1);

      var i = 0;
      while (true) {
        if (this.disabledDates.customPredictor(nextDay)) {
          console.log(nextDay);
          nextDay?.setDate(nextDay.getDate() + 1);
        } else {
          this.date = nextDay;
          this.onSelectedDate(this.date);
          break;
        }

        // only check 100 days in the future
        i++;
        if (i > 100) break;
      }
    },
    async initIsTodaySelectable() {
      this.loading = true;

      console.log("disabled", this.unavailableDays);

      if (!this.selectedBranch) {
        this.loading = false;
        return;
      }

      this.unavailableDays =
        this.deliveryMethod == "takeaway"
          ? await ProductRepo.getDisabledTakeAwayDatesInFirstSevenDays(
              this.selectedBranch.companyId
            )
          : await ProductRepo.getDisabledDeliveryDatesInFirstSevenDays(
              this.selectedBranch.companyId
            );

      this.selectableHours =
        this.deliveryMethod == "takeaway"
          ? await CompanyRepo.getTakeAwayHours(this.selectedBranch.companyId)
          : await CompanyRepo.getDeliveryHours(this.selectedBranch.companyId);

      this.vacations = await CompanyRepo.getVacations(
        this.selectedBranch.companyId
      );

      var res =
        this.deliveryMethod == "takeaway"
          ? await ProductRepo.getAvailableTakeAwayTimeslots(
              this.selectedBranch.companyId,
              new Date()
            )
          : await ProductRepo.getAvailableDeliveryTimeslots(
              this.selectedBranch.companyId,
              new Date()
            );
      console.log("sloties", res?.slots);
      console.log(
        !this.disabledDates.customPredictor(new Date()) &&
          res?.slots != null &&
          res?.slots.length > 0
      );
      this.isTodaySelectable =
        !this.disabledDates.customPredictor(new Date()) &&
        res?.slots != null &&
        res?.slots.length > 0;

      this.loading = false;
    },
    async selectBranch(branch: ICompany) {
      this.selectedBranch = branch;
      await this.initIsTodaySelectable();
      this.$emit("branch-changed", branch);
    },
  },
  async mounted() {
    this.loading = true;
    this.date = (this.value[0] ?? null) as Date | null;

    this.branches = await CompanyRepo.getBranches(this.company.companyId);

    if (this.branches.length == 1) {
      this.selectedBranch = this.branches[0];
      this.initIsTodaySelectable();
    }

    this.loading = false;
  },
  computed: {
    formattedDate: function (): string {
      if (!this.value[0]) return "";
      //@ts-ignore
      return (
        //@ts-ignore
        this.$options.filters.dateFormat(this.value[0], "dddd DD/MM/YY") +
        " " +
        this.$t("From") +
        " " +
        //@ts-ignore
        this.$options.filters.dateFormat(this.value[0], "HH:mm") +
        " " +
        this.$t("To") +
        " " +
        //@ts-ignore
        this.$options.filters.dateFormat(this.value[1], "HH:mm")
      );
    },
  },
  watch: {
    value: function (val) {
      console.log("value", val);
      this.date = val[0];
      this.initIsTodaySelectable();
    },
  },
});
