var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.branches.length > 1 && _vm.selectedBranch)?_c('div',{staticStyle:{"margin-bottom":"10px"},on:{"click":_vm.openPopup}},[_c('strong',[_vm._v(" "+_vm._s(_vm.selectedBranch.branchName)+" "),_c('i',{staticClass:"far fa-edit"})])]):_vm._e(),_c('div',{staticClass:"order-pickupdate",on:{"click":_vm.openPopup}},[_c('input',{attrs:{"type":"text","readonly":"","placeholder":_vm.$t('PickATimeSlot')},domProps:{"value":_vm.formattedDate}})]),(_vm.showTimeSlotPopup)?_c('div',{staticClass:"calender-popup",on:{"click":_vm.closePopup}},[_c('div',{staticClass:"calender-popup-content",staticStyle:{"min-height":"450px"},on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"close",on:{"click":_vm.closePopup}},[_c('i',{staticClass:"fa fa-times"})]),(_vm.loading)?_c('div',[_vm._m(0)]):_vm._e(),(
          _vm.deliveryMethod == 'takeaway' &&
          _vm.branches.length > 1 &&
          !_vm.selectedBranch
        )?_c('div',{staticClass:"branches-container"},[_c('h4',[_vm._v(_vm._s(_vm.$t("PickBranchOffice")))]),_c('div',{staticClass:"branches"},_vm._l((_vm.branches),function(branch){return _c('div',{key:branch.companyId,staticClass:"branch",on:{"click":function($event){return _vm.selectBranch(branch)}}},[_c('div',{staticClass:"branch-name"},[_vm._v(" "+_vm._s(branch.branchName)+" ")]),_c('div',{staticClass:"branch-info"},[_c('div',{staticClass:"address-container"},[_c('i',{staticClass:"fa fa-map-marker-alt"}),_c('address',[_vm._v(" "+_vm._s(branch.street)+" "+_vm._s(branch.number)+" "),_c('br'),_vm._v(" "+_vm._s(branch.postalCode)+" "+_vm._s(branch.postalCode)+" ")])]),_c('i',{staticClass:"fa fa-phone"}),_vm._v(" "+_vm._s(branch.phone)+" ")])])}),0)]):_vm._e(),(
          !_vm.loading &&
          (_vm.deliveryMethod == 'delivery' ||
            _vm.selectedBranch ||
            _vm.branches.length < 2) &&
          _vm.value.length == 0 &&
          !_vm.daySelected
        )?_c('div',{staticClass:"choose-date"},[_c('div',_vm._g({staticClass:"date",class:{ 'not-available': !_vm.isTodaySelectable }},_vm.isTodaySelectable ? { click: _vm.selectToday } : {}),[_c('input',{attrs:{"type":"radio","id":"timeofdelivery-today","name":"deliveryDay","value":"today","disabled":!_vm.isTodaySelectable}}),_c('label',{attrs:{"for":"timeofdelivery-today"}},[_c('i',{staticClass:"fas fa-check-circle checked"}),_c('img',{attrs:{"src":"/images/today.svg","alt":""}}),_vm._v(" "+_vm._s(_vm.$t("Today"))+" ")])]),_c('div',{staticClass:"date"},[_c('input',{attrs:{"type":"radio","id":"timeofdelivery-other","name":"deliveryDay","value":"other-day"},on:{"click":_vm.selectOtherDay}}),_c('label',{attrs:{"for":"timeofdelivery-other"}},[_c('i',{staticClass:"fas fa-check-circle checked"}),_c('img',{attrs:{"src":"/images/pick-a-day.svg","alt":""}}),_vm._v(" "+_vm._s(_vm.$t("OtherDate"))+" ")])])]):(_vm.daySelected)?_c('div',[_c('div',{staticClass:"calender-popup-header"},[_c('datepicker',{attrs:{"language":_vm.nl,"id":'deliveryPickupPopup',"monday-first":true,"disabled-dates":_vm.disabledDates},on:{"selected":_vm.onSelectedDate},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),(_vm.timeRange.length > 0)?_c('div',{staticClass:"timeslots"},_vm._l((_vm.timeRange),function(range){return _c('table',{key:range.hour},[_vm._l((range.slots),function(slot){return _c('tr',{key:slot.value,on:{"click":function($event){return _vm.selectSlot(slot)}}},[_c('td',[_vm._v(_vm._s(slot.label))])])}),_c('tr')],2)}),0):(!_vm.date)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t("PickADate")))])]):_c('div',[_c('p',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.$t("NoTimeSlotsAvailble")))])])]):_vm._e()])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lds-ring"},[_c('div'),_c('div'),_c('div'),_c('div')])}]

export { render, staticRenderFns }