





























































import { Vue } from "vue-property-decorator";
import { CompanyRepo, ICompany } from "../libs/CompanyRepository";
import { ProductRepo } from "../libs/ProductRepository";
import DesktopNav from "../components/shared/DesktopNav.vue";
// @ts-ignore
import PageHeader from "../components/shared/PageHeader.vue";
// @ts-ignore
import imagesLoaded from "vue-images-loaded";
import { IArticle } from "../libs/QuickCollectDatabase";
import { Store } from "../libs/Store";
// @ts-ignore
import Datepicker from "vuejs-datepicker";
import CompanyFooter from "../components/shared/CompanyFooter.vue";
import BuyWidget from "./shared/BuyWidget.vue";

export default Vue.extend({
  name: "ProductDetail" as string,
  components: {
    Datepicker,
    DesktopNav,
    PageHeader,
    CompanyFooter,
    BuyWidget,
  },
  directives: {
    imagesLoaded,
  },
  data() {
    return {
      product: {} as IArticle | null,
      company: {} as ICompany | undefined,
      store: Store.state,
    };
  },
  methods: {
    setDisplayMode(mode: any) {
      this.$root.$emit("change-display-mode", mode);
    },
    goToOverview() {
      this.$router.replace(
        window.location.pathname.substring(
          0,
          window.location.pathname.indexOf("/product")
        ) + "/"
      );
    },
    async onProductOrdered() {
      var result = await this.$swal({
        text: "Product succesvol toegevoegd aan het winkelmandje.",
        showConfirmButton: true,
        confirmButtonText: "Verder winkelen",
        showCancelButton: true,
        icon: "success",
        cancelButtonText: "Naar winkelmandje",
        customClass: { popup: "product-ordered" },
      });

      // @ts-ignore
      if (result.dismiss == "cancel")
        this.$router.replace(
          window.location.pathname.substring(
            0,
            window.location.pathname.indexOf("/product")
          ) + "/#basket"
        );

      if (result.value) this.goToOverview();
    },
  },
  async mounted() {
    this.company = this.$route.name === "companyProduct" 
    ? await CompanyRepo.getCompanyBySlug(
      this.$route.params["companySlug"]
    )
    : await CompanyRepo.getCompanyByDomain(window.location.hostname)

    if (this.company) {
      this.product = await ProductRepo.getProductById(
        this.$route.params.productId,
        this.company.companyId
      );
      if (this.product)
        this.product.amount = ProductRepo.GetQuantityStep(this.product);

      //rebuilding less
      this.store.currentCompany = this.company;
      console.log(this.store);
      if (this.store.currentCompany !== undefined) {
        //add custom variables
        if (
          this.store.currentCompany.primaryColor !== undefined &&
          this.store.currentCompany.primaryColor != null
        ) {
          try {
            // @ts-ignore
            less.modifyVars({
              "primary-color": this.store.currentCompany.primaryColor,
              "secondary-color": this.store.currentCompany.secondaryColor,
              "highlight-color": this.store.currentCompany.buttonColor,
            });
          } catch (e) {}
        }
      }
    }
  },
  watch: {},
  computed: {},
});
