









































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CompanyRepo, ICompany } from "../libs/CompanyRepository";
import { CustomerRepo } from "../libs/CustomerRepository";
import { ProductRepo } from "../libs/ProductRepository";
import Swal from "sweetalert2";
import { configs } from "../libs/config";
import CompanyFooter from "../components/shared/CompanyFooter.vue";

// @ts-ignore
import Autocomplete from "@trevoreyre/autocomplete-vue";
import { UtilsRepo, Utils } from "../libs/Utils";

// @ts-ignore
import imagesLoaded from "vue-images-loaded";
import { IArticle } from "../libs/QuickCollectDatabase";
import { Store } from "../libs/Store";
// @ts-ignore
import { directive as onClickaway } from "vue-clickaway";
import DesktopNav from "../components/shared/DesktopNav.vue";


//@ts-ignore
import VueExpandableImage from 'vue-expandable-image'
Vue.use(VueExpandableImage)

export default Vue.extend({
  name: "Company" as string,
  components: {
    DesktopNav,
    VueExpandableImage,
    CompanyFooter
  },
  directives: {
    imagesLoaded,
    onClickaway: onClickaway,
  },
  data() {
    return {
      config: {
        // @ts-ignore
        handler: this.onClickOutside,
        // @ts-ignore
        middleware: this.middleware,
        events: ["dblclick", "click"],
      },
      companyRedirectMode : false,
      showCompanyNewsItem : false,
      authenticated: false,
      noResults: false,
      isMethodsOpen: false,
      basketCount: 0,
      searchCategoryMode : false,
      company: undefined as ICompany | undefined,
      products: [] as IArticle[],
      basketItems: [] as IArticle[],
      store: Store.state,
      sectorImage : "https://hips.hearstapps.com/hmg-prod/images/sourdough-bread-horizontal-half-1547669255.png" as string,
      firstUse: false,
      currentCategory: "" as string,
      paging: {
        page: 1,
        itemsPerPage: 10,
        pages: [] as number[],
        total: 0 as number,
      },
      categories: [] as string[] | null,
      searchTerm: "",
      viewMethod: "list",
      loading: false,
      orderOverlay: false,
      orderProduct: undefined as IArticle | undefined,
      imageHost: configs.ImageHost,
    };
  },
  methods: {
    displayPrice : function(product : IArticle){
      var priceDifference = false;
      var minPrice = product.pricePerUnit;
      var maxPrice = product.pricePerUnit;

      console.log('display price');
      console.log(product);
      if(product.otherProducts && product.otherProducts.length > 1){
        var productWithDifferentPrice = product.otherProducts.filter(f => f.pricePerUnit !== product.pricePerUnit);
        if(productWithDifferentPrice !== undefined){
          priceDifference = true;
          for(var i =0; i < product.otherProducts.length; i ++){
            var currPrice = product.otherProducts[i].pricePerUnit;
            if(currPrice !== undefined && minPrice !== undefined && maxPrice !== undefined){
              if(currPrice > maxPrice){
                maxPrice = product.otherProducts[i].pricePerUnit;
              }

              if(currPrice < minPrice){
                minPrice = product.otherProducts[i].pricePerUnit;
              }

            }
            

          }
        }
      }

      var htmlString = "<strong>";
      if(priceDifference){
        htmlString += "€" +  (minPrice ?? 0).toFixed(2).replace(".",",") + " Tot € " + (maxPrice ?? 0).toFixed(2).replace(".",",") + "<span>";
      }
      else{
        htmlString += "€" +  (product?.pricePerUnit ?? 0).toFixed(2).replace(".",",") + "<span>";
      }
      htmlString += "/" + this.$t(product.unitType + '_base');
      htmlString += "</span></strong>"                                                
      return htmlString;                                       
    },
    openMethods: function () {
      //this.showCompanyNewsItem = false;
      this.isMethodsOpen = !this.isMethodsOpen;
    },
    setDisplayMode(mode : any) {
       this.$root.$emit('change-display-mode', mode);
    },
    redirectShoppingCart: function () {
      this.$router.push({ name: "basket" });
    },
    showOpeningshours(company :ICompany, event : any){
        Utils.showOpeningshours(company, event, this.$swal);
      },
    openingHourList: function(){
      if(this.company !== undefined){
              return Utils.openingHourList(this.company);

      }
      return "";
    },
    deliveryHourList: function(){
      if(this.company !== undefined){
              return Utils.deliveryHourList(this.company);

      }
      return "";
    },
    resetCategorySearch : function(){
      this.searchTerm = "";
      this.currentCategory = "";
      this.noResults = false;
      this.paging.page = 1;
      this.searchCategoryMode = false;
      this.products = [];
    },
    redirectOrder : function(){
      if(this.company !== undefined){
        this.$router.push({ name: 'preplaceorder', params : { id: this.company.companyId }});
      }

    },
    getProductCountInBasket : function(product : IArticle){
      var productIds = product.otherProducts.map(f => f.productId);

      var productCount = this.basketItems.filter(function (obj) {
        return productIds.indexOf(obj.productId) !== -1;
      });

      if(productCount.length === 0){
        return 0;
      }
      else{
        var totalCount = 0;
        for(var i = 0; i < productCount.length; i++){
          totalCount += productCount[i].amount;
        }
        return totalCount;
      }
    },
    getAvailableDates: function (product: IArticle) {
      var returnString = "";
      var otherDays = false;
      if (
        product.availableDays === null ||
        product.availableDays === undefined
      ) {
        return "Niet beschikbaar op maandag, dinsdag, woensdag, donderdag, vrijdag, zaterdag, zondag";
      } 
      
     if (product.availableDays.indexOf("1") === -1) {
        returnString += "maandag";
        otherDays = true;
      } 
     if (product.availableDays.indexOf("2") === -1) {
        if (otherDays) {
          returnString += ", ";
        }
        returnString += "dinsdag";
        otherDays = true;
      }
      if (product.availableDays.indexOf("3") === -1) {
        if (otherDays) {
          returnString += ", ";
        }
        returnString += "woensdag";
        otherDays = true;
      }
      if (product.availableDays.indexOf("4") === -1) {
        if (otherDays) {
          returnString += ", ";
        }
        returnString += "donderdag";
        otherDays = true;
      } 
      if (product.availableDays.indexOf("5") === -1) {
        if (otherDays) {
          returnString += ", ";
        }
        returnString += "vrijdag";
        otherDays = true;
      }
      if (product.availableDays.indexOf("6") === -1) {
        if (otherDays) {
          returnString += ", ";
        }
        returnString += "zaterdag";
        otherDays = true;
      }
       if (product.availableDays.indexOf("0") === -1) {
        if (otherDays) {
          returnString += ", ";
        }
        returnString += "zondag";
        otherDays = true;
      }
      return returnString !== "" ? "Niet beschikbaar op " + returnString : "";
    },
    checkLocalFavorite(company: ICompany){
      console.log('checklocalfav');
      var favoriteCompanies = localStorage.getItem("favoriteCompanies");
      var companyIds = [] as string[];
      if (favoriteCompanies != null) {
        companyIds = favoriteCompanies.split(",");
      }
      var companyIndex = companyIds.indexOf(company.companyId);
      console.log(companyIndex);
      if (companyIndex === -1) {
        return false;
      } else {
        return true;
      }
    },
    toggleFavorite(company: ICompany, event: any) {
      event.preventDefault();

      if (localStorage.getItem("user") == null) {
        var favoriteCompanies = localStorage.getItem("favoriteCompanies");
        var companyIds = [] as string[];
        if (favoriteCompanies != null) {
          companyIds = favoriteCompanies.split(",").filter(f => f != "" && f != undefined);
        }
        var companyIndex = companyIds.indexOf(company.companyId);
        console.log(companyIds);
        if (companyIndex === -1) {
          companyIds.push(company.companyId);
        } else {
          companyIds.splice(companyIndex, 1);
        }
        console.log(companyIndex);
        console.log('toggle fav');
        console.log(companyIds);
        localStorage.setItem("favoriteCompanies", companyIds.join(","));

        if (company.favorite == null) {
          company.favorite = true;
        } else {
          company.favorite = !company.favorite;
        }
      } else {
        if (company.favorite == null) {
          company.favorite = true;
        } else {
          company.favorite = !company.favorite;
        }
        var self = this;
        var res = CompanyRepo.updateFavoriteStatus(
          company.companyId,
          self.store.loggedInUser.userId,
          company.favorite
        );
      }

      console.log(company);
    },
    calculatePrice: function (product: IArticle): number | undefined {
      console.log(product);
      if (product.pricePerUnit === undefined || product.pricePerUnit === null) {
        return 0;
      }  else if(product.unitType == "WEIGHTPIECE"){
          return 0
      }else if (!ProductRepo.IsSingularUnitType(product.unitType)) {
        var price = product.amount * (product.pricePerUnit / 1000);
        return Math.round(price * 100) / 100;
      } else {
        return product.amount * product.pricePerUnit;
      }   

      return 0;
    },
    calculateTotalPrice: function (): number | undefined {
      var invalidTotalPrice = false;
      var totalPrice = 0;
      for (let index = 0; index < this.basketItems.length; index++) {
        const element = this.basketItems[index];
        var currentPrice = this.calculatePrice(element);

        if (currentPrice === undefined) {
          invalidTotalPrice = true;
          break;
        }
        totalPrice += currentPrice;
      }

      if (invalidTotalPrice) {
        return undefined;
      } else {
        return totalPrice;
      }
    },
    getBasketData: async function () {
      this.loading = true;
      var res = await ProductRepo.getProductsInBasket();
      var self = this;
      console.log(res);

      //only show product from current company
      this.basketItems = res.filter(function (obj) {
        return obj.companyId === (self.company !== undefined ? self.company.companyId : "");
      });

      //paging

      if (!this.basketItems) {
        this.loading = false;
        this.noResults = true;
        this.basketCount = 0;
        return;
      } else if (this.basketItems.length == 0) {
        this.noResults = true;
        this.basketCount = 0;
      } else {
        var count = 0;
        for(var i= 0; i< this.basketItems.length; i++){
          if(ProductRepo.IsSingularUnitType(this.basketItems[i].unitType)){
            count += this.basketItems[i].amount;
          }
          else{
            count += 1;
          }
        }
        this.basketCount = count;
        
        this.noResults = false;
      }

      this.loading = false;
    },
    onClickOutside: function (event: any, element: any) {
      console.log("handle focus out");
      this.orderOverlay = false;
    },
    getProductCategories: async function () {
      this.loading = true;
      var res = await ProductRepo.getProductCategories(this.company == undefined ? ""  : this.company.companyId);

      console.log(res);
      this.categories = res;
      //paging

      if (!res) {
        this.loading = false;
        this.noResults = true;
        return;
      } else if (res.length == 0) {
        this.noResults = true;
      } else {
        this.noResults = false;
      }

      this.loading = false;
    },
    setProductCategory: async function (categoryName: string) {
      console.log("setproduct category");
      if (this.currentCategory == categoryName) {
        this.currentCategory = "";
      } else {
        var res = await ProductRepo.getProductsByCategoryName(
          categoryName,
          this.company == undefined ? ""  : this.company.companyId
        );

        this.products = res ?? [];
        //paging

        if (!res) {
          return;
        }
        this.currentCategory = categoryName;
      }
    },
    getMostOrdered: async function () {
      var self = this;
      var res = await ProductRepo.getMostOrderedProducts(
        self.company == undefined ? ""  : self.company.companyId,
        self.store.loggedInUser.userId
      );

      console.log(res);
      this.products = res?.products ?? [];
      console.log(this.products);
      //paging
      this.paging.pages = [];
      this.paging.total = res?.nbResults ?? 0;

      if (!res) {
        this.loading = false;
        this.noResults = true;
        return;
      } else if (this.paging.total == 0) {
        this.noResults = true;
      } else {
        this.noResults = false;
      }

      for (var index = 1; index <= res.pageCount; index++) {
        this.paging.pages.push(index);
      }

      this.loading = false;
    },
    previousOrder: async function () {
      var self = this;
      console.log(self.store.loggedInUser.userId);
      var res = await ProductRepo.getPreviousOrder(
        self.company == undefined ? ""  : self.company.companyId,
        self.store.loggedInUser.userId
      );

      console.log(res);
      this.products = res?.products ?? [];
      console.log(this.products);
      //paging
      this.paging.pages = [];
      this.paging.total = res?.nbResults ?? 0;

      if (!res) {
        this.loading = false;
        this.noResults = true;
        return;
      } else if (this.paging.total == 0) {
        this.noResults = true;
      } else {
        this.noResults = false;
      }

      for (var index = 1; index <= res.pageCount; index++) {
        this.paging.pages.push(index);
      }

      this.loading = false;
    },
    imageError(imageId: string) {
      var el = document.getElementById(imageId);

      el?.setAttribute("src", "/images/product-placeholder.png");
    },
    loaded() {
      console.log("loaded");
      // @ts-ignore
      $(".products .products__list .product-item .card__name").matchHeight();
      // @ts-ignore
      $(".products .products__list .product-item figure").matchHeight();
    },
    async setViewMethod(method: string, ignoreMethodsOpen: boolean) {
      console.log(method);
      this.viewMethod = method;

      if (!ignoreMethodsOpen) {
        this.isMethodsOpen = !this.isMethodsOpen;
      }

      this.orderOverlay = false;
      // basket
      if (method == "list") {
        this.products = [];
        this.paging.pages = [];
        this.paging.total = 0;
        this.searchTerm = "";
        //this.getBasketData();
      } else if (method == "search") {
        this.products = [];
        this.paging.pages = [];
        this.paging.total = 0;
      } else if (method == "most-ordered") {
        if (this.authenticated) {
          this.products = [];
          this.paging.pages = [];
          this.paging.total = 0;
          this.getMostOrdered();
        }
      } else if (method == "previous-order") {
        if (this.authenticated) {
          this.products = [];
          this.paging.pages = [];
          this.paging.total = 0;
          this.previousOrder();
        }
      } else if (method == "category-overview") {
        this.products = [];
        this.paging.pages = [];
        this.paging.total = 0;
        this.currentCategory = "";
        console.log(this.categories);
        if (this.categories == null || this.categories.length == 0) {
          console.log("product categories");
          await this.getProductCategories();
        } else {
          this.noResults = false;
        }
      }
    },
    async submitOrderProduct() {

      console.log('submit order product');
      console.log(this.orderProduct);

      //check if at least 1 amount is added
      if(this.orderProduct !== undefined){
        var self = this;

        // @ts-ignore
        this.orderProduct.companyId = self.company.companyId;
      
        var foundProduct = this.basketItems.find(f => f.productId == this.orderProduct?.productId);
        console.log(foundProduct);
        if(foundProduct === undefined){
          this.basketItems.push(this.orderProduct);

        }
        else{
          foundProduct.amount = this.orderProduct.amount;
        }

        this.$swal({
          title: "",
          icon: "success",
          text: "Product werd successvol toegevoegd " as string,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          position: "top",
            showConfirmButton: false,
          customClass: {
              container: 'confirm-order-swal',
            },
          animation: false,
        }).then((value) => {
          console.log(value);
          // if(value.dismiss && value.dismiss === Swal.DismissReason.close)
          //   self.$router.push({name: "basket"});
        });
        // else{
        //   foundProduct.amount = this.
        // }

        this.orderOverlay = false;

      }

       var count = 0;
        for(var i= 0; i< this.basketItems.length; i++){
          if(ProductRepo.IsSingularUnitType(this.basketItems[i].unitType)){
            count += this.basketItems[i].amount;
          }
          else{
            count += 1;
          }
        }
        this.basketCount = count;  
    },
    async setOrderProduct(product: IArticle) {
      //check if product is in basket
      var existingProduct = this.basketItems.find(f => f.productId == product.productId);
      console.log('existingProduct');
      console.log(existingProduct);
      console.log(product);
  
      var productConverted : IArticle = {
          originalPrice: product.originalPrice,
          categoryName: product.categoryName,
          productName: product.productName,
          unitType: product.unitType,
          pricePerUnit : product.pricePerUnit,
          productId : product.productId,
          companyId : product.companyId,
          amount :  product.amount,
          image : product.image,
          customUnitType : product.customUnitType,
          customUnitValue : product.customUnitValue,
          availableDays : product.availableDays,
          deleted : false,
          otherProducts : product.otherProducts,
          stock : product.stock,
          originalAmount: product.originalAmount,
      }

      productConverted

      this.orderProduct = productConverted;

      if(existingProduct !== undefined){
        this.orderProduct.amount = existingProduct.amount;
      }
      else{
        this.orderProduct.amount =  ProductRepo.GetQuantityStep(this.orderProduct);
      }
      
      this.orderOverlay = true;
    },
    async changeOrderProduct(product: IArticle) {
      console.log('change order product');
      //check if product is in basket
      var existingProduct = this.basketItems.find(f => f.productId == product.productId);
      console.log("existingProduct");
      console.log(existingProduct);

      var productConverted: IArticle = {
        originalPrice: product.originalPrice,
        categoryName: product.categoryName,
        productName: product.productName,
        unitType: product.unitType,
        pricePerUnit: product.pricePerUnit,
        productId: product.productId,
        companyId: product.companyId,
        amount: product.amount,
        originalAmount: product.originalAmount,
        image: product.image,
        availableDays: product.availableDays,
        customUnitType : product.customUnitType,
        customUnitValue : product.customUnitValue,
        deleted : false,
        otherProducts : this.orderProduct?.otherProducts ?? [],
        stock : product.stock
      };

      var previousAmount = this.orderProduct?.amount;
      var previousUnitType = this.orderProduct?.unitType;

      this.orderProduct = productConverted;

      console.log("previousAmount");
      console.log(previousAmount);

      if (existingProduct !== undefined) {
        this.orderProduct.amount = existingProduct.amount;
      } 
      else {
        this.orderProduct.amount =  ProductRepo.GetQuantityStep(this.orderProduct);
      }

      // const element = this.$el.querySelector(".wrapper");
      // if(element != null){
      //   element.classList.add('js-close-any');
      // }

      this.orderOverlay = true;
    },
    removeFromOverlay(){
      if(this.orderProduct !== undefined){
        // @ts-ignore
        var currentProduct = this.basketItems.find(f => f.productId == this.orderProduct.productId);

        if (currentProduct === undefined) {
          this.orderOverlay = false;
        } 
        else {
          var productIndex = this.basketItems.indexOf(currentProduct);

          if(productIndex !== -1){
            this.basketItems.splice(productIndex,1);
          }
          var count = 0;
          for(var i= 0; i< this.basketItems.length; i++){
            if(ProductRepo.IsSingularUnitType(this.basketItems[i].unitType)){
              count += this.basketItems[i].amount;
            }
            else{
              count += 1;
            }
          }
          this.basketCount = count;
          this.orderOverlay = false;
        }
      }
    },
    removeFromBasket(product: IArticle) {
      //this.store.nbProductsInBasket += this.quantity;
      var self = this;
      this.$swal({
        title: "",
        icon: "warning",
        text: "Ben je zeker dat je dit wilt verwijderen",
        timerProgressBar: true,
        showConfirmButton: true,
        showCancelButton: true,
        animation: false,
      }).then((value) => {
        if (value.value == true) {
          var productIndex = self.basketItems.indexOf(product);

          if(productIndex !== -1){
            self.basketItems.splice(productIndex,1);
          }
          var count = 0;
          for(var i= 0; i< self.basketItems.length; i++){
            if(ProductRepo.IsSingularUnitType(this.basketItems[i].unitType)){
              count += self.basketItems[i].amount;
            }
            else{
              count += 1;
            }
          }
          self.basketCount = count;
        }
        // if(value.dismiss && value.dismiss === Swal.DismissReason.close)
        //   self.$router.push({name: "basket"});
      });
      this.orderOverlay = false;
    },
    async editFromBasket(product: IArticle) {
      //this.store.nbProductsInBasket += this.quantity;
      var self = this;

      var currentProduct = this.basketItems.find(f => f.productId == product.productId);

      if (currentProduct === undefined) {
        this.setOrderProduct(product);
      } else {
        this.orderProduct = product;
        this.orderProduct.amount = currentProduct.amount;
        this.orderOverlay = true;
      }
    },
    closeOrderOverlay() {
      this.orderOverlay = false;
    },
    increaseAmount() {
      console.log(this.orderProduct !== undefined);
      
      if(this.orderProduct !== undefined){
        this.orderProduct.depleted = false
        this.orderProduct.amount += ProductRepo.GetQuantityStep(this.orderProduct);

        if(this.orderProduct.stock != null){
          this.orderProduct.amount = Math.min(this.orderProduct.stock + this.orderProduct.originalAmount, this.orderProduct.amount)
          if(this.orderProduct.amount == this.orderProduct.stock)
            this.orderProduct.depleted = true
        }
          
        //ProductRepo.addToBasket(this.orderProduct);
      }


      // @ts-ignore

      console.log(this.orderProduct.amount);
      console.log(this.orderProduct);
    },
    reduceAmount() {
      console.log('reduce amount');
      console.log(this.orderProduct);
      
      if(this.orderProduct !== undefined){
        this.orderProduct.depleted = false
        var decreaseAmount = ProductRepo.GetQuantityStep(this.orderProduct);
        
        if(decreaseAmount >= this.orderProduct.amount){
            //this.orderProduct.amount = 0;
        }
        else{
          this.orderProduct.amount = this.orderProduct.amount - decreaseAmount;
        }
        //ProductRepo.addToBasket(this.orderProduct);
         if(this.orderProduct.amount == this.orderProduct.stock)
            this.orderProduct.depleted = true  
      }
     
      console.log(this.orderProduct);
    },
    editOrder : async function() {
      var res = await CustomerRepo.editOrder(this.basketItems, this.$route.params["orderid"]);
      if(res == true){
        this.$router.push({
          name : 'orderchangecomplete',
          params: {
            id : this.$route.params["id"],
            orderid : this.$route.params["orderid"]
          }
        })
      }
      else{
        this.$swal({
          title: "",
          icon: "error",
          text: "Bestelling is al bekeken door de handelaar en kan niet meer aangepast worden.",
          showConfirmButton: true,
        });
      }
    },
    getOrderData: async function() {
      this.loading = true;
      var res = await CustomerRepo.getOrderById(this.$route.params["orderid"]);
      var self = this;
      console.log('order data');
      console.log(res);
      var count = 0;
      for (let index = 0; index < res.orderItems.length; index++) {
        const element = res.orderItems[index];
        this.basketItems.push({
          categoryName : element.product.categoryName,
          productName : element.product.productName,
          unitType : element.product.unitType,
          pricePerUnit : element.product.pricePerUnit,
          productId : element.product.productId,
          companyId : res.companyId,
          amount : element.quantity,
          originalAmount: element.quantity,
          image: element.product.image,
          stock : element.product.stock,
        } as IArticle)   
         
        if(ProductRepo.IsSingularUnitType(res.orderItems[index].unitType)){
          count += res.orderItems[index].quantity;
        }
        else{
          count += 1;
        }
        this.basketCount = count;
      }

 
      this.noResults = false;
      this.loading = false;
    },
    search: async function () {
      console.log("search");
      console.log(this.searchTerm);
      
      if(this.viewMethod == "category-overview"){
        if(this.searchTerm.length == 0){
          this.resetCategorySearch();
          return;
        }
        
        this.searchCategoryMode = true;
      }
      if (this.searchTerm.length < 1) {
        this.noResults = true;
        return [];
      }
      var self = this;
      var res = await ProductRepo.getProducts(
        this.paging.page,
        this.paging.itemsPerPage,
        this.searchTerm,
        self.company == undefined ? ""  : self.company.companyId
      );
      console.log(res);
      this.products = res?.products ?? [];
      console.log(this.products);
      //paging
      this.paging.pages = [];
      this.paging.total = res?.nbResults ?? 0;

      if (!res) {
        this.loading = false;
        this.noResults = true;
        return;
      } else if (this.paging.total == 0) {
        this.noResults = true;
      } else {
        this.noResults = false;
      }

      for (var index = 1; index <= res.pageCount; index++) {
        this.paging.pages.push(index);
      }

      this.loading = false;
    },
    openCompanyInfo: function () {
          var htmlString = '<h3>' + this.company?.companyName + '</h3>' +
                    '<div class="merchant-info"><ul><li>' + this.$t(this.company?.sector ??  "") +'</li>';
        if(this.company?.timeToPrepareDelivery){
          htmlString += '<li>Gem. verwerkingstijd: ' + this.company?.timeToPrepareDelivery  + 'min</li>'
        }
         htmlString +=  '<li><i class="fas fa-map-marker-alt"></i> ' + this.company?.street + ' ' + this.company?.number + '<br />' + this.company?.postalcode + ' ' + this.company?.city + '</li><li><i class="fas fa-mobile-alt"></i> <a href="tel:' + this.company?.phone + '">'+  this.company?.phone +'</a></li><li>'+ this.$t('vat') + ' '  + this.company?.vatNumber +'</li>' +
                    '<li><ul class="paymethods">';
                    
          if(this.company?.paymentMethods){
            if(this.company.paymentMethods.indexOf('CASH') !== -1){
              htmlString += '<li class="available"><img src="/images/euro.svg"alt="Cash" /></li>'
            }
            if(this.company.paymentMethods.indexOf('BANKCONTACT') !== -1){
              htmlString += '<li class="available"><img src="/images/debetkaart.svg" alt="Debietkaart" /></li>'
            }
            if(this.company.paymentMethods.indexOf('VISA') !== -1){
              htmlString += '<li class="available"> <img src="/images/kredietkaart.svg" alt="Kredietkaart" /></li>'
            }
            if(this.company.paymentMethods.indexOf('PAYCONIQ') !== -1){
              htmlString += '<li class="available"><img src="/images/payconiq.svg" alt="Payconic" /></li>'
            }
            if(this.company.paymentMethods.indexOf('E-CHEQUES') !== -1){
              htmlString += '<li class="available"><img src="/images/e-cheques.svg" alt="e-cheques" /></li>'
            }
            if(this.company.paymentMethods.indexOf('N-CHEQUES') !== -1){
              htmlString += '<li class="available"><img src="/images/cheques.svg"alt="Cheques" /></li>'
            }
          }
          

          

          htmlString += '</ul></li></ul></div>' +
                    '<div class="qc-logo">Powered by <a href="https://www.quickcollect.be/" target="_blanc"><img src="/images/QC-logo-color.svg" /></a></div>';

          this.$swal({
            title: '',
            html: htmlString,
            showConfirmButton: false,
            showCloseButton: true,
            customClass: {
                container: 'merchant-info-container'
            },
        }).then((value : any) => {
            console.log(value);
            // if(value.dismiss && value.dismiss === Swal.DismissReason.close)
            //   self.$router.push({name: "basket"});
        });
      },
      lineBreakToHtml(text : string){
        if(!text)
          return '';
        return text.replace(/(?:\r\n|\r|\n)/g, '<br>')
      }
  },
  async mounted() {
    console.log('MOUNTED');
    // @ts-ignore
    if(this.$session.get('companyRedirect') !== undefined){
      this.companyRedirectMode = true;
    }
    
    // @ts-ignore
    if(this.$session.get('companyDomain') !== undefined){
      this.companyRedirectMode = true;
    }

    if (localStorage.getItem("user") != null) {
      this.authenticated = true;
    }

    this.showCompanyNewsItem = true;


    this.company = await CompanyRepo.getCompanyById(this.$route.params["id"]);
  

    if (localStorage.getItem("user") == null && this.company !== undefined) {
      this.company.favorite = this.checkLocalFavorite(this.company);
    }
    //this.getProductCategories();
    this.getOrderData();
    var self = this;

    // this.$root.$on('close-overlay', function () {
    //   self.orderOverlay = false;
    // })
    this.$root.$on("close-blob-overlay", function () {
      self.isMethodsOpen = false;
    });
    if (localStorage.getItem("firstUse") === null) {
      self.firstUse = true;
      localStorage.setItem("firstUse", "1");
    }
    console.log(window.innerWidth);
    //if (window.innerWidth > 1024)
      //await this.setViewMethod("category-overview", false);

    // if(this.company?.sector != null){
    //   var sectorResponse = await CompanyRepo.getSectorImage(this.company.sector);
    //   this.sectorImage = this.imageHost + sectorResponse.ImagePath;
    // }


  },  
  watch: {
    currentPage: function () {
      //deze 'watch' traceert page changes en kan de data anders ophalen
      this.search();
    },
    "orderProduct.amount": function(){
      if(this.orderProduct?.stock)
        this.orderProduct.amount = Math.min(this.orderProduct.stock + this.orderProduct.originalAmount, this.orderProduct.amount)

      if(this.orderProduct)
        this.orderProduct.amount = Math.max(1, this.orderProduct.amount)
    }
  },
  computed: {
    maxPage: function maxPage(): number {
      //computed om max page te bepalen, wordt gebruikt in paging html
      if (this.paging.pages.length == 0) return 0;
      return this.paging.pages.reduce((a, b) => (a > b ? a : b));
    },
    currentPage: function currentPage(): number {
      //deze computed value wordt gebruikt om page changes te traceren, zie watch
      return this.paging.page;
    },
    isIndicativePrice: function (): boolean {
      return (
        (this.calculateTotalPrice() ? true : false) &&
        this.basketItems.some((b: any) => !ProductRepo.IsExactUnitType(b.unitType))
      );
    },
     getPaging : function() : number[]{
        var minPage = this.paging.page > 3 ? this.paging.page - 3: 0;
        var maxPage = this.paging.page + 4;
        return this.paging.pages.slice(minPage, maxPage)
    }
  },
});
