













































































































































































































































import Vue from "vue";
import ClickCollectApi from "../libs/ClickCollectApi";
import { ProductRepo } from "../libs/ProductRepository";
import { Store } from "../libs/Store";
import { CompanyRepo, ICompany } from "../libs/CompanyRepository";
import { configs } from "../libs/config";
import DesktopNav from "../components/shared/DesktopNav.vue";
// @ts-ignore
import Password from "vue-password-strength-meter";
import { UtilsRepo, Utils } from "../libs/Utils";
import PageHeader from "../components/shared/PageHeader.vue";
import CompanyFooter from "../components/shared/CompanyFooter.vue";

export default Vue.extend({
  name: "PreProcessOrder" as string,
  components: {
    Password,
    DesktopNav,
    PageHeader,
    CompanyFooter,
  },
  data() {
    return {
      company: undefined as ICompany | undefined,
      companyId: this.$route.params["id"],
      existingUserInput: {
        phoneNumber: "" as string,
        password: "" as string,
      },
      validateUser: {
        phoneNumber: "" as string,
        token: "" as string,
      },
      newUserInput: {
        phoneNumber: "" as string,
        email: "" as string,
        firstName: "" as string,
        lastName: "" as string,
        password: "" as string,
        confirmPassword: "" as string,
        culture: "nl-BE" as string,
        honeypot: "" as string,
        score: 0 as number,
        privacyPolicy: false as boolean,
        sendSms: false as boolean,
        sendPush: true as boolean,
        sendMail: true as boolean,
      },
      loggedInUserInput: {
        phoneNumber: Store.state.loggedInUser.phoneNumber as string,
        firstName: Store.state.loggedInUser.firstName as string,
        lastName: Store.state.loggedInUser.lastName as string,
        oldPhone: Store.state.loggedInUser.phoneNumber as string,
        email: Store.state.loggedInUser.email as string,
        sendSms: Store.state.loggedInUser.sendSms as boolean,
        sendPush: Store.state.loggedInUser.sendPush as boolean,
        sendMail: Store.state.loggedInUser.sendMail as boolean,
        sendInvoice: Store.state.loggedInUser.sendInvoice as boolean,
        remark: "",
        conditions: false,
      },
      authenticated: false,
      imageHost: configs.ImageHost,
      phoneConfig: {
        mode: "international",
        defaultCountry: "BE",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: "",
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: "username",
        name: "New-Phone",
        maxLen: 25,
        wrapperClasses: "",
        inputClasses: "form-control",
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      validPhone: false as boolean,
      viewMode:
        localStorage.getItem("user") != null
          ? "logged-in-user"
          : ("new-user" as string),
      errors: [] as string[],
      formError: [] as string[],
      store: Store.state,
      loading: false,
    };
  },
  methods: {
    updateScore(score: any) {
      this.newUserInput.score = score;
    },
    enterVerification() {
      this.validateUser.phoneNumber = this.existingUserInput.phoneNumber;
      this.errors = [];
      this.formError = [];
      this.viewMode = "validate-user";
      this.loading = false;
    },
    setDisplayMode(mode: any) {
      this.$root.$emit("change-display-mode", mode);
    },
    onInput(formattedNumber: any, number: any, valid: any, country: any) {
      console.log(formattedNumber);
      console.log(number);
      console.log(valid);
      console.log(country);
      this.validPhone = number.isValid;
    },
    async editInfo() {
      this.loading = true;
      this.errors = [];
      if (this.loggedInUserInput.phoneNumber == "") {
        this.errors.push("phoneNumber");
      }

      if (this.loggedInUserInput.firstName == "") {
        this.errors.push("firstName");
      }

      if (this.loggedInUserInput.lastName == "") {
        this.errors.push("lastName");
      }
      if (this.loggedInUserInput.email == "") {
        this.errors.push("email");
      }

      if (!this.loggedInUserInput.conditions) {
        this.errors.push("conditions");
      }

      if (this.errors.length > 0) {
        this.loading = false;
        return;
      }

      try {
        var res = await ClickCollectApi.postData("User/UpdateInfo", {
          PhoneNumber: this.loggedInUserInput.phoneNumber,
          FirstName: this.loggedInUserInput.firstName,
          LastName: this.loggedInUserInput.lastName,
          OldPhone: this.loggedInUserInput.oldPhone,
          Email: this.loggedInUserInput.email,
          SendSms: this.loggedInUserInput.sendSms,
          SendPush: this.loggedInUserInput.sendPush,
          SendMail: this.loggedInUserInput.sendMail,
        });

        var user = {
          userId: res.data.user.userId,
          phoneNumber: res.data.user.phoneNumber,
          email: res.data.user.email,
          firstName: res.data.user.firstName,
          lastName: res.data.user.lastName,
          culture: res.data.user.culture,
          sendSms: res.data.user.sendSms,
          sendMail: res.data.user.sendMail,
          sendPush: res.data.user.sendPush,
          loggedInAs: {},
        };

        this.store.loggedInUser = user;

        localStorage.setItem("user", JSON.stringify(user));

        if (this.loggedInUserInput.remark) {
          console.log("remark filled");
          localStorage.setItem("remark", this.loggedInUserInput.remark);
        } else {
          console.log("remark empty");
          localStorage.setItem("remark", "");
        }

        if (this.loggedInUserInput.sendInvoice) {
          localStorage.setItem("invoice", "true");

          this.$router.push({
            name: "facturation",
            params: {
              id: this.companyId,
            },
          });
        } else {
          localStorage.setItem("invoice", "false");

          this.$router.push({
            name: "placeorder",
            params: {
              id: this.companyId,
            },
          });
        }

        this.loading = false;
      } catch (error) {
        console.log(error);
        var errRes = error.response;
        if (errRes && errRes.status !== 200) {
          if ("" in errRes.data) {
            this.formError = errRes.data[""];
          }
        } else {
          this.formError = [this.$t("noConnection") as string];
        }
        this.loading = false;
      }
    },
    validEmail: function (email: any) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    setViewMode(method: string) {
      this.viewMode = method;
    },
    previousStep() {
      var previousUrl =
        this.$route.query.previousUrl == null
          ? "/"
          : this.$route.query.previousUrl?.toString();

      
      if (
        previousUrl == "/" &&
        // @ts-ignore
        this.$session.get("companyRedirect") !== undefined
      ) {
        // @ts-ignore
        var companySlug = this.$session.get("companyRedirect");
        previousUrl = "/" + companySlug;
      }

      this.$router.push(previousUrl);
    },
    imageError(imageId: string) {
      var el = document.getElementById(imageId);

      el?.setAttribute("src", "/images/product-placeholder.png");
    },
  },
  async mounted() {
    console.log(this.$route);
    console.log("pre process order");
    this.company = await CompanyRepo.getCompanyById(this.$route.params["id"]);
    if (Utils.isIos()) {
      this.newUserInput.sendPush = false;
    }

    if (localStorage.getItem("user") != null) {
      this.authenticated = true;
    }
  },
});
