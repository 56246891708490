import axios, { AxiosResponse } from 'axios'
import { configs } from './config'
import { Store } from './Store';
import { Vm } from '@/main';
import { CustomerRepo } from './CustomerRepository';

export default class ClickCollectApi {
  static baseUrl: string = configs.ApiUrl;

  public static async postData(endPoint: string, data: any): Promise<AxiosResponse<any>> {
    try {
      const res = await axios.post(this.baseUrl + endPoint, data, {
        //AxiosRequestConfig parameter
        withCredentials: true,
        headers: {
          "accept-language": Store.state.culture + "-BE",
          "Pragma": 'no-cache',
          'Cache-Control': 'no-cache'
        }
      });

      return res;
    } catch (error) {
      console.log(error.response);
      if (error.response.status == 405 || error.response.status == 401 ) {
        Store.state.loggedInUser = {};
        localStorage.removeItem("user");
        await CustomerRepo.clearPersonalData();   
        Vm.$router.push({ name: "login" });
      } else {
        throw (error);
      }
    }

    return null as unknown as AxiosResponse<any>;
  }

  public static async postWithoutBaseData(endPoint: string, data: any): Promise<AxiosResponse<any>> {
    try {
      const res = await axios.post(endPoint, data, {
        //AxiosRequestConfig parameter
        withCredentials: false,
        headers: {
          "accept-language": Store.state.culture + "-BE",
          "Pragma": 'no-cache',
          'Cache-Control': 'no-cache'
        }
      });

      return res;
    } catch (error) {
      console.log(error.response);
     
    }

    return null as unknown as AxiosResponse<any>;
  }
  public static async getWithoutBaseData(endPoint: string): Promise<AxiosResponse<any>> {

    try {
      const res = await axios.get(endPoint, {
        headers: {
          "accept-language": Store.state.culture + "-BE",
          "Pragma": 'no-cache',
          'Cache-Control': 'no-cache',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        }
      });
      return res;
    } catch (error) {
      console.log(error.response);
      
    }

    return null as unknown as AxiosResponse<any>;
  }

  public static async getData(endPoint: string): Promise<AxiosResponse<any>> {

    try {
      const res = await axios.get(this.baseUrl + endPoint, {
        //AxiosRequestConfig parameter
        withCredentials: true,
        headers: {
          "accept-language": Store.state.culture + "-BE",
          "Pragma": 'no-cache',
          'Cache-Control': 'no-cache'
        }
      });
      return res;
    } catch (error) {
      console.log(error.response);
      if (error.response.status == 405 || error.response.status == 401) {
        Vm.$router.push({ name: "login" });
      } else {
        throw (error);
      }
    }

    return null as unknown as AxiosResponse<any>;
  }
}