






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CompanyRepo, ICompany } from "../libs/CompanyRepository";
import { ProductRepo } from "../libs/ProductRepository";
import Swal from "sweetalert2";
import { configs } from "../libs/config";
import PinchZoom from "vue-pinch-zoom";
Vue.component("pinch-zoom", PinchZoom);

// @ts-ignore
import Autocomplete from "@trevoreyre/autocomplete-vue";
import { UtilsRepo, Utils } from "../libs/Utils";

// @ts-ignore
import imagesLoaded from "vue-images-loaded";
import { IArticle } from "../libs/QuickCollectDatabase";
import { Store } from "../libs/Store";

import DesktopNav from "../components/shared/DesktopNav.vue";

//@ts-ignore
import ExpandableImage from "../components/shared/ExpandableImage.vue";

import CompanyFooter from "../components/shared/CompanyFooter.vue";
import BuyWidget from "./shared/BuyWidget.vue";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
// @ts-ignore
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import FeaturedProducts from "./shared/FeaturedProducts.vue";
import { ContentPageRepo, IContentPage } from "@/libs/ContentPageRepository";
// @ts-ignore
import VideoEmbed from "../components/shared/VideoEmbed.vue";

export default Vue.extend({
  name: "Company" as string,
  components: {
    DesktopNav,
    ExpandableImage,
    CompanyFooter,
    BuyWidget,
    VueSlickCarousel,
    FeaturedProducts,
    VideoEmbed,
  },
  directives: {
    imagesLoaded,
  },
  data() {
    return {
      socialMedia: {
        title: "",
        siteName: "",
        siteUrl: "",
        siteImage: "",
      },
      companyRedirectMode: false,
      showCompanyNewsItem: false,
      authenticated: false,
      noResults: false,
      isMethodsOpen: false,
      basketCount: 0,
      searchCategoryMode: false,
      company: undefined as ICompany | undefined,
      forceSmallOverlay: false,
      products: [] as IArticle[],
      basketItems: [] as IArticle[],
      store: Store.state,
      companyLoaded: false,
      sectorImage:
        "https://hips.hearstapps.com/hmg-prod/images/sourdough-bread-horizontal-half-1547669255.png" as string,
      firstUse: false,
      paging: {
        page: 1,
        itemsPerPage: 10,
        pages: [] as number[],
        total: 0 as number,
      },
      categories: [] as any[] | null,
      currentCategoryId: "" as string,
      subCategories: [] as any[] | null,
      currentSubCategoryId: "" as string,
      subProducts: [] as IArticle[],
      searchTerm: "",
      viewMethod: "category-overview",
      loading: false,
      orderOverlay: false,
      orderProduct: undefined as IArticle | undefined,
      imageHost: configs.ImageHost,
      showTilesFilter: false,
      homeContentPage: null as IContentPage | null,
      clickedTabs: false,
      acceptAgeError: false,
      showAgeWarning: false,
    };
  },
  methods: {
    displayPrice: function (product: IArticle) {
      var priceDifference = false;
      var minPrice = product.pricePerUnit;
      var maxPrice = product.pricePerUnit;

      console.log("display price");
      console.log(product);
      var originalPrice = product.originalPrice;
      if (product.otherProducts && product.otherProducts.length > 1) {
        var productWithDifferentPrice = product.otherProducts.filter(
          (f) => f.pricePerUnit !== product.pricePerUnit
        );
        if (productWithDifferentPrice !== undefined) {
          priceDifference = true;
          for (var i = 0; i < product.otherProducts.length; i++) {
            var currPrice = product.otherProducts[i].pricePerUnit;
            if (
              currPrice !== undefined &&
              minPrice !== undefined &&
              maxPrice !== undefined
            ) {
              if (currPrice > maxPrice) {
                maxPrice = product.otherProducts[i].pricePerUnit;
              }

              if (currPrice < minPrice) {
                minPrice = product.otherProducts[i].pricePerUnit;
              }

              // take the most expensive original price
              if (product?.otherProducts[i].originalPrice)
                if (
                  !originalPrice ||
                  (product?.otherProducts[i]?.originalPrice ?? -1) >
                    originalPrice
                )
                  //@ts-ignore
                  originalPrice = product?.otherProducts[i]?.originalPrice;
            }
          }

          if (minPrice === maxPrice) priceDifference = false;
        }
      }

      var htmlString =
        "<strong " +
        (product.originalPrice ? "class='discounted-price'" : "") +
        ">";
      if (priceDifference) {
        htmlString +=
          "€" +
          (minPrice ?? 0).toFixed(2).replace(".", ",") +
          " " +
          this.$t("To") +
          " €" +
          (maxPrice ?? 0).toFixed(2).replace(".", ",") +
          "<span>";
      } else {
        htmlString +=
          "€" +
          (product?.pricePerUnit ?? 0).toFixed(2).replace(".", ",") +
          "<span>";
      }
      htmlString += "/" + this.$t(product.unitType + "_base");

      htmlString += "</span></strong>";
      if (product.originalPrice) {
        htmlString +=
          "<span class='original-price'>€" +
          (product?.originalPrice ?? 0).toFixed(2).replace(".", ",") +
          "<span>";
        htmlString += "/" + this.$t(product.unitType + "_base");
        htmlString += "</span></span>";
      }

      return htmlString;
    },
    openMethods: function () {
      //this.showCompanyNewsItem = false;
      this.isMethodsOpen = !this.isMethodsOpen;
    },
    redirectShoppingCart: function () {
      this.$router.push({ name: "basket" });
    },
    showOpeningshours(company: ICompany, event: any) {
      Utils.showOpeningshours(company, event, this.$swal);
    },
    setDisplayMode(mode: any) {
      this.$root.$emit("change-display-mode", mode);
    },
    resetCategorySearch: function () {
      this.searchTerm = "";
      this.currentCategoryId = "";
      this.noResults = false;
      this.paging.page = 1;
      this.searchCategoryMode = false;
      this.products = [];
    },
    redirectOrder: function () {
      //@ts-ignore
      window.orderMode = true;
      if (this.company !== undefined) {
        this.$router.push({
          name: "preplaceorder",
          params: { id: this.company.companyId },
        });
      }
    },
    getProductCountInBasket: function (product: IArticle) {
      var productIds = product.otherProducts.map((f) => f.productId);

      var productCount = this.basketItems.filter(function (obj) {
        return productIds.indexOf(obj.productId) !== -1;
      });

      if (productCount.length === 0) {
        return 0;
      } else {
        var totalCount = 0;
        for (var i = 0; i < productCount.length; i++) {
          totalCount += productCount[i].amount;
        }
        return totalCount;
      }
    },
    getAvailableDates: function (product: IArticle) {
      var returnString = "";
      var otherDays = false;
      if (
        product.availableDays === null ||
        product.availableDays === undefined
      ) {
        return this.$t("ProductUnavailable");
      }

      if (product.availableDays.indexOf("1") === -1) {
        returnString += this.$t('Monday');
        otherDays = true;
      }
      if (product.availableDays.indexOf("2") === -1) {
        if (otherDays) {
          returnString += ", ";
        }
        returnString += this.$t('Tuesday');
        otherDays = true;
      }
      if (product.availableDays.indexOf("3") === -1) {
        if (otherDays) {
          returnString += ", ";
        }
        returnString += this.$t('Wednesday');
        otherDays = true;
      }
      if (product.availableDays.indexOf("4") === -1) {
        if (otherDays) {
          returnString += ", ";
        }
        returnString += this.$t('Thursday');
        otherDays = true;
      }
      if (product.availableDays.indexOf("5") === -1) {
        if (otherDays) {
          returnString += ", ";
        }
        returnString += this.$t('Friday');
        otherDays = true;
      }
      if (product.availableDays.indexOf("6") === -1) {
        if (otherDays) {
          returnString += ", ";
        }
        returnString += this.$t('Saturday');
        otherDays = true;
      }
      if (product.availableDays.indexOf("0") === -1) {
        if (otherDays) {
          returnString += ", ";
        }
        returnString += this.$t('Sunday');
        otherDays = true;
      }
      return returnString !== "" ? this.$t('UnavailableOn') + " " + returnString : "";
    },
    checkLocalFavorite(company: ICompany) {
      console.log("checklocalfav");
      var favoriteCompanies = localStorage.getItem("favoriteCompanies");
      var companyIds = [] as string[];
      if (favoriteCompanies != null) {
        companyIds = favoriteCompanies.split(",");
      }
      var companyIndex = companyIds.indexOf(company.companyId);
      console.log(companyIndex);
      if (companyIndex === -1) {
        return false;
      } else {
        return true;
      }
    },
    toggleFavorite(company: ICompany, event: any) {
      console.log("toggle favorite");
      event.preventDefault();

      if (localStorage.getItem("user") == null) {
        var favoriteCompanies = localStorage.getItem("favoriteCompanies");
        var companyIds = [] as string[];
        if (favoriteCompanies != null) {
          companyIds = favoriteCompanies
            .split(",")
            .filter((f) => f != "" && f != undefined);
        }
        var companyIndex = companyIds.indexOf(company.companyId);
        console.log(companyIds);
        if (companyIndex === -1) {
          companyIds.push(company.companyId);
        } else {
          companyIds.splice(companyIndex, 1);
        }
        console.log(companyIndex);
        console.log("toggle fav");
        console.log(companyIds);
        localStorage.setItem("favoriteCompanies", companyIds.join(","));

        if (company.favorite == null) {
          company.favorite = true;
        } else {
          company.favorite = !company.favorite;
        }
      } else {
        if (company.favorite == null) {
          company.favorite = true;
        } else {
          company.favorite = !company.favorite;
        }
        var self = this;
        var res = CompanyRepo.updateFavoriteStatus(
          company.companyId,
          self.store.loggedInUser.userId,
          company.favorite
        );
      }

      console.log(company);
    },
    calculatePrice: function (product: IArticle): number {
      console.log(product);
      var price = 0;

      if (product.pricePerUnit === undefined || product.pricePerUnit === null) {
        price = 0;
      } else {
        if (!ProductRepo.IsSingularUnitType(product.unitType)) {
          price = product.amount * (product.pricePerUnit / 1000);
          price = Math.round(price * 100) / 100;
        } else if (product.unitType == "WEIGHTPIECE") {
          price = 0;
        } else {
          price = product.amount * product.pricePerUnit;
        }
        console.log("unitPrice", product.pricePerUnit)
      }

      for (var subProduct of product.childProducts ?? []) {
        price += this.calculatePrice(subProduct);
      }

      return price ?? 0;
    },
    calculateTotalPrice: function (): number | undefined {
      var invalidTotalPrice = false;
      var totalPrice = 0;
      for (let index = 0; index < this.basketItems.length; index++) {
        const element = this.basketItems[index];
        var currentPrice = this.calculatePrice(element);

        if (currentPrice === undefined) {
          invalidTotalPrice = true;
          break;
        }
        totalPrice += currentPrice;
      }

      totalPrice += this.totalDeposit;

      if (invalidTotalPrice) {
        return undefined;
      } else {
        return totalPrice;
      }
    },
    getBasketData: async function () {
      this.loading = true;
      var res = await ProductRepo.getProductsInBasket();
      var self = this;
      console.log(res);

      //only show product from current company
      this.basketItems = res.filter(function (obj) {
        return (
          obj.companyId ===
          (self.company !== undefined ? self.company.companyId : "")
        );
      });

      //paging

      if (!this.basketItems) {
        this.loading = false;
        this.noResults = true;
        this.basketCount = 0;
        return;
      } else if (this.basketItems.length == 0) {
        this.noResults = true;
        this.basketCount = 0;
      } else {
        var count = 0;
        for (var i = 0; i < this.basketItems.length; i++) {
          if (ProductRepo.IsSingularUnitType(this.basketItems[i].unitType)) {
            count += this.basketItems[i].amount;
          } else {
            count += 1;
          }
        }
        this.basketCount = count;
        this.noResults = false;
      }

      this.loading = false;
    },
    getProductCategories: async function () {
      this.loading = true;
      var res = await ProductRepo.getProductCategories(
        this.company == undefined ? "" : this.company.companyId
      );

      console.log(res);
      this.categories = res ?? [];
      if (this.$route.params.categoryId) {
        console.log("catId", this.$route.params.categoryId);
        var cat = this.categories.find(
          (c) => c.categoryId == this.$route.params.categoryId
        );

        if (cat) this.setProductCategory(cat.id);
        else
          this.$router.replace(
            this.$route.path
              .substring(0, this.$route.path.indexOf("/cat"))
              .replace(/\/?$/, "/")
          );
      } else if (this.categories.length == 1) {
        console.log("current cat");
        console.log(this.currentCategoryId);
        //@ts-ignore
        this.setProductCategory(this.categories[0].id);
      }

      //paging

      if (!res) {
        this.loading = false;
        this.noResults = true;
        return;
      } else if (res.length == 0) {
        this.noResults = true;
      } else {
        this.noResults = false;
      }

      this.loading = false;
    },
    setProductCategory: async function (categoryId: string) {
      console.log("setproduct category");
      this.currentSubCategoryId = "";
      this.showTilesFilter = false;
      var cat = this.categories?.find((c) => c.id == categoryId);
      this.clickedTabs = true;

      //window.location.hash =  categoryId;
      //window.history.pushState(null, "", window.location.href + "?catId=" + categoryId);
      if (this.company?.enableCategoryTileView) {
        var newPath =
          this.$route.path.indexOf("/cat") > -1
            ? this.$route.path
                .substring(0, this.$route.path.indexOf("/cat"))
                .replace(/\/?$/, "/")
            : this.$route.path;

        this.$router.push(
          newPath +
            "/cat/" +
            cat?.categoryId +
            "/" +
            cat?.slug +
            (this.$route.query.k ? "?k=" + this.$route.query.k : "")
        );
      }

      //window.location.hash =  categoryId;
      if (
        this.currentCategoryId == categoryId &&
        !this.company?.enableCategoryTileView
      ) {
        this.currentCategoryId = "";
      } else {
        var res = await ProductRepo.getProductsByCategoryName(
          categoryId,
          this.company == undefined ? "" : this.company.companyId,
          this.company?.enableCategoryTileView ?? false
        );

        this.products = res?.products ?? [];
        this.subCategories = res?.subCategories ?? [];
        //paging

        if (!res) {
          return;
        }
        this.currentCategoryId = categoryId;
      }
    },
    setProductSubCategory: async function (categoryId: string) {
      console.log("setproduct subcategory");
      this.showTilesFilter = false;
      this.clickedTabs = true;
      if (
        this.currentSubCategoryId == categoryId &&
        !this.company?.enableCategoryTileView
      ) {
        this.currentSubCategoryId = "";
      } else {
        var res = await ProductRepo.getProductsByCategoryName(
          categoryId,
          this.company == undefined ? "" : this.company.companyId,
          false
        );

        // in tile view the product list should be replaced by the subcat products
        if (this.company?.enableCategoryTileView)
          this.products = res?.products ?? [];
        else this.subProducts = res?.products ?? [];
        //this.subCategories = res?.subCategories ?? [];
        //paging

        if (!res) {
          return;
        }
        this.currentSubCategoryId = categoryId;
      }
    },
    getMostOrdered: async function () {
      var self = this;
      var res = await ProductRepo.getMostOrderedProducts(
        self.company == undefined ? "" : self.company.companyId,
        self.store.loggedInUser.userId
      );

      console.log(res);
      this.products = res?.products ?? [];
      console.log(this.products);
      //paging
      this.paging.pages = [];
      this.paging.total = res?.nbResults ?? 0;

      if (!res) {
        this.loading = false;
        this.noResults = true;
        return;
      } else if (this.paging.total == 0) {
        this.noResults = true;
      } else {
        this.noResults = false;
      }

      for (var index = 1; index <= res.pageCount; index++) {
        this.paging.pages.push(index);
      }

      this.loading = false;
    },
    previousOrder: async function () {
      var self = this;
      console.log(self.store.loggedInUser.userId);
      var res = await ProductRepo.getPreviousOrder(
        self.company == undefined ? "" : self.company.companyId,
        self.store.loggedInUser.userId
      );

      console.log(res);
      this.products = res?.products ?? [];
      console.log(this.products);
      //paging
      this.paging.pages = [];
      this.paging.total = res?.nbResults ?? 0;

      if (!res) {
        this.loading = false;
        this.noResults = true;
        return;
      } else if (this.paging.total == 0) {
        this.noResults = true;
      } else {
        this.noResults = false;
      }

      for (var index = 1; index <= res.pageCount; index++) {
        this.paging.pages.push(index);
      }

      this.loading = false;
    },
    imageError(imageId: string) {
      var el = document.getElementById(imageId);

      el?.setAttribute("src", "/images/product-placeholder.png");
    },
    loaded() {
      console.log("loaded");
      // @ts-ignore
      $(".products .products__list .product-item .card__name").matchHeight();
      // @ts-ignore
      $(".products .products__list .product-item figure").matchHeight();
    },
    async setViewMethod(method: string, ignoreMethodsOpen: boolean) {
      this.clickedTabs = true;
      console.log(method);
      this.viewMethod = method;

      if (!ignoreMethodsOpen) {
        this.isMethodsOpen = !this.isMethodsOpen;
      }
      // strip of cat url
      var newPath =
        this.$route.path.indexOf("/cat") > -1
          ? this.$route.path
              .substring(0, this.$route.path.indexOf("/cat"))
              .replace(/\/?$/, "/")
          : this.$route.path;
      this.$router.replace(newPath);

      this.orderOverlay = false;
      // basket
      if (method == "list") {
        this.products = [];
        this.paging.pages = [];
        this.paging.total = 0;
        this.searchTerm = "";
        this.getBasketData();
      } else if (method == "search") {
        this.products = [];
        this.paging.pages = [];
        this.paging.total = 0;
      } else if (method == "most-ordered") {
        if (this.authenticated) {
          this.products = [];
          this.paging.pages = [];
          this.paging.total = 0;
          this.getMostOrdered();
        }
      } else if (method == "previous-order") {
        if (this.authenticated) {
          this.products = [];
          this.paging.pages = [];
          this.paging.total = 0;
          this.previousOrder();
        }
      } else if (method == "category-overview") {
        this.products = [];
        this.paging.pages = [];
        this.paging.total = 0;
        this.currentCategoryId = "";
        this.resetCategorySearch();
        console.log(this.categories);
        if (this.categories == null || this.categories.length == 0) {
          console.log("product categories");
          await this.getProductCategories();
        } else {
          this.noResults = false;
        }
      }
    },
    async setOrderProduct(
      product: IArticle,
      isNew?: boolean,
      forcesSmallOverlay?: boolean
    ) {
      //check if product is in basket
      var existingProduct = await ProductRepo.getFromBasket(product);
      console.log("existingProduct");
      console.log(existingProduct);

      var productConverted: IArticle = {
        categoryName: product.categoryName,
        productName: product.productName,
        unitType: product.unitType,
        originalPrice: product.originalPrice,
        stock: product.stock,
        pricePerUnit: product.pricePerUnit,
        productId: product.productId,
        companyId: product.companyId,
        amount: product.amount,
        originalAmount: product.amount,
        image: product.image,
        availableDays: product.availableDays,
        customUnitType: product.customUnitType,
        customUnitValue: product.customUnitValue,
        customUnitType2: product.customUnitType2,
        customUnitValue2: product.customUnitValue2,
        customUnitType3: product.customUnitType3,
        customUnitValue3: product.customUnitValue3,
        deleted: false,
        otherProducts: product.otherProducts,
        childProducts: product.childProducts,
        infoText: product.infoText,
        editMode: !isNew,
        orderItemId: product.orderItemId,
        slug: product.slug,
        productDescription: product.productDescription,
        orderItemRemarkText: product.orderItemRemarkText,
        remark: product.remark,
        enableOrderItemRemarks: product.enableOrderItemRemarks,
        media: product.media,
        deposit: product.deposit,
      };

      this.orderProduct = productConverted;

      if (existingProduct !== undefined && !isNew) {
        this.orderProduct.amount = existingProduct.quantity;
        // set the correct quantities for the child products
        for (var subProduct of this.orderProduct?.childProducts ?? []) {
          var quantity = existingProduct.subArticles.find(
            (a) => a.productId == subProduct.productId
          );

          subProduct.amount = quantity?.quantity ?? 0;
        }
      } else {
        this.orderProduct.amount = ProductRepo.GetQuantityStep(
          this.orderProduct
        );
      }

      // const element = this.$el.querySelector(".wrapper");
      // if(element != null){
      //   element.classList.add('js-close-any');
      // }
      if (forcesSmallOverlay) this.forceSmallOverlay = true;

      this.orderOverlay = true;
    },
    onRemoveFromOverlay() {
      if (this.orderProduct !== undefined) {
        // @ts-ignore
        var currentProduct = this.basketItems.find(
          (f) => f.productId == this.orderProduct?.productId
        );

        if (currentProduct === undefined) {
          this.orderOverlay = false;
        } else {
          ProductRepo.removeFromBasket(currentProduct);
          var productIndex = this.basketItems.indexOf(currentProduct);

          if (productIndex !== -1) {
            this.basketItems.splice(productIndex, 1);
          }
          var count = 0;
          for (var i = 0; i < this.basketItems.length; i++) {
            if (ProductRepo.IsSingularUnitType(this.basketItems[i].unitType)) {
              count += this.basketItems[i].amount;
            } else {
              count += 1;
            }
          }
          this.basketCount = count;
          this.orderOverlay = false;
        }
      }
    },
    removeFromBasket(product: IArticle) {
      //this.store.nbProductsInBasket += this.quantity;
      var self = this;
      this.$swal({
        title: "",
        icon: "warning",
        text: this.$t("SureDelete").toString(),
        timerProgressBar: true,
        showConfirmButton: true,
        showCancelButton: true,
        animation: false,
        cancelButtonText: this.$t("Cancel").toString(),
      }).then((value) => {
        if (value.value == true) {
          ProductRepo.removeFromBasket(product);
          var productIndex = self.basketItems.indexOf(product);

          if (productIndex !== -1) {
            self.basketItems.splice(productIndex, 1);
          }
          var count = 0;
          for (var i = 0; i < self.basketItems.length; i++) {
            if (ProductRepo.IsSingularUnitType(this.basketItems[i].unitType)) {
              count += self.basketItems[i].amount;
            } else {
              count += 1;
            }
          }
          self.basketCount = count;
        }
        // if(value.dismiss && value.dismiss === Swal.DismissReason.close)
        //   self.$router.push({name: "basket"});
      });
      this.orderOverlay = false;
    },
    async editFromBasket(product: IArticle) {
      //this.store.nbProductsInBasket += this.quantity;
      var self = this;

      var currentProduct = await ProductRepo.getFromBasket(product);

      if (currentProduct === undefined) {
        this.setOrderProduct(product);
      } else {
        this.orderProduct = product;
        this.orderProduct.amount = currentProduct.quantity;
        this.forceSmallOverlay = true;
        this.orderOverlay = true;
      }
    },
    onCloseOrderOverlay() {
      this.orderOverlay = false;
      this.forceSmallOverlay = false;
      this.getBasketData();
    },
    setCompany: function (company: any) {
      this.company = company;
    },
    search: async function () {
      console.log("search");
      console.log(this.searchTerm);

      if (this.viewMethod == "category-overview") {
        if (this.searchTerm.length == 0) {
          this.resetCategorySearch();
          return;
        }

        this.searchCategoryMode = true;
      }
      if (this.searchTerm.length < 1) {
        this.noResults = true;
        return [];
      }
      var self = this;
      var res = await ProductRepo.getProducts(
        this.paging.page,
        this.paging.itemsPerPage,
        this.searchTerm,
        self.company == undefined ? "" : self.company.companyId
      );
      console.log(res);
      this.products = res?.products ?? [];
      console.log(this.products);
      //paging
      this.paging.pages = [];
      this.paging.total = res?.nbResults ?? 0;

      if (!res) {
        this.loading = false;
        this.noResults = true;
        return;
      } else if (this.paging.total == 0) {
        this.noResults = true;
      } else {
        this.noResults = false;
      }

      for (var index = 1; index <= res.pageCount; index++) {
        this.paging.pages.push(index);
      }

      this.loading = false;
    },
    lineBreakToHtml(text: string) {
      if (!text) return "";
      return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
    },
    async onProductOrdered() {
      /*var result = await this.$swal({
        text: "Product succesvol toegevoegd aan het winkelmandje.",
        showConfirmButton: true,
        confirmButtonText: "Verder winkelen",
        showCancelButton: true,
        icon: "success",
        cancelButtonText: "Naar winkelmandje",
        customClass: { popup: "product-ordered" },
      });

      // @ts-ignore
      if (result.dismiss == "cancel")
        this.viewMethod = "list"; */
    },
    hideCompanyNewsItem() {
      this.showCompanyNewsItem = false;
      localStorage.setItem(
        "newsItemClosed" + this.company?.companyId,
        new Date().toISOString()
      );
    },
    acceptAgeWarning() {
      localStorage.setItem(
        "ageWarningAccepted_" + this.company?.companyId,
        "true"
      );
      this.showAgeWarning = false;
    },
  },
  created() {
    console.log("At this point, events and lifecycle have been initialized.");
    console.log(this.company);
  },
  // async beforeRouteEnter (to, from, next){
  //   console.log('before route enter');
  //   if (to.name == "companyRedirect") {

  //     var company = await Promise.resolve(CompanyRepo.getCompanyBySlug(to.path));

  //   } else {
  //     // @ts-ignore
  //     var company = await Promise.resolve(CompanyRepo.getCompanyBySlug(to.path.replace("/d", "")));
  //   }

  //   // @ts-ignore
  //   next(vm => vm.setCompany(company));
  // },
  async beforeMount() {
    console.log("before mount");
    console.log(this.company);
    window.onpopstate = (event: PopStateEvent) => {
      this.$router.push(this.$route.path);
    };
  },
  async mounted() {
    console.log("mounted");
    console.log(this.company);
    if (this.$route.name == "companyRedirect") {
      this.company = await Promise.resolve(
        CompanyRepo.getCompanyBySlug(this.$route.path)
      );
    } else if (
      this.$route.path == "/" ||
      this.$route.name?.includes("CustomDomain")
    ) {
      this.company = await Promise.resolve(
        CompanyRepo.getCompanyByDomain(window.location.hostname)
      );
    } else {
      // @ts-ignore
      this.company = await Promise.resolve(
        CompanyRepo.getCompanyBySlug(this.$route.params.slug)
      );
    }

    this.companyLoaded = true;

    //rebuilding less
    this.store.currentCompany = this.company;
    console.log(this.store);
    if (this.store.currentCompany !== undefined) {
      //add custom variables
      if (
        this.store.currentCompany.primaryColor !== undefined &&
        this.store.currentCompany.primaryColor != null
      ) {
        try {
          setTimeout(
            () =>
              // @ts-ignore
              less.modifyVars({
                "primary-color": this.store.currentCompany.primaryColor,
                "secondary-color": this.store.currentCompany.secondaryColor,
                "highlight-color": this.store.currentCompany.buttonColor,
              }),
            500
          );
        } catch (e) {}
      }
    }

    if (this.company !== undefined) {
      this.socialMedia.title = "Bestel online | " + this.company.companyName;
      this.socialMedia.siteName = this.company.companyName;
      this.socialMedia.siteUrl = window.location.href;
      this.socialMedia.siteImage = this.imageHost + this.company.logo;
    }

    // @ts-ignore
    if (this.$route.name == "companyRedirect") {
      this.companyRedirectMode = true;
      if (this.company == undefined) {
        this.$router.push({ name: "home" });
      }

      // @ts-ignore
      this.$session.set("companyRedirect", this.$route.path);
    } else if (this.$route.path == "/") {
      this.companyRedirectMode = true;
      var url = window.location.href;

      var arr = url.split("/");

      var result = arr[0] + "//" + arr[2];

      // @ts-ignore
      this.$session.set("companyDomain", result);
    } else {
      // @ts-ignore
      this.$session.remove("companyRedirect");
    }

    if (localStorage.getItem("user") != null) {
      this.authenticated = true;
    }

    var newsItemClosedDate = localStorage.getItem(
      "newsItemClosed" + this.company?.companyId
    );
    var now = new Date();
    now.setHours(0, 0, 0, 0);
    this.showCompanyNewsItem =
      !newsItemClosedDate || new Date(newsItemClosedDate) < now;

    if (localStorage.getItem("user") == null && this.company !== undefined) {
      this.company.favorite = this.checkLocalFavorite(this.company);
    }
    this.getProductCategories();
    this.getBasketData();
    var self = this;

    // this.$root.$on('close-overlay', function () {
    //   self.orderOverlay = false;
    // })
    this.$root.$on("close-blob-overlay", function () {
      self.isMethodsOpen = false;
    });

    if (localStorage.getItem("firstUse") === null) {
      self.firstUse = true;
      localStorage.setItem("firstUse", "1");
    }
    console.log(window.innerWidth);
    //if (window.innerWidth > 1024)
    //await this.setViewMethod("category-overview", false);

    // if(this.company?.sector != null){
    //   var sectorResponse = await CompanyRepo.getSectorImage(this.company.sector);
    //   this.sectorImage = this.imageHost + sectorResponse.ImagePath;
    // }
    console.log("route params");
    console.log(this.$route);
    // @ts-ignore
    if (
      this.$route.params.openShoppingCart ||
      // @ts-ignore
      this.$session.get("reorder") !== undefined ||
      this.$route.hash === "#basket"
    ) {
      this.viewMethod = "list";
      this.clickedTabs = true;
      // @ts-ignore
      this.$session.remove("reorder");
    }

    if (this.$route.hash === "#products") {
      console.log("trololol");
      this.viewMethod = "category-overview";
      this.clickedTabs = true;
      // @ts-ignore
      this.$session.remove("reorder");
    }
    if (this.company)
      this.homeContentPage = await ContentPageRepo.getContentPage(
        this.company.companyId,
        "Home"
      );

    if (
      !localStorage.getItem("ageWarningAccepted_" + this.company?.companyId)
    ) {
      this.showAgeWarning = true;
    }
  },
  watch: {
    currentPage: function () {
      //deze 'watch' traceert page changes en kan de data anders ophalen
      this.search();
    },
    "orderProduct.amount": function () {
      if (this.orderProduct?.stock)
        this.orderProduct.amount = Math.min(
          this.orderProduct.stock,
          this.orderProduct.amount
        );

      if (this.orderProduct)
        this.orderProduct.amount = Math.max(1, this.orderProduct.amount);
    },
    "$route.query.catId"() {
      this.currentCategoryId = this.$route.query.catId as string;
    },
  },
  computed: {
    maxPage: function maxPage(): number {
      //computed om max page te bepalen, wordt gebruikt in paging html
      if (this.paging.pages.length == 0) return 0;
      return this.paging.pages.reduce((a, b) => (a > b ? a : b));
    },
    currentPage: function currentPage(): number {
      //deze computed value wordt gebruikt om page changes te traceren, zie watch
      return this.paging.page;
    },
    isIndicativePrice: function (): boolean {
      return this.basketItems.some(
        (b: any) => !ProductRepo.IsExactUnitType(b.unitType)
      );
    },
    getPaging: function (): number[] {
      var minPage = this.paging.page > 3 ? this.paging.page - 3 : 0;
      var maxPage = this.paging.page + 4;
      return this.paging.pages.slice(minPage, maxPage);
    },
    currentCategoryTitle: function (): string {
      var title = this.categories?.find(
        (c) => c.id == this.currentCategoryId
      )?.name;

      if (this.currentSubCategoryId) {
        title +=
          " - " +
          this.subCategories?.find((c) => c.id == this.currentSubCategoryId)
            ?.name;
      }
      return title;
    },
    totalDeposit: function (): number {
      var totalPrice = 0;
      for (var item of this.basketItems) {
        if (!ProductRepo.IsSingularUnitType(item.unitType)) {
          totalPrice += item.deposit ?? 0;
        } else {
          totalPrice += item.amount * (item.deposit ?? 0);
        }
      }

      return totalPrice;
    },
  },
  metaInfo(this: any) {
    if (this.company)
      return {
        title:
          (this.company.seoTitle ?? this.$t("Start")) +
          " | " +
          this.company.companyName,
        link: [
          {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: "456456456456",
          },
        ],
        meta: [
          {
            vmid: "site_name",
            property: "og:site_name",
            content: this.socialMedia.siteName,
          },
          {
            vmid: "site_url",
            property: "og:url",
            content: this.socialMedia.siteUrl,
          },
          {
            vmid: "site_image",
            property: "og:image",
            content: this.socialMedia.siteImage,
          },
        ],
      };
    else return {};
  },
});
