import axios, { AxiosResponse } from 'axios'
import ClickCollectApi from './ClickCollectApi'
import BraetDatabase, { IArticle, Basket, ArticleQuantity, IBasket, Db } from './QuickCollectDatabase'
import { Vm } from '@/main';
import { Store } from './Store';
import { $cookies } from 'vue/types/umd';

class ContentPageRepository {

    culture: () => string;
   
    constructor() {
        this.culture = () => (localStorage.getItem("culture") ? localStorage.getItem("culture") : "nl-BE") as string;
    }

    public async getContentPagesByCompanyId(id: string): Promise<IContentPage[]> {
        
        try {
            var res = await ClickCollectApi.getData("ContentPage/GetContentPagesByCompanyId?id=" + id);
            if (res.status == 200) {
                return res.data.contentPages as IContentPage[];
            } else {
                return [];
            }
        } catch {
            return [];
        }
        return [];
    } 

    public async getContentPage(companyId: string, type: string): Promise<IContentPage | null> {
        
        try {
            var res = await ClickCollectApi.getData("ContentPage/GetContentPage?companyId=" + companyId + "&type=" + type);
            if (res.status == 200) {
                return res.data.contentPage as IContentPage;
            } else {
                return null;
            }
        } catch {
            return null;
        }
    } 
}

export interface IContentPage {
    title: string
    text: string
    type: string
}
export const ContentPageRepo = new ContentPageRepository();