



































































































































































































































































































































































































































import { Vue } from "vue-property-decorator";
import { CompanyRepo, ICompany } from "../libs/CompanyRepository";
import { ISelectOption, ProductRepo } from "../libs/ProductRepository";
import { configs } from "../libs/config";
import DesktopNav from "../components/shared/DesktopNav.vue";
import TimeSlotSelect from "../components/shared/TimeSlotSelect.vue";
// @ts-ignore
import PageHeader from "../components/shared/PageHeader.vue";
// @ts-ignore
import imagesLoaded from "vue-images-loaded";
import { IArticle } from "../libs/QuickCollectDatabase";
import { Store } from "../libs/Store";
// @ts-ignore
import Datepicker from "vuejs-datepicker";
import {
  CustomerRepo,
  IUserFacturationInformation,
  UserFacturationInformation,
} from "../libs/CustomerRepository";
import CompanyFooter from "../components/shared/CompanyFooter.vue";

export default Vue.extend({
  name: "ProcessOrder" as string,
  components: {
    Datepicker,
    DesktopNav,
    PageHeader,
    CompanyFooter,
    TimeSlotSelect,
  },
  directives: {
    imagesLoaded,
  },
  data() {
    return {
      paymentStep: false,
      mondayFirst: true,
      deliveryPrice: null as number | null,
      restrictToOnlinePayment: false,
      noResults: true,
      deliveryMethod: "",
      selfLinked: this,
      deliveryAdres: new UserFacturationInformation() as IUserFacturationInformation,
      company: undefined as ICompany | undefined,
      products: [] as IArticle[],
      openingsHours: [] as any[],
      timeSlotRange: [] as Date[],
      errors: [] as string[],
      store: Store.state,
      paging: {
        page: 1,
        itemsPerPage: 10,
        companyId: this.$route.params["id"],
        pages: [] as number[],
        total: 0 as number,
      },
      authenticated: false,
      searchTerm: "",
      viewMethod: "list",
      loading: false,
      orderOverlay: false,
      orderProduct: undefined as IArticle | undefined,
      imageHost: configs.ImageHost,
      formError: "",
      selectedBranch: null as ICompany | null
    };
  },
  methods: {
    setDisplayMode(mode: any) {
      this.$root.$emit("change-display-mode", mode);
    },
    validateOrder: async function () {
      var self = this;
      this.formError = ""
      var filterProductFromCompany = this.products.filter(function (obj) {
        return obj.companyId === self.paging?.companyId;
      });

      var deliveryDay = this.timeSlotRange[0]?.getDay();
      
      // check if all selected products can be delivered at the selected day
      if (
        (deliveryDay != undefined || deliveryDay == null) &&
        filterProductFromCompany.some(
          (p) =>
            p.availableDays &&
            p.availableDays.length > 0 &&
            !p.availableDays.includes(deliveryDay + "")
        )
      ) {
        // if there are no deliveryslots selectable just continue
        if (this.deliveryMethod == "delivery" && !this.selectableDeliveryHours)
          return true;

        this.errors.push("productsNotAvailable");
        console.log("notavailableError");
        return false;
      }

      if (self.deliveryMethod === "delivery") {
        var response = true;
        if (this.selectableDeliveryHours) {
          //return error if no slot is selected
          if (this.timeSlotRange.length != 2) {
            this.errors.push("timeslot");
            return false;
          }
        }

        //validate delivery address
        if (
          this.deliveryAdres.firstName === "" ||
          this.deliveryAdres.firstName === undefined
        ) {
          this.errors.push("firstName");
          response = false;
        }
        if (
          this.deliveryAdres.lastName === "" ||
          this.deliveryAdres.lastName === undefined
        ) {
          this.errors.push("lastName");
          response = false;
        }
        if (
          this.deliveryAdres.adres === "" ||
          this.deliveryAdres.adres === undefined
        ) {
          this.errors.push("adres");
          response = false;
        }
        if (
          this.deliveryAdres.number === "" ||
          this.deliveryAdres.number === undefined
        ) {
          this.errors.push("number");
          response = false;
        }
        if (
          this.deliveryAdres.postalCode === "" ||
          this.deliveryAdres.postalCode === undefined
        ) {
          this.errors.push("postalCode");
          response = false;
        } else {
          var postalCodeRegex = /^[1-9]{1}[0-9]{3}$/g;
          var validPostalCode = postalCodeRegex.test(
            this.deliveryAdres.postalCode
          );
          if (validPostalCode == false) {
            this.errors.push("postalCode");
            response = false;
          }
        }
        if (
          this.deliveryAdres.city === "" ||
          this.deliveryAdres.city === undefined
        ) {
          this.errors.push("city");
          response = false;
        }

        var res = await ProductRepo.CheckDeliveryAddress(this.deliveryAdres, this.company?.companyId ?? "");

        if(!res.success){
          this.formError = res.error;
          response = false;
        }

        return response;
      } else if (self.deliveryMethod === "takeaway") {
        //return error if no slot is selected
        if (this.timeSlotRange.length != 2) {
          console.log("empty pickup");
          this.errors.push("timeslot");
          return false;
        }

        return true;
      } else {
        this.errors.push("deliveryMethod");
        return false;
      }
    },
    regexFindAll: function (regexPattern: any, sourceString: any) {
      let output = [];
      let match;
      // make sure the pattern has the global flag
      let regexPatternWithGlobal = RegExp(
        regexPattern,
        [...new Set("g" + regexPattern.flags)].join("")
      );
      while ((match = regexPatternWithGlobal.exec(sourceString))) {
        // get rid of the string copy
        // @ts-ignore
        delete match.input;
        // store the match data
        output.push(match);
      }
      return output;
    },
    goToPaymentStep: async function () {
      var isValidOrder = await this.validateOrder();
      if (!isValidOrder || !this.company) {
        this.loading = false;
        return;
      }     

      var paymentDetails = await ProductRepo.GetOnlinePaymentDetails(
        this.selectedBranch?.companyId ?? this.company?.companyId
      );

      this.restrictToOnlinePayment = paymentDetails.restrictToOnlinePayment;
      // if online payment is not enabled or the price is not exact, publish the order immediately
      if (!paymentDetails.onlinePaymentEnabled || this.isIndicativePrice)
        await this.publishOrder();
      else this.paymentStep = true;
    },
    publishOrder: async function (payOnline?: boolean) {
      var self = this;
      this.loading = true;

      var isValidOrder = await this.validateOrder();
      console.log("isvalid order");
      console.log(isValidOrder);
      if (!isValidOrder) {
        this.loading = false;
        return;
      }

      self.errors = [];
      if (self.company !== undefined) {
        var filterProductFromCompany = this.products.filter(function (obj) {
          return obj.companyId === self.paging?.companyId;
        });
        console.log(this.products);
        console.log(filterProductFromCompany);
        console.log(self.paging?.companyId);

        var invoiceLocalParam = localStorage.getItem("invoice");
        var sendInvoice = invoiceLocalParam === "true" ? true : false;

        var remark = localStorage.getItem("remark");

        var res = await ProductRepo.publishOrder(
          self.store.loggedInUser.userId,
          self.paging.companyId,
          filterProductFromCompany,
          self.deliveryMethod,
          self.deliveryAdres,
          false,
          this.timeSlotRange[0]?.toISOString(),
          this.timeSlotRange[1]?.toISOString(),
          sendInvoice,
          payOnline ?? false,
          remark,
          this.selectedBranch?.companyId
        );
        if (!res) {
          this.loading = false;
          return;
        }

        if (res.success) {
          if (res.paymentUrl) {
            window.location.href = res.paymentUrl;
            return;
          }

          //redirect
          this.$router.push({
            name: "ordercomplete",
            params: {
              id: self.company.companyId,
              orderid: res.orderId.toString(),
            },
          });
        } else {
          if (res.error == "ProductOutOfStock") {
            this.$swal({
              title: "",
              icon: "error",
              text: this.$t("OutOfStockError") + "",
              showConfirmButton: true,
              confirmButtonText: this.$t("EditOrder") + "",
              showCancelButton: false,
            }).then(async (value) => {
              console.log(value);
              // @ts-ignore
              if (this.$session.get("companyDomain") !== undefined) {
                this.$router.push({
                  path: "/",
                  params: { openShoppingCart: "1" },
                });
              }
              // @ts-ignore
              else if (this.$session.get("companyRedirect") !== undefined) {
                if (this?.company?.companySlug != null) {
                  this.$router.push({
                    // @ts-ignore
                    path: this.company.companySlug,
                    params: { openShoppingCart: "1" },
                  });
                }
              } else {
                if (this?.company?.companySlug != null) {
                  this.$router.push({
                    name: "company",
                    // @ts-ignore
                    params: {
                      id: this.company.companySlug,
                      openShoppingCart: "1",
                    },
                  });
                }
              }
            });
          } else {
            this.$swal({
              title: "",
              icon: "error",
              text: res.error,
              toast: true,
              timer: 5000,
              timerProgressBar: true,
              position: "top",
              showConfirmButton: false,
              animation: false,
            }).then((value) => {
              console.log(value);
              // if(value.dismiss && value.dismiss === Swal.DismissReason.close)
              //   self.$router.push({name: "basket"});
            });
          }
        }
      }

      this.loading = false;
    },
    getBasketData: async function () {
      this.loading = true;
      var res = await ProductRepo.getProductsInBasket();

      console.log(res);
      this.products = res;
      //paging

      if (!res) {
        this.loading = false;
        return;
      }

      this.loading = false;
    },
    imageError(imageId: string) {
      var el = document.getElementById(imageId);

      el?.setAttribute("src", "/images/product-placeholder.png");
    },
    loaded() {
      console.log("loaded");
      // @ts-ignore
      $(".products .products__list .product-item .card__name").matchHeight();
      // @ts-ignore
      $(".products .products__list .product-item figure").matchHeight();
    },
    setViewMethod(method: string) {
      console.log(method);
      this.viewMethod = method;

      if (method == "list") {
        this.products = [];
        this.paging.pages = [];
        this.paging.total = 0;
        this.searchTerm = "";
        this.getBasketData();
      } else if (method == "search") {
        this.products = [];
        this.paging.pages = [];
        this.paging.total = 0;
      }
    },
    async calculateDeliveryPrice() {
      if (!this.company) return;

      var filterProductFromCompany = this.products.filter(
        (obj: any) => obj.companyId === this.paging?.companyId
      );

      this.deliveryPrice = await ProductRepo.calculateDeliveryPrice(
        this.company?.companyId,
        filterProductFromCompany
      );
    },
    onBranchChanged: function(branch: ICompany){
      this.selectedBranch = branch
    }
  },
  async mounted() {
    this.company = await CompanyRepo.getCompanyById(this.$route.params["id"]);
    this.openingsHours = await CompanyRepo.getOpeningsHours(
      this.$route.params["id"]
    ); 
    var existingDeliveryAddress = await CustomerRepo.getDeliveryAdres();
    if (
      existingDeliveryAddress != undefined &&
      existingDeliveryAddress != null
    ) {
      this.deliveryAdres = existingDeliveryAddress;
    }
    if (this.company !== undefined) {
      if (this.company.enableTakeAway && !this.company.enableDelivery) {
        this.deliveryMethod = "takeaway";
      } else if (!this.company.enableTakeAway && this.company.enableDelivery) {
        this.deliveryMethod = "delivery";
      }
      
    }

    if (localStorage.getItem("user") != null) {
      this.authenticated = true;
    }

    await this.getBasketData();

    if(this.company?.enableDelivery)
        this.calculateDeliveryPrice();
  },
  watch: {
    currentPage: function () {
      //deze 'watch' traceert page changes en kan de data anders ophalen
      //this.getData();
    },
    deliveryMethod: function () {
      this.timeSlotRange = [];
    },
  },
  computed: {
    maxPage: function maxPage(): number {
      //computed om max page te bepalen, wordt gebruikt in paging html
      if (this.paging.pages.length == 0) return 0;
      return this.paging.pages.reduce((a, b) => (a > b ? a : b));
    },
    currentPage: function currentPage(): number {
      //deze computed value wordt gebruikt om page changes te traceren, zie watch
      return this.paging.page;
    },
    isIndicativePrice: function (): boolean {
      return this.products.some(
        (b: any) =>
          !ProductRepo.IsExactUnitType(b.unitType) &&
          b.companyId === this.paging?.companyId
      );
    },
    selectableDeliveryHours: function (): boolean {
      return this.company?.deliveryhours.some((h) => !h.closed) ?? false;
    },
  },
});
