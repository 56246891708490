
































































































import Vue from "vue";
import ClickCollectApi from "../libs/ClickCollectApi";
import { ProductRepo } from "../libs/ProductRepository";
import { Store } from "../libs/Store";
import DesktopNav from "../components/shared/DesktopNav.vue";
import PageHeader from "../components/shared/PageHeader.vue";
export default Vue.extend({
  name: "Login" as string,
  components: {
    DesktopNav,
    PageHeader,
  },
  data() {
    return {
      phoneConfig: {
        mode: "international",
        defaultCountry: "BE",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: "",
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: "username",
        name: "New-Phone",
        maxLen: 25,
        wrapperClasses: "",
        inputClasses: "form-control",
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      phoneNumber: "" as string,
      errors: [] as string[],
      formError: [] as string[],
      store: Store.state,
      loading: false,
      authenticated: false,
    };
  },
  methods: {
    setDisplayMode(mode: any) {
      this.$root.$emit("change-display-mode", mode);
    },
    async requestNewPassword() {
      this.loading = true;
      this.errors = [];
      if (this.phoneNumber == "") {
        this.errors.push("phoneNumber");
      }

      if (this.errors.length > 0) {
        this.loading = false;
        return;
      }

      try {
        var res = await ClickCollectApi.postData("User/RequestPassword", {
          phoneNumber: this.phoneNumber,
        });

        console.log(res);
        if (res.data.success == true) {
          this.$router.push("resetPassword");
        }
      } catch (error) {
        console.log(error);
        var errRes = error.response;
        if (errRes && errRes.status !== 200) {
          if ("" in errRes.data) {
            this.formError = errRes.data[""];
          }
        } else {
          this.formError = [this.$t("noConnection") as string];
        }
        this.loading = false;
      }
    },
  },
  mounted: function () {
    if (localStorage.getItem("user") != null) {
      this.authenticated = true;
    }
  },
});
