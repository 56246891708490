import axios, { AxiosResponse } from 'axios'
import ClickCollectApi from './ClickCollectApi'
import BraetDatabase, { IArticle, Basket, ArticleQuantity, IBasket, Db, IArticleQuantity } from './QuickCollectDatabase'
import { Vm } from '@/main';
import { Store } from './Store';
import { CustomerRepo, IUserFacturationInformation } from './CustomerRepository';
import VueCookies from 'vue-cookies'
import { uuid } from 'vue-uuid';
import { Component, Vue } from "vue-property-decorator";
import { ICompany } from './CompanyRepository';

class ProductRepository {

    db: BraetDatabase;
    culture: () => string;
    abortTransactions: boolean;

    constructor(db: BraetDatabase) {
        this.db = db; 
        this.culture = () => (localStorage.getItem("culture") ? localStorage.getItem("culture") : "nl-BE") as string;
        this.abortTransactions = false;
    }

    public async publishOrder(customerId: string, companyId : string, products : IArticle[], 
        deliveryMethod : string, deliveryAddress : IUserFacturationInformation, 
        asFastAsPossible : boolean, deliveryDate: string, deliveryDateUntil: string,sendInvoice : boolean,
        payOnline: boolean, remark : string | null, branchId: string | undefined): Promise<IOrderResult | null> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.postData("Order/PublishOrder",{
                    userId : customerId,
                    companyId : companyId,
                    products : products,
                    asFastAsPossible : asFastAsPossible,
                    deliveryDate : deliveryDate,
                    deliveryDateUntil : deliveryDateUntil,
                    deliveryMethod : deliveryMethod,
                    deliveryAddress : deliveryAddress,
                    sendInvoice : sendInvoice,
                    payOnline: payOnline,
                    remark : remark,
                    branchId: branchId
                });
                console.log(res);
                if (res.status == 200) {
                    return res.data as IOrderResult;
                } else {
                    return null;
                }
            } catch {
                return null;
            }
        }

        return null;
    }

    public async calculateDeliveryPrice(companyId : string, products : IArticle[]): Promise<number> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.postData("Order/CalculateDeliveryPrice",{
                    companyId : companyId,
                    products : products
                });
                console.log(res);
                if (res.status == 200) {
                    return res.data.deliveryPrice as number;
                } else {
                    return 0;
                }
            } catch {
                return 0;
            }
        }

        return 0;
    }

    public async getOrder(orderId : number): Promise<IOrder | undefined> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("Order/GetById?orderId=" + orderId);
                if (res.status == 200) {
                    return res.data as IOrder;
                } else {
                    return undefined;
                }
            } catch {
                return undefined;
            }
        }

        return undefined;
    }

    public async getOrderDetails(orderId: string): Promise<any> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("Order/GetOrderDetails?orderId=" + orderId);

                if (res.status == 200)
                    return res.data;

            } catch {
                return {};
            }
        } else {
            return {};
        }

        return {};
    }

    public async getLinkedProductsByCategory(productId : number): Promise<any[]> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("Product/GetLinkedProducts?productId=" + productId);
                if (res.status == 200) {
                    return res.data as any[];
                } else {
                    return [];
                }
            } catch {
                return [];
            }
        }

        return [];
    }

    public async getBranches(productId : number): Promise<any[]> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("Product/GetBranches?productId=" + productId);
                if (res.status == 200) {
                    return res.data as any[];
                } else {
                    return [];
                }
            } catch {
                return [];
            }
        }

        return [];
    }

    public async getFeaturedProducts(companyId : string): Promise<any[]> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("Product/GetFeaturedProducts?companyId=" + companyId);
                if (res.status == 200) {
                    return res.data.products as any[];
                } else {
                    return [];
                }
            } catch {
                return [];
            }
        }

        return [];
    }

    public async getAvailableTakeAwayTimeslots(companyId : string, date : Date): Promise<ITimeRangeResponse | null> {
        if (navigator.onLine) {
            
                var res = await ClickCollectApi.getData("Order/GetAvailableTakeAwayTimeslots?companyId=" + companyId + "&date=" + date.toJSON());
                if (res.status == 200) {
                    
                    if(res.data.success == false){
                        console.log(res.data)
                        throw(res.data.error)
                    }                       

                    return res.data as ITimeRangeResponse;
                } else {
                    return null;
                }
            
        }

        return null;
    }

    public async getAvailableDeliveryTimeslots(companyId : string, date : Date): Promise<ITimeRangeResponse | null> {
        if (navigator.onLine) {
            
                var res = await ClickCollectApi.getData("Order/GetAvailableDeliveryTimeslots?companyId=" + companyId + "&date=" + date.toJSON());
                if (res.status == 200) {
                    
                    if(res.data.success == false){
                        console.log(res.data)
                        throw(res.data.error)
                    }                       

                    return res.data as ITimeRangeResponse;
                } else {
                    return null;
                }
            
        }

        return null;
    }

    public async getDisabledTakeAwayDatesInFirstSevenDays(companyId : string): Promise<Date[]> {
        if (navigator.onLine) {
            
                var res = await ClickCollectApi.getData("Order/getDisabledTakeAwayDatesInFirstSevenDays?companyId=" + companyId);
                if (res.status == 200) {
                                         

                    return res.data.disabledDates.map((d : string) => new Date(d));
                } else {
                    return [];
                }
            
        }

        return [];
    }

    public async getDisabledDeliveryDatesInFirstSevenDays(companyId : string): Promise<Date[]> {
        if (navigator.onLine) {
            
                var res = await ClickCollectApi.getData("Order/getDisabledDeliveryDatesInFirstSevenDays?companyId=" + companyId);
                if (res.status == 200) {
                                         

                    return res.data.disabledDates.map((d : string) => new Date(d));
                } else {
                    return [];
                }
            
        }

        return [];
    }

    public async getProducts(start: number, nbPerPage: number, search: string | null, companyId : string): Promise<IArticleResult | null> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.postData("Product/GetProducts",{
                    start : start,
                    nbPerPage : nbPerPage,
                    searchString : search,
                    companyId : companyId
                });
                if (res.status == 200) {
                    return res.data as IArticleResult;
                } else {
                    return null;
                }
            } catch {
                return null;
            }
        }

        return null;
    }

    public async getMostOrderedProducts(companyId : string, userId : string): Promise<IArticleResult | null> {
        console.log(companyId);
        console.log(userId);
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.postData("Product/GetMostOrderedProducts",{
                    companyId : companyId,
                    userId : userId 
                });
                if (res.status == 200) {
                    return res.data as IArticleResult;
                } else {
                    return null;
                }
            } catch {
                return null;
            }
        }

        return null;
    }


    public async getPreviousOrder(companyId : string, userId : string): Promise<IArticleResult | null> {
        console.log(companyId);
        console.log(userId);
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.postData("Product/GetPreviousOrder",{
                    companyId : companyId,
                    userId : userId 
                });
                if (res.status == 200) {
                    return res.data as IArticleResult;
                } else {
                    return null;
                }
            } catch {
                return null;
            }
        }

        return null;
    }

    //#region basket
    /**
     * Returns the existing active basket or creates a new one
     */
    public async getActiveBasket(): Promise<IBasket> {
        var customerId = CustomerRepo.getCurrentUserId();
        var activeBasket = undefined as IBasket | undefined;
        var currentUserSession = Vue.$cookies.get("user_session");
        console.log('current user session');
        console.log(currentUserSession);
        console.log(customerId);
        if(customerId !== undefined){
            activeBasket = await this.db.baskets
                .filter(b => !b.finished && b.customerId == customerId)
                .first();
        }

        if(!activeBasket){
            activeBasket = await this.db.baskets
            .filter(b => !b.finished && b.sessionId == currentUserSession)
            .first();
        }     

        if (!activeBasket) {
            var nbBaskets = await this.db.baskets.count();
            activeBasket = new Basket(nbBaskets + 1, customerId, currentUserSession);
            this.db.baskets.put(activeBasket);
        }
        console.log(activeBasket);
        console.log('active basket');
        return activeBasket;
    }

    public async getProductsInBasket(): Promise<IArticle[]> {
        return this.getProductsInGivenBasket(await this.getActiveBasket());
    }

    private async getProductsInGivenBasket(basket: IBasket): Promise<IArticle[]> {
        var articleIdsWithQuantity = basket.productIds;

        var articleIds = articleIdsWithQuantity.flatMap(a => a.subArticles 
            ? [a.productId.toString(), ...a.subArticles.map(s => s.productId.toString())] 
            : [a.productId.toString()]);
        console.log('article ids');
        console.log(articleIds);
        var articles = await this.getProductsByIds(articleIds);
        var articlesWithQuantity = [] as IArticle[];

        if(articles != null){
            var i = 0
            for (var articleQuantity of articleIdsWithQuantity) {
                var article = articles.find(a => a.productId == articleQuantity.productId);
    
                if (!article)
                    continue;

                // clone
                article = JSON.parse(JSON.stringify(article)) as IArticle

                article.amount = articleQuantity.quantity;
                article.pricePerUnit = articleQuantity.unitPrice;
                article.unitType = articleQuantity.unitType;
                article.companyId = articleQuantity.companyId;
                article.productId = article.productId;
                article.orderItemId = articleIdsWithQuantity.indexOf(articleQuantity)
                article.remark = articleQuantity.remark
                article.childProducts = []

                // add subproducts
                for(var subProduct of articleQuantity.subArticles ?? []){
                    var subArticle = articles.find(a => a.productId == subProduct.productId);

                    // clone
                    subArticle = JSON.parse(JSON.stringify(subArticle)) as IArticle

                    console.log("subarticle", subArticle)

                    if(!subArticle)
                        continue;

                    subArticle.amount = subProduct.quantity;
                    subArticle.pricePerUnit = subProduct.unitPrice;
                    subArticle.unitType = subProduct.unitType;
                    subArticle.companyId = subProduct.companyId;

                    article.childProducts?.push(subArticle)
                }

                articlesWithQuantity.push(article);
            }
    
        }
        
        return articlesWithQuantity;
    }

    
    public async getProductsByIds(productIds : string[]): Promise<IArticle[] | null> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.postData("Product/GetProductsByIds?", productIds);
                if (res.status == 200) {
                    return res.data as IArticle[];
                } else {
                    return null;
                }
            } catch {
                return null;
            }
        }

        return null;
    }

    public async getProductById(productId : string, companyId: string): Promise<IArticle | null> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("Product/GetProductById?id=" + productId + "&companyId=" + companyId);
                if (res.status == 200) {
                    return res.data as IArticle;
                } else {
                    return null;
                }
            } catch {
                return null;
            }
        }

        return null;
    }

    public async getProductCategories(companyId : string): Promise<any[] | null> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("Product/GetProductCategories?companyId=" + companyId);
                if (res.status == 200) {
                    return res.data as string[];
                } else {
                    return null;
                }
            } catch {
                return null;
            }
        }

        return null;
    }

    public async getProductsByCategoryName(categoryId : string, companyId : string, fetchSubProducts?: boolean): Promise<any | null> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.postData("Product/GetProductsByCategoryName",{
                    categoryId : categoryId,
                    companyId : companyId,
                    fetchSubProducts: fetchSubProducts ?? false
                });
                if (res.status == 200) {
                    return res.data as any;
                } else {
                    return null;
                }
            } catch {
                return null;
            }
        }

        return null;
    }

    public async addToBasket(article: IArticle) {
        var activeBasket = await this.getActiveBasket();

        // find the orderitem that has the exact match of linkedproducts with the same amount and the same remark
        var productQuantity =
            activeBasket.productIds
                .find(a => a.productId == article.productId
                    && a.remark == article.remark
                    && a.subArticles?.length == article.childProducts?.length
                    && a.subArticles.every(s => article.childProducts?.some(cp => cp.productId == s.productId && cp.amount == s.quantity) ?? true));

        var subArticles = article.childProducts?.map(
            p => new ArticleQuantity(p.productId, p.amount, p.pricePerUnit, p.unitType,p.companyId, undefined, p.remark)
        ) ?? []        

        if (productQuantity){
            productQuantity.quantity += article.amount;
            productQuantity.subArticles = subArticles;
            productQuantity.remark = article.remark;
        }
        else{
            activeBasket.productIds.push(new ArticleQuantity(article.productId, article.amount, article.pricePerUnit, article.unitType,article.companyId, subArticles, article.remark));
        }

        this.db.baskets.put(activeBasket);
    }

    public async updateInBasket(article: IArticle) {
        var activeBasket = await this.getActiveBasket();
        console.log("orderItemId", article.orderItemId)
        // find the orderitem that has the exact match of linkedproducts with the same amount and same remark
        var productQuantity =
            article.orderItemId != undefined && article.orderItemId > -1
            ? activeBasket.productIds[article.orderItemId]
            : activeBasket.productIds
                .find(a => a.productId == article.productId
                    && a.remark == article.remark
                    && a.subArticles?.length == article.childProducts?.length
                    && a.subArticles.every(s => article.childProducts?.some(cp => cp.productId == s.productId && cp.amount == s.quantity) ?? true));

        var subArticles = article.childProducts?.map(
            p => new ArticleQuantity(p.productId, p.amount, p.pricePerUnit, p.unitType,p.companyId, undefined, article.remark)
        ) ?? []        

        if (productQuantity){
            productQuantity.quantity = article.amount;
            productQuantity.subArticles = subArticles;
            productQuantity.remark = article.remark;
        }
        else{
            activeBasket.productIds.push(new ArticleQuantity(article.productId, article.amount, article.pricePerUnit, article.unitType,article.companyId, subArticles, article.remark));
        }

        this.db.baskets.put(activeBasket);
    }

    public async addToBasketKeepingExistingAmount(article: IArticle) {
        var activeBasket = await this.getActiveBasket();
        console.log("article", article)

        var productQuantity =
            activeBasket.productIds
                .find(a => a.productId == article.productId);

        if (productQuantity){
            productQuantity.quantity += article.amount;
            productQuantity.remark = article.remark;
        }
        else
            activeBasket.productIds.push(new ArticleQuantity(article.productId, article.amount, article.pricePerUnit, article.unitType,article.companyId, undefined, article.remark));

        this.db.baskets.put(activeBasket);
    }


    public async removeFromBasket(article: IArticle) {
        var activeBasket = await this.getActiveBasket();

          // find the orderitem that has the exact match of linkedproducts with the same amount
          var productIndex =
          activeBasket.productIds
              .findIndex(a => a.productId == article.productId
                  && a.subArticles?.length == article.childProducts?.length
                  && a.subArticles.every(s => article.childProducts?.some(cp => cp.productId == s.productId && cp.amount == s.quantity) ?? true));       

        if(productIndex !== -1){
            activeBasket.productIds.splice(productIndex,1);

            this.db.baskets.put(activeBasket);
        }     
    }

    public async getFromBasket(article: IArticle): Promise<IArticleQuantity | undefined> {
        var activeBasket = await this.getActiveBasket();

          // find the orderitem that has the exact match of linkedproducts with the same amount
          var product =
          activeBasket.productIds
              .find(a => a.productId == article.productId
                  && a.subArticles?.length == article.childProducts?.length
                  && a.subArticles.every(s => article.childProducts?.some(cp => cp.productId == s.productId && cp.amount == s.quantity) ?? true));
        
        return product;       
    }  
    
    public async GetOnlinePaymentDetails(companyId : string): Promise<any> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.getData("Order/GetPaymentDetails?companyId=" + companyId);
                if (res.status == 200) {
                    return res.data as IArticle[];
                } else {
                    return null;
                }
            } catch {
                return null;
            }
        }

        return null;
    }

    public async CheckDeliveryAddress(deliveryAddress: any, companyId : string): Promise<any> {
        if (navigator.onLine) {
            try {
                var res = await ClickCollectApi.postData("Order/CheckDeliveryAddress?companyId=" + companyId, deliveryAddress);
                if (res.status == 200) {
                    return res.data as any;
                } else {
                    return null;
                }
            } catch {
                return null;
            }
        }

        return null;
    }

    /**
     * Return the quantity step of the given product, determined by it's unittype, 
     * current quantity and its clustersize
     * @param product 
     */
    public GetQuantityStep(product : IArticle) : number{
        if(!product)
            return 0

        if(product.unitType == "PIECE" || product.unitType == "PERSON" || product.unitType == "SET" || product.unitType == "PAIR" || product.unitType == "WEIGHTPIECE")
            return 1;

        return product.amount >= 1000 ? 100 : 50;
    }

    /**
     * Check whether the given unit type takes steps per one
     * @param product 
     */
    public IsSingularUnitType(unitType : string) : boolean{
        if(unitType == "PIECE" || unitType == "PERSON" || unitType == "SET" || unitType == "PAIR" || unitType == "WEIGHTPIECE")
            return true;
        return false;
    }

    /**
     * Check whether the given unit can be charged exactly
     * @param product 
     */
    public IsExactUnitType(unitType : string) : boolean{
        if(unitType == "PIECE" || unitType == "PERSON" || unitType == "SET" || unitType == "PAIR")
            return true;
        return false;
    }
}


export class AutocompleteData {
    id: string;
    name: string;
    artikelNr: string;

    constructor(id: string, name: string, artikelNr: string) {
        this.id = id;
        this.name = name;
        this.artikelNr = artikelNr;
    }
}


export class BasketOverview {
    companyId: string;
    company: ICompany | undefined;
    products: IArticle[];

    constructor(companyId: string, company: ICompany, products: IArticle[]) {
        this.companyId = companyId;
        this.company = company;
        this.products = products;
    }
}

export interface ITimeRangeResponse {
    interval: number;
    slots: ITimeRange[]
}

export interface ITimeRange {
    hour: string;
    slot: ISelectOption[]
}

export interface ISelectOption {
    label: string;
    value: string;
}

export interface IArticleResult {
    pageCount: number;
    nbResults: number;
    products: IArticle[];
}

export interface IOrderResult {
    success: boolean;
    orderId: number;
    error : string;
    paymentUrl: string | null;
}

export interface IOrder {
    id: string;
    orderId: number;
    deliveryDate : Date | undefined;
    asFastAsPossible: boolean;
    userId : string;
    companyId: string;    
    orderItems: any[];
    created: string;
}


export const ProductRepo = new ProductRepository(Db);