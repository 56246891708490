

























































































































































import Vue from "vue";
import ClickCollectApi from "../libs/ClickCollectApi";
import { ProductRepo } from "../libs/ProductRepository";
import { Store } from "../libs/Store";
import DesktopNav from "../components/shared/DesktopNav.vue";
import { UtilsRepo, Utils } from "../libs/Utils";
import PageHeader from "../components/shared/PageHeader.vue";
import { CompanyRepo, ICompany } from "@/libs/CompanyRepository";

export default Vue.extend({
  name: "Profile" as string,
  components: {
    DesktopNav,
    PageHeader,
  },
  data() {
    return {
      existingUserInput: {
        phoneNumber: Store.state.loggedInUser.phoneNumber as string,
        email: Store.state.loggedInUser.email as string,
        firstName: Store.state.loggedInUser.firstName as string,
        lastName: Store.state.loggedInUser.lastName as string,
        culture: "nl-BE" as string,
        oldPhone: Store.state.loggedInUser.phoneNumber as string,
        sendSms: Store.state.loggedInUser.sendSms as boolean,
        sendPush: Store.state.loggedInUser.sendPush as boolean,
        sendMail: Store.state.loggedInUser.sendMail as boolean,
      },
      authenticated: false,
      showMobileMenu: false,
      errors: [] as string[],
      formError: [] as string[],
      store: Store.state,
      loading: false,
      success: false,
      validPhone: false as boolean,
      phoneConfig: {
        mode: "international",
        defaultCountry: "BE",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: "",
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: "username",
        name: "New-Phone",
        maxLen: 25,
        wrapperClasses: "",
        inputClasses: "form-control",
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      company: undefined as ICompany | undefined,
    };
  },
  methods: {
    isIos: function () {
      Utils.isIos();
    },
    setDisplayMode(mode: any) {
      console.log("set display mode");
      this.$root.$emit("change-display-mode", mode);
    },
    onInput(formattedNumber: any, number: any, valid: any, country: any) {
      console.log(formattedNumber);
      console.log(number);
      console.log(valid);
      console.log(country);
      this.validPhone = number.isValid;
    },
    validEmail: function (email: any) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    async editInfo() {
      this.loading = true;
      this.errors = [];
      if (this.existingUserInput.phoneNumber == "") {
        this.errors.push("phoneNumber");
      } else if (
        this.validPhone == false &&
        this.existingUserInput.oldPhone != this.existingUserInput.phoneNumber
      ) {
        this.errors.push("valid-phoneNumber");
      }

      if (this.existingUserInput.email == "") {
        this.errors.push("email");
      } else if (!this.validEmail(this.existingUserInput.email)) {
        this.errors.push("valid-email");
      }

      if (this.existingUserInput.firstName == "") {
        this.errors.push("firstName");
      }

      if (this.existingUserInput.lastName == "") {
        this.errors.push("lastName");
      }

      // if (this.existingUserInput.culture == "") {
      //   this.errors.push("culture");
      // }

      if (this.errors.length > 0) {
        this.loading = false;
        return;
      }

      try {
        var res = await ClickCollectApi.postData("User/UpdateInfo", {
          PhoneNumber: this.existingUserInput.phoneNumber,
          FirstName: this.existingUserInput.firstName,
          LastName: this.existingUserInput.lastName,
          Culture: "nl-BE",
          Email: this.existingUserInput.email,
          OldPhone: this.existingUserInput.oldPhone,
          SendSms: this.existingUserInput.sendSms,
          SendPush: this.existingUserInput.sendPush,
          SendMail: this.existingUserInput.sendMail,
        });

        var user = {
          userId: res.data.user.userId,
          phoneNumber: res.data.user.phoneNumber,
          firstName: res.data.user.firstName,
          lastName: res.data.user.lastName,
          email: res.data.user.email,
          culture: res.data.user.culture,
          sendSms: this.existingUserInput.sendSms,
          sendPush: this.existingUserInput.sendPush,
          sendMail: this.existingUserInput.sendMail,
          loggedInAs: {},
        };

        this.store.loggedInUser = user;

        localStorage.setItem("user", JSON.stringify(user));
        this.success = true;
        this.loading = false;
      } catch (error) {
        console.log(error);
        var errRes = error.response;
        if (errRes && errRes.status !== 200) {
          if ("" in errRes.data) {
            this.formError = errRes.data[""];
          }
        } else {
          this.formError = [this.$t("noConnection") as string];
        }
        this.loading = false;
      }
    },
    showSettingsMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
  },
  mounted: async function () {
    console.log(Store);
    if (localStorage.getItem("user") != null) {
      this.authenticated = true;
    }
    this.company = await CompanyRepo.getCompanyByRoute(this.$route);
  },
  computed: {
    basePath: function () {
      var segments = this.$route.path.split("/");
      console.log(segments);
      return segments.length > 2 ? "/" + segments[1] : "";
    },
  },
});
