











































































































































import { Vue } from "vue-property-decorator";
import { CompanyRepo, ICompany } from "../libs/CompanyRepository";
import { ProductRepo, IOrder } from "../libs/ProductRepository";
import { configs } from "../libs/config";

// @ts-ignore

// @ts-ignore
import imagesLoaded from "vue-images-loaded";
import { CustomerRepo } from "../libs/CustomerRepository";
import DesktopNav from "../components/shared/DesktopNav.vue";
import PageHeader from "../components/shared/PageHeader.vue";
import { Utils } from "../libs/Utils";
import CompanyFooter from "../components/shared/CompanyFooter.vue";
export default Vue.extend({
  name: "ConfirmOrder" as string,
  components: {
    DesktopNav,
    PageHeader,
    CompanyFooter,
  },
  directives: {
    imagesLoaded,
  },
  data() {
    return {
      company: undefined as ICompany | undefined,
      order: undefined as IOrder | undefined,
      paid: null as string | null,
      authenticated: false,
      imageHost: configs.ImageHost,
    };
  },
  methods: {
    openingHourList: function () {
      if (this.company !== undefined) {
        return Utils.openingHourList(this.company);
      }
      return "";
    },
    deliveryHourList: function () {
      if (this.company !== undefined) {
        return Utils.deliveryHourList(this.company);
      }
      return "";
    },
    imageError(imageId: string) {
      console.log("image error");
      console.log(imageId);
      var el = document.getElementById(imageId);

      el?.setAttribute("src", "/images/product-placeholder.png");
    },
    setDisplayMode(mode: any) {
      this.$root.$emit("change-display-mode", mode);
    },
    padNumber(number: any) {
      number = number.toString();

      while (number.length < 4) {
        number = "0" + number;
      }

      return number;
    },
    redirectStart() {
      console.log("redirect start");
      // @ts-ignore
      if (this.$session.get("companyDomain") !== undefined) {
        this.$router.push("/");
      }
      // @ts-ignore
      else if (this.$session.get("companyRedirect") !== undefined) {
        // @ts-ignore
        var companySlug = this.$session.get("companyRedirect");
        this.$router.push(companySlug);
      } else {
        this.$router.push({ name: "companies" });
      }
    },
  },
  async created() {
    this.company = await CompanyRepo.getCompanyById(this.$route.params["id"]);
    this.order = await CustomerRepo.getOrderById(this.$route.params["orderid"]);

    //@ts-ignore
    this.paid = this.$route.query.paid;
    console.log(this.paid);
    //if (this.paid != "false") {
      if(true){
      var products = await ProductRepo.getProductsInBasket();

      // remove all the products of this order from the active basket
      for (var product of products.filter(
        (p) => p.companyId == this.company?.companyId
      )) {
        await ProductRepo.removeFromBasket(product);
      }
    }
    this.$forceUpdate()

    console.log(this.order);
    console.log(this.company);
  },
  async mounted() {
    if (localStorage.getItem("user") != null) {
      this.authenticated = true;
    }
  },
});
